import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { AppInfo } from '@capacitor/app/dist/esm/definitions';
import { Capacitor } from '@capacitor/core';
import { Device as CapDevice, OperatingSystem } from '@capacitor/device';
import { DevicePlatform } from '@coalist/common';
import { gitHash, gitHashShort } from '../../version';

export interface DeviceInfo {
  readonly uuid: string;
  readonly model: string;
  readonly platform: DevicePlatform;
  readonly operatingSystem: OperatingSystem;
  readonly osVersion: string;
  readonly manufacturer: string;
  readonly appVersion: string;
  readonly appBuild: string;
  readonly gitHash: string;
  readonly gitHashShort: string;
  readonly language: string | null;
}

@Injectable({ providedIn: 'root' })
export class DeviceService {
  async getDeviceInfo(): Promise<DeviceInfo> {
    const deviceId = await CapDevice.getId();
    const deviceInfo = await CapDevice.getInfo();
    const language = await CapDevice.getLanguageCode();
    let appInfo: AppInfo | null = null;
    if (Capacitor.isNativePlatform()) {
      appInfo = await App.getInfo();
    }
    return {
      uuid: deviceId.identifier,
      model: deviceInfo.model,
      platform: deviceInfo.platform,
      operatingSystem: deviceInfo.operatingSystem,
      osVersion: deviceInfo.osVersion,
      manufacturer: deviceInfo.manufacturer,
      appVersion: appInfo?.version ?? '',
      appBuild: appInfo?.build ?? '',
      gitHash,
      gitHashShort,
      language: language.value,
    };
  }
}
