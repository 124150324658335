import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Client, ClientInvoice, MemberUser, Project } from '@coalist/common';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { SlidesComponent } from '../../components/slides/slides.component';
import { ClientService } from '../../services/client.service';
import { InvoiceService } from '../../services/invoice.service';
import { MemberUserService } from '../../services/member-user.service';
import { ProjectService } from '../../services/project.service';
import { loadPaged, PagedLoading } from '../../util/loading';
import { brandColor, setThemeColor } from '../../util/theme';

export type ProjectsSlide = 'projects' | 'clients' | 'leads';

@Component({
  selector: 'events-page',
  templateUrl: './projects.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsPage implements ViewWillEnter, ViewDidLeave {
  @ViewChild('slides') slides!: SlidesComponent;

  slideIds: ProjectsSlide[] = ['projects', 'clients', 'leads'];
  activeSlide = new BehaviorSubject<ProjectsSlide>('projects');

  projects: PagedLoading<Project>;
  clients: PagedLoading<Client>;
  invoices: PagedLoading<ClientInvoice>;
  memberUsers: Observable<MemberUser[]>;

  constructor(
    private clientService: ClientService,
    private projectService: ProjectService,
    private invoiceService: InvoiceService,
    private memberUserService: MemberUserService,
  ) {
    this.projects = loadPaged((limit) => this.projectService.findActive(limit));
    this.clients = loadPaged((limit) => this.clientService.findActive(limit));
    this.invoices = loadPaged((limit) => this.invoiceService.findAll(limit));
    this.memberUsers = this.memberUserService.memberUsers;
  }

  async ionViewWillEnter() {
    setThemeColor(brandColor);
    this.projects.enable();
    this.clients.enable();
    this.invoices.enable();
  }

  ionViewDidLeave() {
    this.projects.disable();
    this.clients.disable();
    this.invoices.disable();
  }

  add() {}

  selectSlide(page: ProjectsSlide) {
    this.slides.slideTo(this.slideIds.indexOf(page));
  }

  slideSelected(activeIndex: number) {
    const page = this.slideIds[activeIndex];
    if (this.activeSlide.value !== page) {
      this.activeSlide.next(page);
    }
  }
}
