import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MemberCompany, MemberUser } from '@coalist/common';
import { trackById } from '../../../util/trackBy';

export type MemberCompanyItem = Pick<MemberCompany, 'id' | 'name' | 'logoUrl'>;

@Component({
  selector: 'member-company-item',
  templateUrl: './member-company-item.component.html',
  styleUrls: ['./member-company-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberCompanyItemComponent {
  @Input() routerLink?: string;
  @Input() company!: MemberCompanyItem;
  @Input() users: MemberUser[] | null = null;

  trackById = trackById;
}
