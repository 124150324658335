import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Admission } from '@coalist/common';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { AdmissionService } from '../../services/admission.service';
import { load, Loading } from '../../util/loading';
import { brandColor, setThemeColor } from '../../util/theme';

@Component({
  selector: 'admissions-page',
  templateUrl: './admissions.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdmissionsPage implements ViewWillEnter, ViewWillLeave {
  admissions: Loading<Admission[]>;

  constructor(private admissionService: AdmissionService) {
    this.admissions = load(this.admissionService.findByState(['voting', 'meetingScheduled', 'consensus', 'accepted']));
  }

  ionViewWillEnter() {
    setThemeColor(brandColor);
    this.admissions.enable();
  }

  ionViewWillLeave() {
    this.admissions.disable();
  }
}
