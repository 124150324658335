import { Address } from './address';
import { PersonEntity } from './person';
import { SocialPlatformType } from './social-platform';
import { Id, IsoDate, WithId } from './types';

export type MemberUserRole = 'Owner' | 'MainContact' | 'AuthorizedOfficer1' | 'AuthorizedOfficer2';

export const memberUserRoles: MemberUserRole[] = ['Owner', 'MainContact', 'AuthorizedOfficer1', 'AuthorizedOfficer2'];

export type AvailabilityStatus = 'available' | 'partial' | 'unavailable';

export const availabilityStatuses: AvailabilityStatus[] = ['available', 'partial', 'unavailable'];

export type MemberUser = MemberUserEntity & WithId;
export type MemberUserId = Id;

export interface MemberUserSummaryEntity {}

export interface MemberUserEntity extends PersonEntity {
  email: string;
  active: boolean;
  companyId: Id;
  companyName: string;
  roles: MemberUserRole[];
  businessTitle: string; // e.g. CEO, CTO, Founder, Partner, etc.
  title: string; // e.g. Software Engineer
  address?: Address;
  phone: string;
  website?: string;
  socialProfiles?: Partial<Record<SocialPlatformType, string>>;
  availability: string;
  availabilityStatus: AvailabilityStatus;
  notificationEnabled?: boolean;
  welcomeShown?: boolean;
}

export interface MemberUserEducation {
  academicTitle: string;
  institution: string;
  graduationYear: number;
}

export type MemberUserProfile = MemberUserProfileEntity & WithId;

export type SkillLevel = 0 | 1 | 2 | 3 | 4 | 5;

export interface MemberUserProfileEntity {
  languages: string[];
  miniBiography: string;
  skills: Record<Id, SkillLevel>;
  education: MemberUserEducation[];
}

export type MemberUserDetails = MemberUserDetailsEntity & WithId;

export interface MemberUserDetailsEntity {
  dateOfBirth: IsoDate;
}
