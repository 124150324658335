import { CameraResultType, ImageOptions } from '@capacitor/camera';
import { UploadMetadata } from '@firebase/storage';

export const defaultImageSettings: ImageOptions = {
  height: 300,
  width: 300,
  quality: 100,
  allowEditing: true,
  resultType: CameraResultType.DataUrl
};

export const cacheForever: UploadMetadata = { cacheControl: 'max-age=31536000' };
