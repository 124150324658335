<ion-header>
  <ion-toolbar color="white">
    <ion-buttons slot="start">
      <ion-button color="brand" (click)="cancel()">{{ 'general.cancel' | translate}}</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="brand" (click)="ok()">{{ 'general.ok' | translate}}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="cols">
    <div class="col">
      <ion-datetime
        presentation="time"
        [(ngModel)]="startTimeValue"
        (ionChange)="startTimeChanged($any($event).detail.value)"
        locale="de-CH"></ion-datetime>
    </div>
    <ng-container *ngIf="endTimeValue">
      <div class="divider">
        <div class="picker-before"></div>
        <div class="picker-highlight">-</div>
        <div class="picker-after"></div>
      </div>
      <div class="col" *ngIf="endTimeValue">
        <ion-datetime
          presentation="time"
          [(ngModel)]="endTimeValue"
          (ionChange)="endTimeChanged($any($event).detail.value)"
          locale="de-CH"></ion-datetime>
      </div>
    </ng-container>
  </div>
</ion-content>
