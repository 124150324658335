<ion-header>
  <ion-toolbar color="brand">
    <ion-title>
      <ion-segment [value]="activeSlide | async" (ionChange)="selectSlide($any($event).detail.value)">
        <ion-segment-button value="projects">{{ 'projects.title' | translate }}</ion-segment-button>
        <ion-segment-button value="clients">{{ 'clients.title' | translate }}</ion-segment-button>
      </ion-segment>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="add()" color="secondary">
        <ion-icon name="add" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <slides #slides (slideChanged)="slideSelected($event)">
    <slide>
      <ion-content>
        <loading *ngIf="projects.loading | async as loading" [loading]="loading">
          <project-list
            *ngIf="projects.page | async as page"
            [projects]="page.items"
            [memberUsers]="(memberUsers | async) ?? []"
            [hasMore]="page.hasMore"
            (loadMore)="projects.loadMore()"
          ></project-list>
        </loading>
      </ion-content>
    </slide>
    <slide>
      <ion-content>
        <loading *ngIf="clients.loading | async as loading" [loading]="loading">
          <client-list
            *ngIf="clients.page | async as page"
            [clients]="page.items"
            [hasMore]="page.hasMore"
            (loadMore)="projects.loadMore()"
          ></client-list>
        </loading>
      </ion-content>
    </slide>
    <slide> TODO </slide>
  </slides>
</ion-content>
