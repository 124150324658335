import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Vote } from '@coalist/common';
import { ModalController } from '@ionic/angular';

export interface AdmissionVoteForm {
  supporting: boolean;
  comment: string;
}

export type VoteUpdate = Pick<Vote, 'vote' | 'comment'>;

@Component({
  selector: 'admission-vote-page',
  templateUrl: './admission-vote.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdmissionVotePage implements OnInit {
  @Input() vote!: Vote;

  form: AdmissionVoteForm = {
    supporting: false,
    comment: '',
  };

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.form = {
      supporting: this.vote?.vote === 'supporting',
      comment: this.vote?.comment ?? '',
    };
  }

  ok() {
    const vote: VoteUpdate = {
      vote: this.form.supporting ? 'supporting' : 'notSupporting',
      comment: this.form.comment ?? undefined,
    };
    this.modalController.dismiss(vote);
  }

  cancel() {
    this.modalController.dismiss();
  }
}
