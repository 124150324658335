import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@coalist/common';

@Component({
  selector: 'address',
  templateUrl: 'address.component.html',
  styleUrls: ['address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressComponent {
  @Input() address!: Address;
}
