import { Injectable } from '@angular/core';
import { Client, Id } from '@coalist/common';
import { limit, orderBy } from 'firebase/firestore';
import { map } from 'rxjs';
import { FirebaseService } from './firebase.service';

@Injectable({ providedIn: 'root' })
export class ClientService {
  constructor(private firebase: FirebaseService) {}

  findActive(maxItems = 20) {
    return this.firebase
      .query<Client>('clients', orderBy('name', 'asc'), limit(maxItems))
      .pipe(map((companies) => companies.sort((a, b) => a.name.localeCompare(b.name))));
  }

  findById(id: Id) {
    return this.firebase.findOne<Client>(`clients/${id}`);
  }
}
