import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Event } from '@coalist/common';
import { ModalController, NavController, ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { formatISO } from 'date-fns';
import { EventService } from '../../services/event.service';
import { loadPaged, PagedLoading } from '../../util/loading';
import { brandColor, setThemeColor } from '../../util/theme';
import { EditEventPage } from '../edit-event/edit-event.page';

@Component({
  selector: 'events-page',
  templateUrl: './events.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsPage implements ViewWillEnter, ViewDidLeave {
  events: PagedLoading<Event>;

  constructor(
    private eventService: EventService,
    private modalController: ModalController,
    private navController: NavController,
  ) {
    const today = formatISO(new Date(), { representation: 'date' });
    this.events = loadPaged((limit) => this.eventService.findAfter(today, limit));
  }

  ionViewWillEnter() {
    setThemeColor(brandColor);
    this.events.enable();
  }

  ionViewDidLeave() {
    this.events.disable();
  }

  async add() {
    const modal = await this.modalController.create({
      component: EditEventPage,
    });
    await modal.present();
    const { data: eventId } = await modal.onDidDismiss();
    if (eventId) {
      await this.navController.navigateForward(`/events/${eventId}`);
    }
  }
}
