<ion-list>
  <ion-item-divider>
    {{ "admission.label.voting" | translate }}
    <ion-badge color="medium" slot="end">{{ votes.length }}</ion-badge>
  </ion-item-divider>

  <ion-item *ngFor="let vote of votes; trackBy: trackById">
    <ion-label class="ion-text-wrap">
      <h2>{{ vote.memberUserDisplayName }}</h2>
      <p>{{ vote.comment }}</p>
    </ion-label>
    <ion-icon
      [color]="vote.vote === 'supporting' ? 'primary' : 'warning'"
      slot="end"
      [name]="vote.vote === 'supporting' ? 'thumbs-up' : 'thumbs-down'"
    ></ion-icon>
  </ion-item>
</ion-list>
