import { FormControl, FormGroup } from '@angular/forms';
import { IonInput } from '@ionic/angular';

export type ModelFormGroup<T> = FormGroup<{
  [K in keyof T]: FormControl<T[K]>;
}>;

export function validate<C>(component: C, formGroup: FormGroup) {
  const firstInvalidControlName = Object.keys(formGroup.controls).find((field) => !formGroup.controls[field].valid);
  if (firstInvalidControlName) {
    const firstInvalidField = component[`${firstInvalidControlName}Input` as keyof C];
    if (firstInvalidField instanceof IonInput) {
      firstInvalidField.setFocus();
    }
    return false;
  }
  return true;
}
