import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { getToken } from '@firebase/messaging';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { FirebaseService } from './firebase.service';
import { IonicService } from './ionic.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private registration?: ServiceWorkerRegistration;

  constructor(
    private firebaseService: FirebaseService,
    private ionicService: IonicService,
    private platform: Platform,
    private push: SwPush,
    private navController: NavController,
    private translate: TranslateService,
  ) {
    this.init();
  }

  private async notificationReceived(notification: Notification) {
    console.log('notificationReceived', notification);
    if (notification.data?.eventId) {
      await this.ionicService.showAlert({
        header: notification.title,
        message: notification.body,
        buttons: [
          {
            role: 'cancel',
            text: this.translate.instant(`general.cancel`),
          },
          {
            role: 'ok',
            text: this.translate.instant(`notifications.showEvent`),
            handler: () => {
              this.navController.navigateForward(`events/${notification.data.eventId}`);
            },
          },
        ],
      });
    } else {
      await this.ionicService.showAlert({
        header: notification.title,
        message: notification.body,
        buttons: [
          {
            role: 'ok',
            text: this.translate.instant(`general.ok`),
          },
        ],
      });
    }
  }

  private async notificationActionPerformed(
    action: string,
    notification: NotificationOptions & {
      title: string;
    },
  ) {
    console.log('notificationActionPerformed', action, notification);
  }

  async init() {
    if (this.platform.is('pwa')) {
      await this.initWeb();
    }
  }

  async initWeb() {
    this.registration = await navigator.serviceWorker.register('firebase-messaging-sw.js');
    if (!this.registration) {
      console.error('service worker registration is undefined');
      return;
    }

    this.push.messages.subscribe(async (msg) => {
      console.log('message', msg);
      const message = msg as { notification: Notification };
      await this.notificationReceived(message.notification);
    });
    this.push.notificationClicks.subscribe(async (click) => {
      console.log('notificationActionPerformed', click);
      await this.notificationActionPerformed(click.action, click.notification);
    });
  }

  async requestPermission() {
    if (!this.registration) {
      return undefined;
    }

    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      console.info('notification permission not granted', permission);
      return undefined;
    } else {
      return await getToken(this.firebaseService.messaging, {
        vapidKey: environment.firebase.vapidKey,
        serviceWorkerRegistration: this.registration,
      });
    }
  }
}
