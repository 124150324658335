<ion-header>
  <ion-toolbar color="brand">
    <ion-title>{{ "availability.title" | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()"><ion-icon name="close" slot="icon-only"></ion-icon> </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list lines="none">
    <ion-item-divider>{{ "availability.stateLabel" | translate }}</ion-item-divider>
    <ion-radio-group #radioGroup [value]="availabilityStatus">
      <ion-item *ngFor="let availabilityStatus of availabilityStatuses">
        <ion-label class="ion-text-wrap">{{ "availability.description." + availabilityStatus | translate }}</ion-label>
        <ion-radio slot="end" [value]="availabilityStatus"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>

  <ion-list lines="none">
    <ion-item-divider>{{ "availability.commentLabel" | translate }}</ion-item-divider>

    <ion-item>
      <ion-textarea #textarea [value]="availability" autofocus="true" autoGrow="true"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-label>
        <ion-button color="primary" size="default" expand="block" (click)="save(textarea.value, radioGroup.value)">
          {{ "general.save" | translate }}
        </ion-button>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
