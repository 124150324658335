import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { brandColor, setThemeColor } from '../../util/theme';

@Component({
  selector: 'home-page',
  templateUrl: './admin.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPage implements ViewWillEnter {
  ionViewWillEnter() {
    setThemeColor(brandColor);
  }
}
