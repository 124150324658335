import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ClientInvoice, ClientInvoiceEntity, Id } from '@coalist/common';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { brandColor, setThemeColor } from '../../util/theme';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'edit-invoice-page',
  templateUrl: './edit-invoice.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditInvoicePage implements ViewWillEnter {
  invoice?: ClientInvoice;

  constructor(
    private invoiceService: InvoiceService,
    private modalController: ModalController,
  ) {}

  ionViewWillEnter() {
    setThemeColor(brandColor);
  }

  cancel() {
    this.modalController.dismiss();
  }

  async save(invoice: ClientInvoiceEntity) {
    if (this.invoice) {
      await this.invoiceService.save(this.invoice.id, invoice);
      await this.modalController.dismiss();
    } else {
      const invoiceId = await this.invoiceService.create(invoice);
      await this.modalController.dismiss(invoiceId);
    }
  }

  async delete(id: Id) {
    await this.modalController.dismiss();
    await this.invoiceService.delete(id);
  }
}
