<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title><span *ngIf="invoice | async as invoice">{{ invoice.invoiceNumber }}</span></ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="invoice | async as invoice" (click)="edit(invoice)" color="secondary">
        <ion-icon name="pencil" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="invoice | async as invoice">
    <invoice [invoice]="invoice"></invoice>
  </ng-container>
</ion-content>
