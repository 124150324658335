<ion-list>
  <ion-item-divider>
    <ion-badge color="medium" slot="end">{{ users.length }}</ion-badge>
  </ion-item-divider>
  <member-user-item
    *ngFor="let user of users; trackBy: trackById"
    [user]="user"
    routerLink="users/{{ user.id }}"
    [@flyInOut]
  ></member-user-item>
</ion-list>
