import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Id, MemberUser } from '@coalist/common';

export interface MemberUserItem {
  readonly id: Id;
  readonly firstName: string;
  readonly lastName: string;
  readonly detail?: string;
  readonly pictureUrl?: string;
}

export type MemberUserItemType = 'default' | 'self';

@Component({
  selector: 'member-user-item',
  templateUrl: './member-user-item.component.html',
  styleUrls: ['./member-user-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberUserItemComponent implements OnChanges {
  @Input() type: MemberUserItemType = 'default';
  @Input() routerLink?: string;
  @Input() user!: MemberUserItem | MemberUser;
  @Input() detail?: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      if ('companyName' in this.user) {
        this.detail = this.detail || this.user.companyName;
      } else {
        this.detail = this.detail || this.user.detail;
      }
    }
  }
}
