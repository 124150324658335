<ion-list>
  <ion-item-group>
    <ion-item>
      <ion-label class="user-avatar">
        <ion-avatar (click)="editProfilePicture()">
          @if (profilePictureSaving) {
            <ion-spinner></ion-spinner>
          } @else {
            <img [storageImg]="user.pictureUrl" fallback="/assets/img/avatar.svg" />
          }
        </ion-avatar>
      </ion-label>
    </ion-item>
  </ion-item-group>

  @if (notificationsAvailable) {
    @if (!user.notificationEnabled) {
      <ion-button
        class="ion-padding"
        color="primary"
        expand="block"
        (click)="onEnablePushNotifications()"
        [disabled]="pushNotificationsPending"
      >
        @if (pushNotificationsPending) {
          <ion-spinner></ion-spinner>
        } @else {
          Enable Push Notifications
        }
      </ion-button>
    } @else {
      <ion-button class="ion-padding" color="primary" expand="block" (click)="disablePushNotifications.emit()">
        Disable Push Notifications
      </ion-button>
    }
  }

  <ion-item-group>
    <ion-item-divider>{{ 'profile.edit' | translate }}</ion-item-divider>
    <ion-item routerLink="member">
      <ion-label>{{ 'memberProfile.title' | translate }}</ion-label>
    </ion-item>
    <ion-item routerLink="company">
      <ion-label>{{ user.companyName }}</ion-label>
    </ion-item>
    <ion-item routerLink="skills">
      <ion-label>{{ 'skills.title' | translate }}</ion-label>
    </ion-item>
    <ion-item routerLink="cv">
      <ion-label>{{ 'businessProfile.title' | translate }}</ion-label>
    </ion-item>
    <ion-button
      class="ion-padding-top ion-padding"
      color="primary"
      expand="block"
      fill="outline"
      (click)="logout.emit()"
      >Logout</ion-button
    >
  </ion-item-group>
</ion-list>
