<ion-list lines="none">
  <ion-item>
    <ion-label class="company-logo">
      <img *ngIf="client.logoUrl" [storageImg]="client.logoUrl" fallback="/assets/img/logo-placeholder.svg" />
      <ion-icon *ngIf="!client.logoUrl" name="business" color="medium"></ion-icon>
    </ion-label>
  </ion-item>
  <ng-container *ngIf="client.address">
    <ion-item-divider>{{ 'client.address' | translate }}</ion-item-divider>
    <address-item [address]="client.address"></address-item>
  </ng-container>
  <ng-container *ngIf="projects.length > 0">
    <ion-item-divider>{{ 'client.projects' | translate }}</ion-item-divider>
    <ion-item *ngFor="let project of projects" routerLink="/projects/{{ project.id }}">
      <ion-label>{{ project.name }}</ion-label>
    </ion-item>
  </ng-container>
</ion-list>
