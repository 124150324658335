<ion-header class="ion-no-border">
  <home-header *ngIf="user | async as user" [user]="user"></home-header>
</ion-header>

<ion-content>
  <home-content
    *ngIf="user | async as user"
    [user]="user"
    [eventsLoading]="(upcomingEvents.loading | async) ?? 'initial'"
    [upcomingEvents]="(upcomingEvents.data | async) ?? []"
    [admissionsLoading]="(admissions.loading | async) ?? 'initial'"
    [admissions]="(admissions.data | async) ?? []"
    (editAvailability)="editAvailability()"
  ></home-content>
</ion-content>
