<ion-list>
  <ion-item-group *ngFor="let date of dates" [@flyInOut]>
    <ion-item-divider sticky>{{ date | formatDate: 'd. MMMM [yyyy]' }}</ion-item-divider>
    <ion-item *ngFor="let event of eventsByDate[date]; trackBy: trackById" [routerLink]="event.id" [@flyInOut]>
      <ion-label>
        <h2>{{ event.title }}</h2>
        <p>{{ event.location }}</p>
      </ion-label>
      <ion-badge slot="end" color="light">{{ event.participantIds.length }}</ion-badge>
    </ion-item>
  </ion-item-group>

  <ion-infinite-scroll [disabled]="!hasMore" (ionInfinite)="onLoadMore()">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-list>
