<ion-list lines="none">
  <ion-item>
    <a routerLink="/app">
      <ion-label slot="start" class="logo"><img src="/assets/img/logo.png" /></ion-label>
    </a>
  </ion-item>

  <ion-item>
    <ion-label>
      <ion-button type="submit" size="default" expand="block" color="primary" (click)="login.emit()">
        <span *ngIf="!loading">{{ 'login.login' | translate }}</span>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </ion-label>
  </ion-item>

  <ion-item>
    <ion-note color="danger">
      <ng-container *ngIf="loginError">{{ 'login.error' | translate }}</ng-container>
    </ion-note>
  </ion-item>
</ion-list>
