import { BehaviorSubject, catchError, EMPTY, from, Observable, ReplaySubject, Subscription, switchMap } from 'rxjs';

export type LoadingState = 'initial' | 'loading' | 'success' | 'offline' | 'error';

export interface Loading<T> {
  loading: Observable<LoadingState>;
  data: Observable<T>;
  enable: () => void;
  disable: () => void;
}

export interface PagedItems<T> {
  items: T[];
  hasMore: boolean;
}

export interface PagedLoading<T> {
  loading: Observable<LoadingState>;
  page: Observable<PagedItems<T>>;
  loadMore(): void;
  enable(): void;
  disable(): void;
}

export function loadPaged<T>(loadFunc: (limit: number) => Observable<T[]>, pageSize = 20): PagedLoading<T> {
  const state = new BehaviorSubject<LoadingState>('loading');
  const limit = new BehaviorSubject(pageSize);
  const page = new ReplaySubject<PagedItems<T>>();
  let subscription: Subscription | undefined = undefined;
  return {
    loading: state,
    page,
    enable() {
      if (subscription !== undefined) {
        return;
      }
      subscription = limit
        .pipe(
          switchMap((limit) =>
            loadFunc(limit).pipe(
              catchError((e) => {
                console.error(e);
                state.next('offline');
                return EMPTY;
              }),
            ),
          ),
        )
        .subscribe((items) => {
          state.next('success');
          page.next({
            items,
            hasMore: items.length === limit.value,
          });
        });
    },
    disable() {
      subscription?.unsubscribe();
      subscription = undefined;
    },
    loadMore() {
      limit.next(limit.value + pageSize);
    },
  };
}

export function load<T>(loadFunc: Observable<T> | Promise<T>): Loading<T> {
  const loading = new BehaviorSubject<LoadingState>('loading');
  const state = new ReplaySubject<T>();
  let subscription: Subscription | undefined = undefined;
  return {
    loading: loading,
    data: state,
    enable: () => {
      if (subscription !== undefined) {
        return;
      }
      from(loadFunc)
        .pipe(
          catchError((e) => {
            console.error(e);
            loading.next('offline');
            return EMPTY;
          }),
        )
        .subscribe((value) => {
          loading.next('success');
          state.next(value);
        });
    },
    disable: () => {
      subscription?.unsubscribe();
      subscription = undefined;
    },
  };
}
