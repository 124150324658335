import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Client } from '@coalist/common';
import { IonInfiniteScroll } from '@ionic/angular';
import { flyInOut } from '../../../util/animations';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'client-list',
  templateUrl: './client-list.component.html',
  styleUrl: './client-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class ClientListComponent {
  @ViewChild(IonInfiniteScroll) infiniteScroll?: IonInfiniteScroll;

  @Input({ required: true }) clients!: Client[];
  @Input() hasMore = true;
  @Output() loadMore = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clients) {
      this.infiniteScroll?.complete();
    }
    if (changes.hasMore && this.infiniteScroll) {
      this.infiniteScroll.disabled = this.hasMore;
    }
  }

  onLoadMore() {
    this.loadMore.next();
  }

  trackById = trackById;
}
