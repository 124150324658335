import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Event, EventEntity, Id } from '@coalist/common';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { formatISO, parseISO } from 'date-fns';
import { EventForm } from '../../components/event/edit-event/edit-event.component';
import { EventService } from '../../services/event.service';
import { brandColor, setThemeColor } from '../../util/theme';

@Component({
  selector: 'edit-event-page',
  templateUrl: './edit-event.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditEventPage implements ViewWillEnter {
  event?: Event;

  constructor(
    private eventService: EventService,
    private modalController: ModalController,
  ) {}

  ionViewWillEnter() {
    setThemeColor(brandColor);
  }

  cancel() {
    this.modalController.dismiss();
  }

  async save(form: EventForm) {
    const startDate = formatISO(parseISO(form.startDate), { representation: 'date' });
    const endDate = formatISO(parseISO(form.startDate), { representation: 'date' });
    const event: Omit<EventEntity, 'participantIds'> = {
      date: startDate,
      allDay: form.allDay,
      startDate: !form.allDay ? form.startDate : startDate,
      endDate: !form.allDay ? form.endDate : endDate,
      duration: !form.allDay ? form.duration : 1440,
      title: form.title,
      location: form.location,
      online: form.online,
      description: form.description,
    };

    if (this.event) {
      await this.eventService.save(this.event.id, event);
      await this.modalController.dismiss();
    } else {
      const eventId = await this.eventService.create({
        ...event,
        participantIds: [],
      });
      await this.modalController.dismiss(eventId);
    }
  }

  async delete(eventId: Id) {
    await this.modalController.dismiss();
    await this.eventService.delete(eventId);
  }
}
