import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { DurationMinutes, IsoDateTime, IsoTime } from '@coalist/common';
import { IonDatetime, ModalController } from '@ionic/angular';
import {
  addDays,
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  format,
  formatISO,
  isBefore,
  parse,
  parseISO,
  subDays,
} from 'date-fns';

@Component({
  selector: 'time-picker',
  templateUrl: 'time-picker.page.html',
  styleUrls: ['time-picker.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerPage {
  @ViewChild(IonDatetime) datePicker!: IonDatetime;

  startDate!: Date;
  startTimeValue?: IsoTime;
  endDate?: Date;
  endTimeValue?: IsoTime;
  duration?: DurationMinutes;

  set time(time: IsoDateTime) {
    this.startDate = parseISO(time);
    this.startTimeValue = format(this.startDate, 'HH:mm');
  }

  set startTime(time: IsoDateTime) {
    this.startDate = parseISO(time);
    this.startTimeValue = format(this.startDate, 'HH:mm');
    this.updateDuration();
  }

  set endTime(time: IsoDateTime) {
    this.endDate = parseISO(time);
    this.endTimeValue = format(this.endDate, 'HH:mm');
    this.updateDuration();
  }

  constructor(private modalController: ModalController) {}

  startTimeChanged(time?: IsoTime) {
    if (time) {
      this.startDate = parse(time, 'HH:mm', this.startDate);
      if (this.endDate && this.duration) {
        this.endDate = addMinutes(this.startDate, this.duration);
        this.endTimeValue = format(this.endDate, 'HH:mm');
      }
    }
  }

  endTimeChanged(time?: IsoTime) {
    if (time && this.endDate) {
      this.endDate = parse(time, 'HH:mm', this.endDate);
      if (isBefore(this.endDate, this.startDate)) {
        this.endDate = addDays(this.endDate, 1);
      }
      if (differenceInDays(this.endDate, this.startDate) > 1) {
        this.endDate = subDays(this.endDate, 1);
      }
      this.updateDuration();
    }
  }

  updateDuration() {
    if (this.startTimeValue && this.endTimeValue && this.endDate) {
      this.duration = differenceInMinutes(
        parse(this.endTimeValue, 'HH:mm', this.startDate),
        parse(this.startTimeValue, 'HH:mm', this.endDate)
      );
    }
  }

  ok() {
    if (!this.endDate) {
      this.modalController.dismiss(this.startTimeValue);
    } else {
      this.modalController.dismiss({
        start: formatISO(this.startDate, { representation: 'complete' }),
        end: formatISO(this.endDate, { representation: 'complete' }),
        duration: differenceInMinutes(this.endDate, this.startDate),
      });
    }
  }

  cancel() {
    this.modalController.dismiss();
  }
}
