<ion-list>
  <ion-item-divider>
    <ion-badge color="medium" slot="end">{{ companies.length }}</ion-badge>
  </ion-item-divider>
  <member-company-item
    *ngFor="let company of companies; trackBy: trackById"
    [company]="company"
    [users]="usersByCompany[company.id]"
    routerLink="companies/{{ company.id }}"
    [@flyInOut]
  ></member-company-item>
</ion-list>
