<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button color="secondary"></ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="event | async as event">{{event.title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="event | async as event" (click)="edit(event)" color="secondary">
        <ion-icon name="pencil" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="user | async as user">
    <event
      *ngIf="event | async as event"
      [user]="user"
      [event]="event"
      [participants]="(participants | async) ?? []"
      (attend)="attend(event.id, user.id, $event)"></event>
  </ng-container>
</ion-content>
