import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { de, Locale } from 'date-fns/locale';

export interface AppLocale {
  locale: string;
  localeData?: any;
  dateFnsLocale?: Locale;
}

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private readonly locales: Record<string, AppLocale>;
  readonly defaultLocale: string;
  readonly defaultLanguage: string;
  locale: string;
  language: string;
  dateFnsLocale?: Locale;

  constructor(private translate: TranslateService) {
    this.locales = {
      de: { locale: 'de', localeData: localeDe, dateFnsLocale: de },
      // en: { locale: 'en' }
    };
    this.defaultLocale = 'de';
    this.defaultLanguage = localeToLanguage(this.defaultLocale);
    this.locale = this.defaultLocale;
    this.language = this.defaultLanguage;
  }

  async use(locale: string | null) {
    const resolvedLocale = this.locales[locale ?? this.defaultLocale] || this.locales[this.defaultLocale];
    if (resolvedLocale.localeData) {
      registerLocaleData(resolvedLocale.localeData, resolvedLocale.locale);
    }
    this.locale = resolvedLocale.locale;
    this.language = localeToLanguage(resolvedLocale.locale);
    this.dateFnsLocale = resolvedLocale.dateFnsLocale;

    this.translate.setDefaultLang(this.defaultLanguage);
    await this.translate.use(this.language);
    return resolvedLocale.locale;
  }
}

export function localeToLanguage(locale: string) {
  return locale.indexOf('-') > 0 ? locale.split('-')[0] : locale;
}
