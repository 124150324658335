import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Id, MemberCompany, MemberUser } from '@coalist/common';
import { flyInOut } from '../../../util/animations';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'member-company-list',
  templateUrl: './member-company-list.component.html',
  styleUrls: ['./member-company-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class MemberCompanyListComponent implements OnChanges {
  @Input() companies!: MemberCompany[];
  @Input() users: MemberUser[] | null = null;

  usersByCompany: Record<Id, MemberUser[]> = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes.users) {
      this.usersByCompany = (this.users ?? []).reduce<Record<Id, MemberUser[]>>((users, user) => {
        if (users[user.companyId]) {
          users[user.companyId].push(user);
        } else {
          users[user.companyId] = [user];
        }
        return users;
      }, {});
    }
  }

  trackById = trackById;
}
