<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="user | async as user">{{ user.firstName }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <user-profile
    *ngIf="user | async as user"
    [user]="user"
    [profilePictureSaving]="(profilePictureSaving | async)!"
    (profilePictureChanged)="updateProfilePicture($event)"
    (enablePushNotifications)="enablePushNotifications()"
    (disablePushNotifications)="disablePushNotifications()"
    (logout)="logout()"
  ></user-profile>
</ion-content>
