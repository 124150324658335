import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, ImageOptions, Photo } from '@capacitor/camera';
import {
  ActionSheetController,
  ActionSheetOptions,
  AlertController,
  AlertOptions,
  ModalController,
  PickerController,
  ToastController,
  ToastOptions,
} from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { NavModalPage } from '../pages/nav-modal/nav-modal.page';

export interface ConfirmActionSheet {
  header?: string;
  subHeader?: string;
  confirmButtonText?: string;
}

export interface EditTextProps {
  title: string;
  text?: string;
  placeholder?: string;
  multiline?: boolean;
}

@Injectable({ providedIn: 'root' })
export class IonicService {
  constructor(
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private modalController: ModalController,
    private pickerController: PickerController,
    private toastController: ToastController,
    private translate: TranslateService,
  ) {}

  async showAlert(options: AlertOptions) {
    const alert = await this.alertController.create(options);
    await alert.present();
    const { data } = await alert.onDidDismiss();
    return data;
  }

  async showModal<T>(options: ModalOptions, cardModal = false): Promise<T | undefined> {
    const modal = await this.modalController.create({
      presentingElement: cardModal ? document.querySelector('ion-router-outlet') ?? undefined : undefined,
      ...options,
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }

  async showNavModal(rootComponent: ModalOptions, cardModal = false) {
    return this.showModal(
      {
        component: NavModalPage,
        componentProps: rootComponent,
      },
      cardModal,
    );
  }

  async actionSheet(config: ActionSheetOptions) {
    const actionSheet = await this.actionSheetController.create(config);
    await actionSheet.present();
    return await actionSheet.onDidDismiss();
  }

  async confirmActionSheet(config: ConfirmActionSheet) {
    const result = await this.actionSheet({
      header: config.header,
      subHeader: config.subHeader,
      buttons: [
        {
          role: 'cancel',
          text: this.translate.instant('general.cancel'),
        },
        {
          role: 'destructive',
          text: config.confirmButtonText ?? this.translate.instant('general.ok'),
        },
      ],
    });
    return result.role === 'destructive';
  }

  async choosePicture(options?: Partial<ImageOptions>, showDelete = false): Promise<'delete' | Photo | undefined> {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          role: 'camera',
          icon: 'camera',
          text: 'Foto aufnehmen',
        },
        {
          role: 'library',
          icon: 'images',
          text: 'Foto auswählen',
        },
        ...(showDelete
          ? [
              {
                role: 'destructive',
                icon: 'trash',
                text: 'Löschen',
              },
            ]
          : []),
        {
          role: 'cancel',
          text: 'Abbrechen',
        },
      ],
    });
    await actionSheet.present();
    const { role } = await actionSheet.onDidDismiss();

    if (role === 'camera' || role === 'library') {
      return await Camera.getPhoto({
        height: 300,
        width: 300,
        quality: 100,
        correctOrientation: true,
        allowEditing: true,
        ...options,
        resultType: CameraResultType.DataUrl,
        source: role === 'camera' ? CameraSource.Camera : CameraSource.Photos,
      });
    }
    return role === 'destructive' ? 'delete' : undefined;
  }

  async showToast(options: ToastOptions) {
    const toast = await this.toastController.create(options);
    toast.present();
  }
}
