import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { ClientInvoice } from '@coalist/common';
import { FirebaseService } from '../../../services/firebase.service';

@Component({
  selector: 'invoice',
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceComponent implements OnChanges {
  constructor(
    private firebaseService: FirebaseService,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input() invoice!: ClientInvoice;
  pdfUrl: string | null = null;

  async ngOnChanges() {
    if (this.invoice.storagePath != null) {
      this.pdfUrl = await this.firebaseService.downloadUrl(this.invoice.storagePath);
    }
    // TODO: move to invoice page
    this.cdr.markForCheck();
  }
}
