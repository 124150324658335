<ion-list>
  <ion-item-group>
    <ion-item (click)="editLogo()">
      <ion-label class="company-logo">
        <ng-container *ngIf="!logoSaving">
          <img [storageImg]="company.logoUrl" fallback="/assets/img/logo-placeholder.svg" />
        </ng-container>
        <ion-spinner *ngIf="logoSaving"></ion-spinner>
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'editMemberCompany.businessTitle' | translate }}</ion-item-divider>
    <ion-item>
      <ion-label (click)="editBusinessTitle()">{{ user.businessTitle }}</ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'editMemberCompany.roles' | translate }}</ion-item-divider>
    <ion-item *ngFor="let role of memberUserRoles">
      <ion-label>{{ 'role.' + role | translate }}</ion-label>
      <ion-toggle
        color="primary"
        [checked]="user.roles.includes(role)"
        (ionChange)="changeRole(role, $any($event).detail.checked)"></ion-toggle>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'editMemberCompany.users' | translate }}</ion-item-divider>
    <ion-item-sliding *ngFor="let user of users">
      <member-user-item [user]="user"></member-user-item>
      <ion-item-options>
        <ion-item-option color="danger">
          <ion-icon slot="icon-only" name="trash" (click)="deleteUser(user)"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-item-group>

  <ion-item-group *ngIf="company.address">
    <ion-item-divider>{{ 'profile.address' | translate }}</ion-item-divider>
    <address-item [address]="company.address" [showMap]="false"></address-item>
  </ion-item-group>
</ion-list>
