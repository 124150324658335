import { Injectable } from '@angular/core';
import {
  MemberUser,
  MemberUserDetails,
  MemberUserDeviceEntity,
  MemberUserEntity,
  MemberUserProfile,
} from '@coalist/common';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { GoogleAuthProvider, signInWithCredential, signInWithPopup, signOut, User } from '@firebase/auth';
import { arrayUnion, UpdateData } from '@firebase/firestore';
import { NavController, Platform } from '@ionic/angular';
import { user } from 'rxfire/auth';
import { filter, firstValueFrom, Observable, of, shareReplay, switchMap, take } from 'rxjs';
import { WelcomePage } from '../pages/welcome/welcome.page';
import { DeviceService } from './device.service';
import { FirebaseService } from './firebase.service';
import { IonicService } from './ionic.service';
import { MemberUserService } from './member-user.service';
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  authUser: Observable<User | null>;
  userOptional: Observable<MemberUser | null>;
  user: Observable<MemberUser>;
  profile: Observable<MemberUserProfile>;
  protectedData: Observable<MemberUserDetails>;

  constructor(
    private deviceService: DeviceService,
    private firebase: FirebaseService,
    private ionicService: IonicService,
    private memberUserService: MemberUserService,
    private navController: NavController,
    private notificationService: NotificationService,
    private platform: Platform,
  ) {
    this.authUser = user(this.firebase.auth);
    this.userOptional = this.authUser.pipe(
      switchMap((user) => (user?.email ? this.memberUserService.findByEmail(user.email) : of(null))),
      shareReplay(1),
    );
    this.user = this.userOptional.pipe(
      filter((member: MemberUser | null): member is MemberUser => member !== null),
      shareReplay(1),
    );
    this.profile = this.user.pipe(
      switchMap((user) => this.memberUserService.findProfileById(user.id)),
      shareReplay(1),
    );
    this.protectedData = this.user.pipe(
      switchMap((user) => this.memberUserService.findProtectedById(user.id)),
      shareReplay(1),
    );

    this.userOptional.pipe(filter((user) => !user?.active)).subscribe(() => {
      this.navController.navigateRoot('/login', { animated: false });
    });

    this.user.pipe(take(1)).subscribe(async (user) => {
      if (!user.welcomeShown) {
        await this.ionicService.showModal({
          component: WelcomePage,
        });
      }
    });

    this.platform.ready().then(() => {
      console.log(platform.platforms());
      if (this.platform.is('capacitor') && this.platform.is('ios')) {
        GoogleAuth.initialize({
          clientId: '177519525013-mepg0lg6t3c85j8djfe4g02rg3lh1k6e.apps.googleusercontent.com',
          scopes: ['email'],
          grantOfflineAccess: true,
        });
      }
    });
  }

  async googleLogin() {
    if (this.platform.is('capacitor') && this.platform.is('ios')) {
      return this.googleLoginCapacitor();
    } else {
      return this.googleLoginWeb();
    }
  }

  async googleLoginCapacitor() {
    const user = await GoogleAuth.signIn();
    const credential = GoogleAuthProvider.credential(user.authentication.idToken);
    await signInWithCredential(this.firebase.auth, credential);
    return await firstValueFrom(this.memberUserService.findByEmail(user.email));
  }

  async googleLoginWeb() {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      hd: 'coalist.ch',
    });

    const userCredential = await signInWithPopup(this.firebase.auth, provider);
    if (
      !userCredential ||
      userCredential.user.email === null ||
      !checkDomain(userCredential.user.email, 'coalist.ch')
    ) {
      await this.logout();
      return null;
    }

    return await firstValueFrom(this.memberUserService.findByEmail(userCredential.user.email));
  }

  async logout() {
    return signOut(this.firebase.auth);
  }

  async welcomeShown() {
    const user = await firstValueFrom(this.user);
    this.firebase.mergeDoc<UpdateData<MemberUserEntity>>(`member-users/${user.id}`, {
      welcomeShown: true,
    });
  }

  async enablePushNotifications() {
    const token = await this.notificationService.requestPermission();
    const user = await firstValueFrom(this.user);
    const device = await this.deviceService.getDeviceInfo();

    this.firebase.mergeDoc<UpdateData<MemberUserEntity>>(`member-users/${user.id}`, {
      notificationEnabled: !!token,
    });

    if (token) {
      this.firebase.mergeDoc<UpdateData<MemberUserDeviceEntity>>(`member-users/${user.id}/devices/${device.uuid}`, {
        ...device,
        notificationToken: arrayUnion(token),
        userId: user.id,
      });
    }
  }

  async disablePushNotifications() {
    const user = await firstValueFrom(this.user);

    this.firebase.mergeDoc<UpdateData<MemberUserEntity>>(`member-users/${user.id}`, {
      notificationEnabled: false,
    });
  }
}

function checkDomain(email: string | null, domain: string) {
  if (email === null) {
    return false;
  }
  return domain === email.substring(email.indexOf('@') + 1);
}
