import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuration',
})
export class FormatDurationPipe implements PipeTransform {
  transform(value: number | null | undefined, pattern: string = 'mediumDate') {
    if (!value) {
      return '0';
    }

    const hours = Math.floor(value / 60);
    const minutes = value - hours * 60;

    if (hours > 0) {
      return `${`${hours}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}`;
    }
    return `${`${minutes}`.padStart(2, '0')}`;
  }
}
