<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <ion-list>
    <ion-item-group>
      <ion-item-divider>{{ 'event.name' | translate }}</ion-item-divider>
      <ion-item>
        <ion-input #titleInput type="text" formControlName="title" [placeholder]="'event.name' | translate"></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.title.errors"
          slot="end"
          name="warning-outline"
          color="warning"></ion-icon>
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider>{{ 'event.date' | translate }}</ion-item-divider>
      <ion-item (click)="selectDate()">
        <ion-icon slot="start" name="calendar"></ion-icon>
        <ion-label>{{ formGroup.value.startDate | formatDate }}</ion-label>
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider>
        {{ 'event.time' | translate }}
        <ion-label slot="end">{{ 'event.allDay' | translate }}</ion-label>
      </ion-item-divider>
      <ion-item class="duration">
        <ion-icon slot="start" name="time"></ion-icon>
        <ion-label (click)="selectTime()">
          <ng-container *ngIf="!formGroup.value.allDay">
            <div class="times">
              <div>
                {{ formGroup.value.startDate | formatDate: 'HH:mm' }}
              </div>
              <ng-container *ngIf="(formGroup.value.duration ?? 0) > 0">
                -
                <div>
                  {{ formGroup.value.endDate | formatDate: 'HH:mm' }}
                </div>
              </ng-container>
            </div>
            <p *ngIf="(formGroup.value.duration ?? 0) > 0">
              {{ 'editEvent.duration' | translate }}: {{ formGroup.value.duration | formatDuration }} h
            </p>
          </ng-container>
        </ion-label>
        <div slot="end">
          <div class="toggle-label-top"></div>
          <ion-toggle formControlName="allDay"></ion-toggle>
        </div>
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider>
        {{ 'event.location' | translate }}
        <ion-label slot="end">{{ 'event.online' | translate }}</ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-icon slot="start" name="location"></ion-icon>
        <ion-input type="text" formControlName="location" [placeholder]="'event.location' | translate"></ion-input>
        <div slot="end">
          <div class="toggle-label-top"></div>
          <ion-toggle formControlName="online"></ion-toggle>
        </div>
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider>{{ 'event.description' | translate }}</ion-item-divider>
      <ion-item>
        <ion-textarea
          [rows]="1"
          [autoGrow]="true"
          formControlName="description"
          [placeholder]="'event.description' | translate"></ion-textarea>
      </ion-item>
      <div class="ion-padding">
        <ion-button expand="block" color="danger" *ngIf="event" (click)="deleteEvent(event.id)">
          {{ 'general.delete' | translate }}
        </ion-button>
        <ion-button type="submit" expand="block">
          {{ (event ? 'general.save' : 'editEvent.create') | translate }}
        </ion-button>
      </div>
    </ion-item-group>
  </ion-list>
</form>
