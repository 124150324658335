import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MemberCompany, MemberUser } from '@coalist/common';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { SlidesComponent } from '../../components/slides/slides.component';
import { MemberCompanyService } from '../../services/member-company.service';
import { MemberUserService } from '../../services/member-user.service';
import { load, Loading } from '../../util/loading';
import { brandColor, setThemeColor } from '../../util/theme';
import { SlidesPage } from '../slides/slides-page/slides.page';

export type MembersSlide = 'companies' | 'people';

@Component({
  selector: 'members-page',
  templateUrl: './members.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersPage extends SlidesPage<MembersSlide> implements ViewWillEnter, ViewDidLeave {
  @ViewChild('slides') slides?: SlidesComponent;

  companies: Loading<MemberCompany[]>;
  users: Loading<MemberUser[]>;

  constructor(
    private memberCompanyService: MemberCompanyService,
    private memberUserService: MemberUserService,
  ) {
    super(['people', 'companies']);
    this.companies = load(this.memberCompanyService.memberCompanies);
    this.users = load(this.memberUserService.memberUsers);
  }

  ionViewWillEnter() {
    setThemeColor(brandColor);
    this.companies.enable();
    this.users.enable();
  }

  ionViewDidLeave() {
    this.companies.disable();
    this.users.disable();
  }

  add() {}
}
