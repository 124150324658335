<ion-list>
  <ng-container *ngFor="let client of clients; trackBy: trackById">
    <ion-item-divider>{{ client.name }}</ion-item-divider>
    <ion-item
      *ngFor="let project of projectsByClient[client.id]; trackBy: trackById"
      [routerLink]="project.id"
      [@flyInOut]
    >
      <ion-avatar slot="start">
        <ion-icon name="business" color="medium"></ion-icon>
      </ion-avatar>
      <ion-label>
        <h2>{{ project.name }}</h2>
        <p>
          <ng-container *ngFor="let memberId of project.activeMemberIds; let last = last">
            {{ memberUserName(memberId) }}<ng-container *ngIf="!last">,</ng-container>
          </ng-container>
        </p>
      </ion-label>
      <ng-container *ngFor="let memberId of project.activeMemberIds">
        <ion-avatar slot="end" *ngIf="pictureUrl(memberId) as pictureUrl" class="project-member">
          <img [storageImg]="pictureUrl" />
        </ion-avatar>
      </ng-container>
    </ion-item>
    <ion-item *ngIf="projects.length === 0">
      <ion-label>
        <h2>{{ 'projects.empty' | translate }}</h2>
        <p>{{ 'projects.emptyDetail' | translate }}</p>
      </ion-label>
    </ion-item>
  </ng-container>
  <ion-infinite-scroll [disabled]="!hasMore" (ionInfinite)="onLoadMore()">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-list>
