import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComponentProps } from '@ionic/core';

@Component({
  selector: 'nav-modal',
  templateUrl: './nav-modal.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavModalPage<T> {
  component!: T;
  componentProps?: ComponentProps<T>;
}
