<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/home"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'app.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="deviceInfo | async as deviceInfo">
  <ion-list lines="none" *ngIf="version | async as version">
    <ion-item-divider>{{ 'app.updates' | translate }}</ion-item-divider>
    <div class="version-container">
      <ion-item *ngIf="version.type === 'current'">
        <ion-label>
          <div class="ion-text-center">
            <ion-icon name="checkmark-circle"></ion-icon>
          </div>
          <p class="ion-text-center">{{ 'app.upToDate' | translate }}</p>
        </ion-label>
      </ion-item>

      <ion-item *ngIf="version.type === 'outdated'">
        <ion-label>
          <div class="ion-text-center">
            <ion-icon name="refresh-circle"></ion-icon>
          </div>
          <p class="ion-text-center">{{ 'app.newVersion' | translate }}</p>
        </ion-label>
      </ion-item>

      <ion-item *ngIf="version.type === 'downloading'">
        <ion-label>
          <ion-spinner name="circles"></ion-spinner>
          <p class="ion-text-center">{{ 'app.downloading' | translate }}</p>
        </ion-label>
      </ion-item>

      <ion-item *ngIf="version.type === 'error'">
        <ion-label class="ion-text-wrap">
          <div class="ion-text-center">
            <ion-icon name="ban-outline"></ion-icon>
          </div>
          <p class="ion-text-center">{{ version.error }}</p>
        </ion-label>
      </ion-item>
    </div>

    <ion-item>
      <ion-label>
        <ion-button
          [disabled]="checkButtonDisabled | async"
          size="default"
          color="primary"
          expand="block"
          (click)="checkForUpdates()"
        >
          {{ 'app.searchForUpdates' | translate }}
        </ion-button>
        <ion-button size="default" color="primary" expand="block" (click)="refresh()">
          <ng-container *ngIf="version.type === 'outdated' || version.type === 'downloading'; else elseBlock">
            {{ 'app.installUpdate' | translate }}
          </ng-container>
          <ng-template #elseBlock>
            {{ 'app.installAgain' | translate }}
          </ng-template>
        </ion-button>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list lines="none">
    <ion-item-divider>{{ 'app.appInfo' | translate }}</ion-item-divider>
    <ion-item>
      <ion-label>
        <h2>{{ 'app.gitHashShort' | translate }}</h2>
        <p>{{ deviceInfo.gitHashShort }}</p>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <h2>{{ 'app.gitHash' | translate }}</h2>
        <p>{{ deviceInfo.gitHash }}</p>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list lines="none">
    <ion-item-divider>Device</ion-item-divider>
    <ion-item>
      <ion-label>
        <h2>{{ 'app.platform' | translate }}</h2>
        <p>{{ deviceInfo.platform }}</p>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <h2>{{ 'app.manufacturer' | translate }}</h2>
        <p>{{ deviceInfo.manufacturer }}</p>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <h2>{{ 'app.operationSystem' | translate }}</h2>
        <p>{{ deviceInfo.operatingSystem }}, {{ deviceInfo.osVersion }}</p>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <h2>{{ 'app.model' | translate }}</h2>
        <p>{{ deviceInfo.model }}</p>
      </ion-label>
    </ion-item>

    <ng-container *ngIf="deviceInfo.platform !== 'web'">
      <ion-item>
        <ion-label>
          <h2>{{ 'app.nativeAppVersion' | translate }}</h2>
          <p>{{ deviceInfo.appVersion }}</p>
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <h2>{{ 'app.nativeAppBuild' | translate }}</h2>
          <p>{{ deviceInfo.appBuild }}</p>
        </ion-label>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>
