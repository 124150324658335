<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button color="secondary"></ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="company | async as company">{{ company.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <member-company *ngIf="company | async as company" [company]="company" [users]="(users | async)!"></member-company>
</ion-content>
