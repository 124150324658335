<ion-list>
  <ion-item *ngFor="let client of clients; trackBy: trackById" [routerLink]="'clients/' + client.id" [@flyInOut]>
    <ion-avatar slot="start">
      <ion-icon name="business" color="medium"></ion-icon>
    </ion-avatar>
    <ion-label>
      <h2>{{ client.name }}</h2>
    </ion-label>
  </ion-item>
  <ion-item *ngIf="clients.length === 0">
    <ion-label>
      <h2>{{ 'clients.empty' | translate }}</h2>
      <p>{{ 'clients.emptyDetail' | translate }}</p>
    </ion-label>
  </ion-item>
  <ion-infinite-scroll [disabled]="!hasMore" (ionInfinite)="onLoadMore()">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-list>
