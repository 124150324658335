import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DeviceService } from './device.service';
import { LanguageService } from './language.service';
import { UserService } from './user.service';

type Version =
  | { type: 'current' }
  | { type: 'outdated'; currentHash: string; latestHash: string }
  | { type: 'downloading'; hash: string }
  | { type: 'error'; error: string };

@Injectable({ providedIn: 'root' })
export class AppService {
  version = new BehaviorSubject<Version>({ type: 'current' });

  constructor(
    private deviceService: DeviceService,
    private languageService: LanguageService,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private toastController: ToastController,
    private translateService: TranslateService,
    private userService: UserService,
  ) {}

  async start() {
    await this.platform.ready();
    const device = await this.deviceService.getDeviceInfo();
    await this.languageService.use(device.language);
    await firstValueFrom(this.userService.userOptional);
    await hideSplashScreen();
    this.subscribeToAppUpdates();
  }

  subscribeToAppUpdates() {
    this.swUpdate.versionUpdates.subscribe((evt: VersionEvent) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          this.version.next({ type: 'downloading', hash: evt.version.hash });
          break;
        case 'VERSION_READY':
          this.version.next({
            type: 'outdated',
            currentHash: evt.currentVersion.hash,
            latestHash: evt.latestVersion.hash,
          });
          this.presentAppUpdateToast();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          this.version.next({ type: 'error', error: evt.error });
          break;
      }
    });
  }

  refresh() {
    document.location.reload();
  }

  async checkForUpdates() {
    try {
      await this.swUpdate.checkForUpdate();
    } catch (e) {
      this.version.next({
        type: 'error',
        error: e?.toString() || 'unknown error',
      });
    }
  }

  async presentAppUpdateToast() {
    const toast = await this.toastController.create({
      message: this.translateService.instant('app.update'),
      buttons: [
        {
          text: this.translateService.instant('general.yes'),
          role: 'info',
          handler: () => document.location.reload(),
        },
        {
          text: this.translateService.instant('general.no'),
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }
}

async function hideSplashScreen() {
  const splash = document ? document.getElementById('splash') : null;
  if (splash !== null) {
    splash.addEventListener('animationstart', async () => {
      await SplashScreen.hide();
    });
    splash.addEventListener('animationend', () => {
      splash.remove();
    });
    splash.className = 'fade-out';
  } else {
    await SplashScreen.hide();
  }
}
