import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { IonicSlides } from '@ionic/angular';
import { distinctUntilChanged, Subscription } from 'rxjs';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { ComponentChanges } from '../../util/component-changes';
import { pageTransition } from '../../util/page-transitions';

@Component({
  selector: 'slides',
  templateUrl: './slides.component.html',
  styleUrl: './slides.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlidesComponent implements OnChanges, OnDestroy {
  @Input() options?: SwiperOptions;
  @Output() slideChanged = new EventEmitter<number>();

  @ViewChild('swiperElement') set setSwiperElement(swiperElement: ElementRef) {
    this.swiperElement = swiperElement;
    this.init();
  }

  private swiperElement!: ElementRef;
  private swiper!: Swiper;
  private subscriptions = new Subscription();

  ngOnChanges(changes: ComponentChanges<SlidesComponent>) {
    if (changes.options && this.swiper) {
      this.swiper.params = {
        ...this.swiper.params,
        ...this.options,
      };
    }
  }

  private init() {
    if (this.swiper) {
      return;
    }

    this.swiper = new Swiper(this.swiperElement.nativeElement, {
      modules: [IonicSlides],
      ...this.options,
    });
    this.swiper.on('slideChange', () => {
      this.slideChanged.emit(this.swiper?.activeIndex);
    });
    this.subscriptions.add(
      pageTransition.pipe(distinctUntilChanged()).subscribe((transition) => {
        if (transition) {
          const activeIndex = this.swiper.activeIndex;
          this.swiper.allowTouchMove = false;
          this.swiper.setProgress(0);
          this.swiper.slideTo(activeIndex);
        } else {
          this.swiper.allowTouchMove = true;
        }
      }),
    );
  }

  get activeIndex() {
    return this.swiper?.activeIndex;
  }

  slideTo(index: number) {
    this.swiper?.slideTo(index);
  }

  slidePrevious() {
    this.swiper?.slidePrev();
  }

  slideNext() {
    this.swiper?.slideNext();
  }

  update() {
    this.swiper?.updateSlides();
    this.swiper?.update();
  }

  ngOnDestroy() {
    this.swiper?.destroy();
    this.subscriptions.unsubscribe();
  }
}
