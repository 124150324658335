import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autoFocus]',
})
export class AutoFocusDirective {
  @Input() autoFocus? = true;

  constructor(element: ElementRef) {
    setTimeout(() => {
      if (this.autoFocus ?? true) {
        if ('setFocus' in element.nativeElement) {
          element.nativeElement.setFocus();
        }
      }
    }, 500);
  }
}
