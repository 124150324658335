<ion-list lines="none">
  <ion-item-group>
    <ion-item>
      <ion-label>{{ invoice.qrReference }}</ion-label>
      <ion-button slot="end"><ion-button [href]="pdfUrl">PDF</ion-button></ion-button>
    </ion-item>
    <ion-item>{{ invoice.invoiceDate }}</ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'invoice.client' | translate }}</ion-item-divider>
    <ion-item class="address">
      <ion-label class="address">
        <p>{{ invoice.client.name }}</p>
        <p>{{ invoice.client.address }}</p>
        <p>{{ invoice.client.country }}-{{ invoice.client.zip }} {{ invoice.client.city }}</p>
      </ion-label>
    </ion-item>
  </ion-item-group>
</ion-list>
