<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">{{ 'general.cancel' | translate}}</ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="ok()">{{ 'general.ok' | translate}}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-textarea
        *ngIf="multiline"
        [autoFocus]
        [autoGrow]="true"
        [value]="text"
        [placeholder]="placeholder ?? title"
        autocapitalize="on"
        (ionChange)="text = $any($event).detail.value"
      ></ion-textarea>
      <ion-input
        *ngIf="!multiline"
        [autoFocus]
        [value]="text"
        [placeholder]="placeholder ?? title"
        autocapitalize="on"
        (ionChange)="text = $any($event).detail.value"
      ></ion-input>
    </ion-item>
  </ion-list>
</ion-content>
