<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title><span *ngIf="project | async as project">{{ project.name }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="project | async as project">
    <project
      *ngIf="details | async as details"
      [project]="project"
      [details]="details"
      [memberUsers]="(memberUsers | async) ?? []"
      [invoices]='(invoices | async) ?? []'
    ></project>
  </ng-container>
</ion-content>

<ion-footer>
  <ion-button expand="block" (click)="creatInvoice()">{{ 'project.createInvoice' | translate }}</ion-button>
</ion-footer>
