import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MemberUser } from '@coalist/common';

@Component({
  selector: 'member-user-availability-item',
  templateUrl: './member-user-availability-item.component.html',
  styleUrls: ['./member-user-availability-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberUserAvailabilityItemComponent {
  @Input() user!: MemberUser;
}
