import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { AppService } from '../../services/app.service';
import { DeviceInfo, DeviceService } from '../../services/device.service';
import { brandColor, setThemeColor, white } from '../../util/theme';

@Component({
  selector: 'app-info-page',
  templateUrl: './app-info-page.component.html',
  styleUrls: ['./app-info-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppInfoPage implements ViewWillEnter, ViewDidLeave {
  deviceInfo: Observable<DeviceInfo>;
  checkButtonDisabled = new BehaviorSubject(false);
  version = this.appService.version;

  constructor(
    private deviceService: DeviceService,
    private appService: AppService,
  ) {
    this.deviceInfo = from(this.deviceService.getDeviceInfo());
    this.checkForUpdates();
  }

  ionViewWillEnter() {
    setThemeColor(brandColor);
  }

  ionViewDidLeave() {
    setThemeColor(white);
  }

  async checkForUpdates() {
    await this.appService.checkForUpdates();
  }

  refresh() {
    this.appService.refresh();
  }
}
