import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MemberCompany, MemberUser, MemberUserRole, memberUserRoles } from '@coalist/common';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../services/modal.service';
import { PickerService } from '../../../services/picker.service';

@Component({
  selector: 'edit-member-company',
  templateUrl: './edit-member-company.component.html',
  styleUrls: ['./edit-member-company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMemberCompanyComponent {
  @Input() user!: MemberUser;
  @Input() company!: MemberCompany;
  @Input() logoSaving = false;
  @Input() users!: MemberUser[];
  @Output() logoChanged = new EventEmitter<string>();
  @Output() businessTitleChanged = new EventEmitter<string>();

  memberUserRoles = memberUserRoles;

  constructor(private modalService: ModalService, private picker: PickerService, private translate: TranslateService) {}

  async editLogo() {
    const dataUrl = await this.picker.photo();
    if (dataUrl) {
      this.logoChanged.emit(dataUrl);
    }
  }

  async editBusinessTitle() {
    const newBusinessTitle = await this.modalService.editText(
      this.translate.instant('editMemberCompany.businessTitle'),
      this.user.businessTitle
    );
    if (newBusinessTitle) {
      this.businessTitleChanged.emit(newBusinessTitle);
    }
  }

  changeRole(role: MemberUserRole, checked: boolean) {}

  deleteUser(user: MemberUser) {}
}
