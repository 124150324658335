<ion-header>
  <ion-toolbar color="brand">
    <ion-title>{{invoice?.invoiceNumber ?? 'editInvoice.title' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <edit-invoice [invoice]="invoice" (save)="save($event)" (delete)="delete($event)"></edit-invoice>
</ion-content>
