<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="home">
      <ion-icon name="home"></ion-icon>
      <ion-label>{{ "tabs.home" | translate }}</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="events">
      <ion-icon name="calendar"></ion-icon>
      <ion-label>{{ "tabs.events" | translate }}</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="members">
      <ion-icon name="business"></ion-icon>
      <ion-label>{{ "tabs.members" | translate }}</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="coalist">
      <ion-icon name="sunny"></ion-icon>
      <ion-label>{{ "tabs.coalist" | translate }}</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="projects">
      <ion-icon name="briefcase"></ion-icon>
      <ion-label>{{ "tabs.projects" | translate }}</ion-label>
    </ion-tab-button>
    <!--    <ion-tab-button tab="admin">-->
    <!--      <ion-icon name="construct"></ion-icon>-->
    <!--      <ion-label>{{ "tabs.admin" | translate }}</ion-label>-->
    <!--    </ion-tab-button>-->
  </ion-tab-bar>
</ion-tabs>
