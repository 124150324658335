import { ChangeDetectionStrategy, ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { IsoDate } from '@coalist/common';
import { ModalController } from '@ionic/angular';
import { PickerService } from '../../services/picker.service';

export type FormField = TextFormField | TextAreaFormField | NumberFormField | DateFormField;

export interface AbstractFormField {
  id: string;
  labelPosition?: 'fixed' | 'stacked' | 'floating';
  validators?: ValidatorFn[];
}

export interface TextFormField extends AbstractFormField {
  type: 'text';
  label: string;
  value: string;
}

export interface TextAreaFormField extends AbstractFormField {
  type: 'textarea';
  label: string;
  value: string;
}

export interface NumberFormField extends AbstractFormField {
  type: 'number';
  label: string;
  value: number;
}

export interface DateFormField extends AbstractFormField {
  type: 'date';
  label: string;
  value: IsoDate;
}

@Component({
  selector: 'edit-form-page',
  templateUrl: './edit-form.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditFormPage {
  @ViewChildren('input') inputs?: QueryList<any>;

  title = '';
  formFields: FormField[] = [];
  formGroup!: UntypedFormGroup;
  showErrors = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalController: ModalController,
    private picker: PickerService
  ) {}

  set fields(fields: FormField[]) {
    this.formFields = fields;
    this.formGroup = new UntypedFormGroup(
      fields.reduce<Record<string, AbstractControl>>((formGroup, field) => {
        formGroup[field.id] = new FormControl(field.value, { validators: field.validators });
        return formGroup;
      }, {})
    );
  }

  async editDate(field: DateFormField) {
    const updatedDate = await this.picker.date(field.value ?? '');
    if (updatedDate !== undefined) {
      field.value = updatedDate;
      this.cdr.markForCheck();
    }
  }

  cancel() {
    this.modalController.dismiss();
  }

  ok() {
    if (this.formGroup.valid) {
      this.modalController.dismiss(this.formGroup.value);
    } else {
      this.showErrors = true;
      const firstInvalidIndex = this.formFields.findIndex((field) => !this.formGroup.controls[field.id].valid);
      const input = this.inputs?.get(firstInvalidIndex);
      if (input && 'setFocus' in input) {
        input.setFocus();
      }
    }
  }
}
