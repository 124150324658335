<ion-list>
  <ion-item-group *ngIf="details.description">
    <ion-item>
      <ion-label class="ion-text-wrap">{{ details.description }}</ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'project.client' | translate }}</ion-item-divider>
    <ion-item routerLink="/projects/clients/{{ project.clientId }}">
      <ion-label>{{ project.clientName }}</ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>
      {{ 'project.members' | translate }}
      <ion-badge slot="end">{{ activeMembers.length }}</ion-badge>
    </ion-item-divider>
    <member-user-item *ngFor="let member of activeMembers; trackBy: trackById" [user]="member"></member-user-item>
    <ion-item *ngIf="inactiveMembers.length > 0 && !showInactive" (click)="toggleInactive()" detail>
      <ion-label>{{ inactiveMembers.length }} inaktiv</ion-label>
    </ion-item>
  </ion-item-group>
  <ion-item-group *ngIf="showInactive">
    <ion-item-divider (click)="toggleInactive()">
      {{ 'project.inactiveMembers' | translate }}
      <ion-badge slot="end">{{ inactiveMembers.length }}</ion-badge>
    </ion-item-divider>
    <member-user-item *ngFor="let member of inactiveMembers; trackBy: trackById" [user]="member"></member-user-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'project.invoices' | translate }}</ion-item-divider>
    <ion-item *ngFor="let invoice of invoices; trackBy: trackById" routerLink="/projects/invoices/{{ invoice.id }}">
      <ion-label>
        <h2>{{ invoice.invoiceNumber }}</h2>
        <p>{{ invoice.invoiceDate }}</p>
      </ion-label>
      <div slot="end">{{ invoice.amountBruttoTotal | currency: 'CHF' : 'CHF ' }}</div>
    </ion-item>
  </ion-item-group>
</ion-list>
