<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'memberProfile.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <edit-member-profile
    *ngIf="user | async as user"
    [user]="user"
    [userProtected]="userProtected | async"
    (userChanged)="updateUser($event)"
    (userProtectedChanged)="updateUserProtected($event)">
  </edit-member-profile>
</ion-content>
