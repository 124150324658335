import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Client, Id, MemberUser, Project } from '@coalist/common';
import { IonInfiniteScroll } from '@ionic/angular';
import { flyInOut } from '../../../util/animations';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class ProjectListComponent implements OnChanges {
  @ViewChild(IonInfiniteScroll) infiniteScroll?: IonInfiniteScroll;

  @Input({ required: true }) projects!: Project[];
  @Input({ required: true }) memberUsers!: MemberUser[];
  @Input() hasMore = true;
  @Output() loadMore = new EventEmitter<void>();

  clients: Client[] = [];
  projectsByClient: Record<string, Project[]> = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projects) {
      this.clients = this.projects
        .reduce<Client[]>((clients, project) => {
          if (!clients.find((c) => c.id === project.clientId)) {
            clients.push({
              id: project.clientId,
              name: project.clientName,
            });
          }
          return clients;
        }, [])
        .sort(sortClients);
      this.projectsByClient = this.clients.reduce<Record<Id, Project[]>>((projectsByClient, client) => {
        projectsByClient[client.id] = this.projects
          .filter((project) => project.clientId === client.id)
          .sort(sortProjects);
        return projectsByClient;
      }, {});
      this.infiniteScroll?.complete();
    }
    if (changes.hasMore && this.infiniteScroll) {
      this.infiniteScroll.disabled = this.hasMore;
    }
  }

  onLoadMore() {
    this.loadMore.next();
  }

  memberUserName(memberId: string) {
    const user = this.memberUsers.find((user) => user.id === memberId);
    return user?.firstName;
  }

  pictureUrl(memberId: string) {
    const user = this.memberUsers.find((user) => user.id === memberId);
    return user?.pictureUrl;
  }

  trackById = trackById;
}

function sortClients(a: Client, b: Client) {
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

function sortProjects(a: Project, b: Project) {
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}
