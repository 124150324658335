<ion-list>
  <ion-item-group>
    <ion-item-divider>{{ 'profile.bio' | translate }}</ion-item-divider>
    <ion-item>
      <ion-label class="ion-text-wrap" *ngIf="profile.miniBiography">{{ profile.miniBiography }}</ion-label>
      <ion-label class="placeholder" *ngIf="!profile.miniBiography">
        {{ 'profile.noBio' | translate }}
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'profile.education' | translate }}</ion-item-divider>
    <ion-item *ngFor="let education of profile.education">
      <ion-label>
        <h2>{{ education.academicTitle }}</h2>
        <p>{{ education.graduationYear }} {{ education.institution }}</p>
      </ion-label>
    </ion-item>
    <ion-item *ngIf="profile.education.length === 0">
      <ion-label class="placeholder">
        {{ 'profile.noEducation' | translate }}
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'profile.languages' | translate }}</ion-item-divider>
    <ion-item *ngFor="let language of profile.languages">
      <ion-label>{{ language }}</ion-label>
    </ion-item>
    <ion-item *ngIf="profile.languages.length === 0">
      <ion-label class="placeholder">
        {{ 'profile.noLanguages' | translate }}
      </ion-label>
    </ion-item>
  </ion-item-group>
</ion-list>
