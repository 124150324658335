<ion-list>
  <ion-item-group>
    <ion-item>
      <ion-label class="user-avatar">
        <ion-avatar>
          <img [storageImg]="memberUser.pictureUrl" fallback="/assets/img/avatar.svg" />
        </ion-avatar>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>
        <h2>{{ memberUser.firstName }} {{ memberUser.lastName }}</h2>
        <p *ngIf="memberUser.title">{{ memberUser.title }}</p>
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'profile.company' | translate }}</ion-item-divider>
    <member-company-item
      routerLink="../../companies/{{ memberUser.companyId }}"
      [company]="{ id: memberUser.companyId, name: memberUser.companyName }"
    ></member-company-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'profile.contact' | translate }}</ion-item-divider>
    <ion-item href="mailto:{{ memberUser.email }}" detailIcon="none">
      <ion-icon slot="start" name="mail"></ion-icon>
      <ion-label>{{ memberUser.email }}</ion-label>
    </ion-item>
    <ion-item *ngIf="memberUser.phone" href="tel:{{ memberUser.phone }}" detailIcon="none">
      <ion-icon slot="start" name="call"></ion-icon>
      <ion-label>{{ memberUser.phone }}</ion-label>
    </ion-item>
    <ng-container *ngFor="let link of links">
      <ion-item [href]="link.href" target="_blank">
        <ion-icon slot="start" [name]="link.icon"></ion-icon>
        <ion-label>{{ link.text }}</ion-label>
      </ion-item>
    </ng-container>
  </ion-item-group>

  <ion-item-group *ngIf="memberUser.address">
    <ion-item-divider>{{ 'profile.address' | translate }}</ion-item-divider>
    <address-item [address]="memberUser.address" [showMap]="true"></address-item>
  </ion-item-group>
</ion-list>
