import { ViewDidEnter, ViewDidLeave, ViewWillEnter, ViewWillLeave } from '@ionic/angular';

export const brandColor = '#d33a2c';
export const white = '#ffffff';

export function setThemeColor(color: string) {
  const themeColorMeta = document.querySelector('meta[name=theme-color]');
  if (themeColorMeta) {
    themeColorMeta.setAttribute('content', color);
  }
}

export function getThemeColor() {
  const themeColorMeta = document.querySelector('meta[name=theme-color]');
  if (themeColorMeta) {
    return themeColorMeta.getAttribute('content') ?? white;
  }
  return white;
}

export abstract class ThemedPage implements ViewWillEnter, ViewDidEnter, ViewWillLeave, ViewDidLeave {
  previousTheme = '';

  ionViewWillEnter() {
    this.previousTheme = getThemeColor();
    setThemeColor(brandColor);
  }

  ionViewDidEnter() {
    setThemeColor(brandColor);
  }

  ionViewWillLeave() {
    setThemeColor(this.previousTheme);
  }

  ionViewDidLeave() {
    setThemeColor(this.previousTheme);
  }
}
