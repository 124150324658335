<ion-list>
  <ion-item-group>
    <ion-item-divider>{{ 'profile.bio' | translate }}</ion-item-divider>
    <ion-item (click)="editMiniBiography()">
      <ion-label class="ion-text-wrap">{{ profile?.miniBiography }}</ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>
      {{ 'profile.education' | translate }}
      <ion-button slot="end" (click)="editEducation()">
        <ion-icon slot="icon-only" name="add"></ion-icon>
      </ion-button>
    </ion-item-divider>
    <ion-item-sliding *ngFor="let education of profile?.education; let index = index">
      <ion-item (click)="editEducation(index, education)">
        <ion-label>
          <h2>{{ education.academicTitle }}</h2>
          <p>{{ education.graduationYear }} {{ education.institution }}</p>
        </ion-label>
      </ion-item>
      <ion-item-options>
        <ion-item-option color="danger">
          <ion-icon slot="icon-only" name="trash" (click)="deleteEducation(index)"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>
      {{ 'profile.languages' | translate }}
      <ion-button slot="end" (click)="editLanguage()"> <ion-icon slot="icon-only" name="add"></ion-icon> </ion-button>
    </ion-item-divider>
    <ion-item-sliding *ngFor="let language of profile?.languages; let index = index">
      <ion-item (click)="editLanguage(index, language)">
        <ion-label>{{ language }}</ion-label>
      </ion-item>
      <ion-item-options>
        <ion-item-option color="danger">
          <ion-icon slot="icon-only" name="trash" (click)="deleteLanguage(index)"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-item-group>
</ion-list>
