<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="company | async as company">{{ company.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="user | async as user">
    <edit-member-company
      *ngIf="company | async as company"
      [user]="user"
      [company]="company"
      [logoSaving]="(logoSaving | async)!"
      [users]="(users | async) ?? []"
      (logoChanged)="updateLogo($event)"
      (businessTitleChanged)="updateBusinessTitle($event)"
    ></edit-member-company>
  </ng-container>
</ion-content>
