import { Injectable } from '@angular/core';
import { ClientInvoice, ClientInvoiceEntity, Config, Id } from '@coalist/common';
import { limit, orderBy, PartialWithFieldValue, where } from '@firebase/firestore';
import { FirebaseService } from './firebase.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  constructor(private firebase: FirebaseService) {}

  async nextInvoiceNumber(): Promise<number> {
    await this.firebase.increment('config/config', 'invoiceNumberSequence');
    return (await this.firebase.getDocument<Config>('config/config'))?.invoiceNumberSequence;
  }

  findAll(maxItems = 20): Observable<ClientInvoice[]> {
    return this.firebase.query<ClientInvoice>('invoices', limit(maxItems), orderBy('invoiceDate', 'asc'));
  }

  findById(id: Id) {
    return this.firebase.findOne<ClientInvoice>(`invoices/${id}`);
  }

  findByProject(projectId: Id): Observable<ClientInvoice[]> {
    return this.firebase.query<ClientInvoice>(
      'invoices',
      where('projectId', '==', projectId),
      orderBy('invoiceDate', 'desc'),
    );
  }

  create(data: ClientInvoiceEntity) {
    return this.firebase.addDoc<ClientInvoiceEntity>('invoices', data);
  }

  save(id: Id, data: PartialWithFieldValue<ClientInvoice>) {
    return this.firebase.updateDoc(`invoices/${id}`, data);
  }

  delete(id: Id) {
    return this.firebase.deleteDoc(`invoices/${id}`);
  }
}
