import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';

@Directive({
  selector: '[storageImg]',
})
export class StorageImgDirective implements OnChanges {
  @Input('storageImg') storagePath?: string;
  @Input() fallback = '';

  constructor(
    private element: ElementRef,
    private firebase: FirebaseService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.storagePath) {
      this.updateImg();
    }
  }

  async updateImg() {
    if (this.storagePath) {
      const url = await this.firebase.downloadUrl(this.storagePath);
      if (url) {
        this.updateImgSrc(url);
        return;
      }
    }
    this.updateImgSrc(null);
  }

  updateImgSrc(location: string | null) {
    this.element.nativeElement.src = location ?? this.fallback;
  }
}
