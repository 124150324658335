import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPage {
  user = this.userService.authUser;
  loading = new BehaviorSubject(false);
  loginError = new BehaviorSubject(false);

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private userService: UserService,
  ) {}

  async login() {
    this.loginError.next(false);
    this.loading.next(true);
    const user = await this.userService.googleLogin();
    this.loading.next(false);
    if (!user?.active) {
      this.loginError.next(true);
    } else {
      this.navController.navigateRoot('/', { animated: false });
    }
  }

  logout() {
    this.userService.logout();
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
