<ion-header>
  <ion-toolbar color="brand">
    <ion-title>{{'events.title' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="add()" color="secondary">
        <ion-icon name="add" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <loading *ngIf="events.loading | async as loading" [loading]="loading">
    <event-list
      *ngIf="events.page | async as page"
      [events]="page.items"
      [hasMore]="page.hasMore"
      (loadMore)="events.loadMore()"
    ></event-list>
  </loading>
</ion-content>
