import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Event, MemberUser } from '@coalist/common';
import { isSameDay, parseISO } from 'date-fns';
import { flyInOut } from '../../../util/animations';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class EventComponent implements OnChanges {
  trackById = trackById;

  @Input() user!: MemberUser;
  @Input() event!: Event;
  @Input() participants!: MemberUser[];
  @Output() attend = new EventEmitter<boolean>();

  otherParticipants: MemberUser[] = [];
  sameDay = true;
  attends = false;
  location = '';
  mapsUrl?: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.event) {
      this.sameDay = isSameDay(parseISO(this.event.startDate), parseISO(this.event.endDate));
      if (!this.event.online && this.event.location !== this.location) {
        this.location = this.event.location;
        this.mapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://maps.google.com/maps?q=${this.event.location}&t=&z=15&ie=UTF8&iwloc=&output=embed`
        );
      }
    }
    if (changes.participants) {
      this.otherParticipants = this.participants.filter((participant) => participant.id !== this.user.id);
      this.attends = !!this.participants.find((participant) => participant.id === this.user.id);
    }
  }
}
