import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ClientInvoice, ClientInvoiceEntity, Detail, Id, MainLineItem } from '@coalist/common';
import { ModalController } from '@ionic/angular';
import { addHours, formatISO, parseISO } from 'date-fns';
import { DatePickerPage } from '../../../pages/date-picker/date-picker.page';
import { flyInOut } from '../../../util/animations';
import { today } from '../../../util/date';
import { generate } from 'node-iso11649';
import { customAlphabet } from 'nanoid';
import { validate } from '../../../util/form';

@Component({
  selector: 'edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class EditInvoiceComponent implements OnChanges {
  @Input() invoice?: ClientInvoiceEntity;
  @Output() save = new EventEmitter<ClientInvoiceEntity>();
  @Output() delete = new EventEmitter<Id>();

  formGroup = this.fb.group({
    invoiceDate: [
      formatISO(addHours(parseISO(today('complete')), 1), { representation: 'complete' }),
      Validators.required,
    ],
    invoiceNumber: ['100000', Validators.required],
    qrReference: [createQrReference(), Validators.required],
    invoiceText: [
      `We hope you are satisfied with our services and look forward to do more business with you. Don't hesitate to get in touch with us in
case of any questions.


Client contact: CLIENT_CONTACT
Reference: PROJECT_REFERENCE`,
      Validators.required,
    ],
    paymentTermDays: [30, Validators.required],
    vatRate: [0.077, Validators.required],
    client: this.fb.group({
      name: ['SIX Interbank Clearing AG', Validators.required],
      address: ['Hardturmstrasse', Validators.required],
      zip: ['8021', Validators.required],
      city: ['Zürich', Validators.required],
      country: ['CH', Validators.required],
    }),
    supplierAddress: ['Genossenschaft coalist | Nordstrasse 168, 8037 Zürich', Validators.required],
    vatNumber: ['CHE-250.981.916 MWST', Validators.required],
    mainLineItems: JSON.stringify(
      [
        {
          quantity: 8,
          description:
            'Software Engineering SIC5-Platform - SIX Interbank Clearing AG -October 2023\nIwan Birrer: 13 d',
          unit: 'h',
          unitPrice: 180,
        },
        { quantity: 13, description: 'Project B', unit: 'd', unitPrice: 1350 },
        { quantity: 14, description: 'Project C', unit: 'h', unitPrice: 100 },
      ],
      null,
      2,
    ),

    withDetails: [true],
    details: JSON.stringify(
      [
        {
          computeMainLineItem: true,
          lineItems: [
            { date: '2023-11-17', description: 'From Details', quantity: 8, unit: 'h' },
            { date: '2023-11-18', description: 'From Details', quantity: 8, unit: 'h' },
            { date: '2023-11-19', description: 'From Details', quantity: 8, unit: 'h' },
            { date: '2023-11-20', description: 'From Details', quantity: 8, unit: 'h' },
          ],
          unit: 'h',
          targetUnit: 'd',
          unitPrice: 1350,
          conversionFactor: 8.4,
          roundingFraction: 100,
          vatRate: 0.023,
          description: 'SIC 5 - Iwan Birrer',
        },
        {
          computeMainLineItem: false,
          lineItems: [
            { date: '2023-11-17', description: 'From Details', quantity: 8 },
            { date: '2023-11-18', description: 'From Details', quantity: 8 },
            { date: '2023-11-19', description: 'From Details', quantity: 8 },
            { date: '2023-11-20', description: 'From Details', quantity: 8 },
          ],
          unit: 'h',
          description: 'SIC 4 - Iwan Birrer',
        },
      ] as Detail[],
      null,
      2,
    ),

    unitPrice: [1350, [Validators.required]],
    footer: [
      `Genossenschaft coalist | Nordstrasse 168, 8037 Zürich, Schweiz | accounting@coalist.ch | CHE-250.981.916 MWST
  Hypothekarbank Lenzburg AG - IBAN: CH79 0830 7000 3530 1230 0 - QR-IBAN: CH48 3011 6000 3530 1230 0 - BIC: HYPLCH22XXX`,
      Validators.required,
    ],
  });

  showErrors = false;
  constructor(
    private modalController: ModalController,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    console.log('invoice', this.invoice);

    if (changes.invoice && this.invoice) {
      this.formGroup.patchValue({
        ...this.invoice,
        details: this.invoice.details ? JSON.stringify(this.invoice.details, null, 2) : '',
        mainLineItems: JSON.stringify(this.invoice.mainLineItems, null, 2),
      });
    }
  }

  submit() {
    if (validate(this, this.formGroup)) {
      const mainLineItems = JSON.parse(this.formGroup.controls.mainLineItems.value ?? '') as MainLineItem[];
      const detailsJson = this.formGroup.controls.details.value?.trim();
      const details = detailsJson ? (JSON.parse(detailsJson) as Detail[]) : undefined;
      const clientInvoice = { ...this.formGroup.value, mainLineItems, details } as ClientInvoice;
      console.log('submit', clientInvoice);
      this.save.emit(clientInvoice);
    } else {
      this.showErrors = true;
    }
  }

  async selectDate() {
    const modal = await this.modalController.create({
      component: DatePickerPage,
      componentProps: { date: this.formGroup.value.invoiceDate },
      cssClass: 'date-picker-modal',
    });
    await modal.present();
    const { data: invoiceDate } = await modal.onDidDismiss();
    if (invoiceDate) {
      this.formGroup.patchValue({ invoiceDate });
      this.cdr.markForCheck();
    }
  }

  async deleteInvoice(id: Id) {}
}

export function createQrReference() {
  const random = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 19)();
  const reference = `CO${random}`;
  return generate({ reference, pretty: false });
}
