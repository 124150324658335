import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isThisYear, parseISO } from 'date-fns';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'formatDate',
  pure: false
})
export class FormatDatePipe implements PipeTransform {
  constructor(private localeService: LanguageService) {}

  transform(value: string | null = '', pattern: string = 'mediumDate') {
    if (!value || value.length === 0) {
      return '';
    }
    let effectivePattern = pattern;
    if (pattern.indexOf('[yyyy]') >= 0) {
      const date = parseISO(value);
      if (isThisYear(date)) {
        effectivePattern = pattern.replace('[yyyy]', '').trim();
      } else {
        effectivePattern = pattern.replace('[yyyy]', 'yyyy').trim();
      }
    }

    return formatDate(value, effectivePattern, this.localeService.locale ?? 'en');
  }
}
