import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AdmissionVoteForm } from '../../../pages/admission-vote/admission-vote.page';

@Component({
  selector: 'admission-vote',
  templateUrl: './admission-vote.component.html',
  styleUrls: ['./admission-vote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdmissionVoteComponent {
  @Input() form!: AdmissionVoteForm;
}
