import { Injectable } from '@angular/core';
import {
  Id,
  MemberUser,
  MemberUserDetails,
  MemberUserDetailsEntity,
  MemberUserEntity,
  MemberUserProfile,
  MemberUserProfileEntity,
} from '@coalist/common';
import { orderBy, UpdateData, where } from '@firebase/firestore';
import { map, Observable, shareReplay } from 'rxjs';
import { cacheForever } from '../model/constants';
import { FirebaseService } from './firebase.service';

@Injectable({ providedIn: 'root' })
export class MemberUserService {
  memberUsers = this.findActive().pipe(shareReplay(1));

  constructor(private firebase: FirebaseService) {}

  findActive(): Observable<MemberUser[]> {
    return this.firebase
      .query<MemberUser>('member-users', where('active', '==', true))
      .pipe(map((user) => user.sort((a, b) => a.firstName.localeCompare(b.firstName))));
  }

  findById(id: Id): Observable<MemberUser> {
    return this.firebase.findOne<MemberUser>(`member-users/${id}`);
  }

  findProtectedById(userId: Id): Observable<MemberUserDetails> {
    return this.firebase.findOne<MemberUserDetails>(`member-users/${userId}/member-user-details/member-user-details`);
  }

  findProfileById(userId: Id): Observable<MemberUserProfile> {
    return this.firebase.findOne<MemberUserProfile>(`member-users/${userId}/member-user-details/member-user-profile`);
  }

  findByCompanyId(companyId: Id): Observable<MemberUser[]> {
    return this.firebase.query<MemberUser>(
      'member-users',
      where('companyId', '==', companyId),
      orderBy('firstName', 'asc'),
    );
  }

  findByEmail(email: string): Observable<MemberUser | null> {
    return this.firebase.queryOne<MemberUser>('member-users', where('email', '==', email));
  }

  profilePictureUrl(pictureUrl: string) {
    return this.firebase.downloadUrl(pictureUrl);
  }

  updateMemberUser(userId: Id, update: UpdateData<MemberUserEntity>) {
    return this.firebase.updateDoc(`member-users/${userId}`, update);
  }

  updateProtectedData(userId: Id, update: UpdateData<MemberUserDetailsEntity>) {
    return this.firebase.setDoc(`member-users/${userId}/member-user-details/member-user-details`, update, {
      merge: true,
    });
  }

  updateProfile(userId: Id, update: UpdateData<MemberUserProfileEntity>) {
    return this.firebase.setDoc(`member-users/${userId}/member-user-details/member-user-profile`, update, {
      merge: true,
    });
  }

  async updateProfilePicture(userId: Id, imagePath: string) {
    const storageLocation = `profile-pictures/${this.firebase.id()}`;
    const result = await this.firebase.upload(storageLocation, imagePath, cacheForever);
    this.updateMemberUser(userId, {
      pictureUrl: storageLocation,
    });
  }
}
