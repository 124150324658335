import { Injectable } from '@angular/core';
import { Id, Project, ProjectDetails } from '@coalist/common';
import { limit, orderBy, where } from '@firebase/firestore';
import { FirebaseService } from './firebase.service';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(private firebase: FirebaseService) {}

  findActive(maxItems = 20) {
    return this.firebase.query<Project>(
      'projects',
      orderBy('clientName', 'asc'),
      where('active', '==', true),
      limit(maxItems),
    );
  }

  findById(id: Id) {
    return this.firebase.findOne<Project>(`projects/${id}`);
  }

  findDetailsById(id: Id) {
    return this.firebase.findOne<ProjectDetails>(`projects/${id}/project-details/project-details`);
  }

  findByClientId(clientId: Id) {
    return this.firebase.query<Project>('projects', where('clientId', '==', clientId));
  }
}
