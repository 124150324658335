import { Injectable } from '@angular/core';
import { Camera, CameraSource } from '@capacitor/camera';
import { IsoDate } from '@coalist/common';
import { TranslateService } from '@ngx-translate/core';
import { defaultImageSettings } from '../model/constants';
import { DatePickerPage } from '../pages/date-picker/date-picker.page';
import { IonicService } from './ionic.service';

@Injectable({ providedIn: 'root' })
export class PickerService {
  constructor(
    private ionicService: IonicService,
    private translate: TranslateService,
  ) {}

  async date(date: IsoDate | ''): Promise<IsoDate | undefined> {
    return await this.ionicService.showModal({
      component: DatePickerPage,
      componentProps: { date },
      cssClass: 'date-picker-modal',
    });
  }

  async photo(): Promise<string | undefined> {
    const picture = await Camera.getPhoto({
      ...defaultImageSettings,
      source: CameraSource.Photos,
    });
    return picture?.dataUrl;
  }

  async picture(): Promise<string | undefined> {
    const picture = await Camera.getPhoto({
      ...defaultImageSettings,
      source: CameraSource.Photos,
    });
    return picture?.dataUrl;
  }

  async pictureWithActionSheet(): Promise<string | undefined> {
    const data = await this.ionicService.actionSheet({
      buttons: [
        {
          text: this.translate.instant('picture.camera'),
          data: {
            source: CameraSource.Camera,
          },
        },
        {
          text: this.translate.instant('picture.photos'),
          data: {
            source: CameraSource.Photos,
          },
        },
        {
          text: this.translate.instant('general.cancel'),
          role: 'cancel',
        },
      ],
    });

    if (data?.data.source) {
      const picture = await Camera.getPhoto({
        ...defaultImageSettings,
        source: data.data.source,
      });
      return picture?.dataUrl;
    }
    return undefined;
  }
}
