<ion-list lines="none">
  <ion-item>
    <ion-label>{{ "admission.vote.supporting" | translate }}</ion-label>
    <ion-toggle [checked]="form.supporting" (ionChange)="form.supporting = $any($event).detail.checked"></ion-toggle>
  </ion-item>
  <ion-item-divider>{{ "admission.vote.comment" | translate }}</ion-item-divider>
  <ion-item>
    <ion-textarea autoGrow [value]="form.comment" (ionChange)="form.comment = $any($event).detail.value"></ion-textarea>
  </ion-item>
</ion-list>
