import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { MemberUser } from '@coalist/common';

@Component({
  selector: 'home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeHeaderComponent {
  @Input() user!: MemberUser;
}
