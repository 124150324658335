<div class="dots"></div>
<ion-list lines="none">
  <ion-item class="logo-item">
    <img class="logo" src="/assets/img/coalist-logo-name.svg" alt="Coalist Logo" />
    <ion-button slot="end" fill="none" (click)="dismiss.emit()"
      ><ion-icon slot="icon-only" name="close"></ion-icon
    ></ion-button>
  </ion-item>
</ion-list>
<slides>
  <slide class="welcome">
    <div class="header">
      <div class="ion-padding-horizontal">
        <div class="welcome-header">
          <h1>Hallo {{ user.firstName }}</h1>
          <ion-avatar routerLink="/home/profile">
            <img [storageImg]="user.pictureUrl" fallback="/assets/img/avatar.svg" alt="avatar image" />
          </ion-avatar>
        </div>
        <p>Willkommen beim coalist Hub.</p>
        <p>Hier erfährst du alles über coalist:</p>
      </div>
      <ion-list>
        <ion-item>Mitglieder (Firmen und Personen)</ion-item>
        <ion-item>Projekte</ion-item>
        <ion-item>Admissions</ion-item>
        <ion-item>Meetings und Events</ion-item>
        <ion-item>und vieles mehr</ion-item>
      </ion-list>
    </div>
    <ion-button (click)="next()" expand="block" fill="outline" class="ion-padding">Weiter</ion-button>
  </slide>
  <slide class="notifications">
    <div class="header ion-padding-horizontal">
      <h1>Push-Notifikationen</h1>
      <p>
        coalist will dir Push-Notifikationen schicken, um über neue Events zu informieren, an anstehende Events
        erinnern, Admission-Abstimmungen anzukündigen oder andere Informationen teilen.
      </p>
      <p>Willst du Push Notifikationen erhalten?</p>
      <ion-button (click)="enableNotifications()" [disabled]="pushNotificationsPending">
        @if (pushNotificationsPending) {
          <ion-spinner></ion-spinner>
        } @else {
          Enable Push Notifications
        }
      </ion-button>
    </div>
    <ion-button (click)="dismiss.emit()" expand="block" fill="outline" class="ion-padding">Weiter zum Hub</ion-button>
  </slide>
</slides>
