import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Client,
  ClientInvoice,
  ClientInvoiceEntity,
  Config,
  getCurrentVatRate,
  MemberUser,
  Project,
  ProjectDetails,
} from '@coalist/common';
import { NavController } from '@ionic/angular';
import { firstValueFrom, map, Observable, switchMap, tap } from 'rxjs';
import { createQrReference } from '../../components/invoice/edit-invoice/edit-invoice.component';
import { ClientService } from '../../services/client.service';
import { FirebaseService } from '../../services/firebase.service';
import { InvoiceService } from '../../services/invoice.service';
import { MemberUserService } from '../../services/member-user.service';
import { ProjectService } from '../../services/project.service';
import { today } from '../../util/date';

@Component({
  selector: 'project-page',
  templateUrl: './project.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectPage {
  project: Observable<Project>;
  details: Observable<ProjectDetails>;
  memberUsers: Observable<MemberUser[]>;
  invoices: Observable<ClientInvoice[]>;
  client: Observable<Client>;

  constructor(
    activatedRoute: ActivatedRoute,
    projectService: ProjectService,
    navController: NavController,
    memberUserService: MemberUserService,
    clientService: ClientService,
    private invoiceService: InvoiceService,
    private firebaseService: FirebaseService,
  ) {
    const projectId = activatedRoute.params.pipe(map(({ id }) => id));
    this.project = projectId.pipe(
      switchMap((id) => projectService.findById(id)),
      tap((project) => {
        if (project === null) {
          navController.pop();
        }
      }),
    );
    this.details = projectId.pipe(switchMap((id) => projectService.findDetailsById(id)));
    this.client = this.project.pipe(switchMap((project) => clientService.findById(project.clientId)));
    this.memberUsers = memberUserService.memberUsers;
    this.invoices = projectId.pipe(switchMap((id) => invoiceService.findByProject(id)));
  }

  async creatInvoice() {
    const project = await firstValueFrom(this.project);
    const projectDetail = await firstValueFrom(this.details);
    const invoiceNumber = await this.invoiceService.nextInvoiceNumber();
    const config = await this.firebaseService.getDocument<Config>('config/config');
    const vatRate = getCurrentVatRate(config, 'Normal');
    const client = await firstValueFrom(this.client);
    if (vatRate == null) {
      console.error('failed to get current vat rate from config');
      return;
    }

    const coalistAddress = config.coalist.address;
    let invoiceText = projectDetail.invoiceText ?? config.invoice.invoiceText;
    if (projectDetail.additionalInvoiceText) {
      invoiceText += `\n\n\n${projectDetail.additionalInvoiceText}`;
    }

    const invoice: ClientInvoiceEntity = {
      projectId: project.id,
      invoiceDate: today(),
      invoiceNumber: invoiceNumber.toString(),
      qrReference: createQrReference(),
      invoiceText: invoiceText,
      paymentTermDays: config.invoice.paymentTermDays,
      vatRate,
      client: {
        name: project.clientName,
        address: client.address?.street ?? '',
        city: client.address?.city ?? '',
        zip: client.address?.postalCode ?? '',
        country: client.address?.countryCode ?? '',
      },
      mainLineItems: projectDetail.members.map((user) => {
        return {
          quantity: 0,
          unit: projectDetail.unit,
          unitPrice: user.unitPrice,
          description:
            projectDetail.members.length === 1
              ? user.description
              : `${user.description}\n${user.firstName} ${user.lastName}`,
        };
      }),
      details: [],
      vatNumber: `${config.coalist.uid} MWST`,
      footer: config.invoice.footer,
      supplierAddress: `${config.coalist.name} | ${coalistAddress?.street}, ${coalistAddress?.postalCode} ${coalistAddress?.city}`,
    };
    this.invoiceService.create(invoice);
  }
}
