import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Admission, Event, MemberUser } from '@coalist/common';
import { LoadingState } from '../../../util/loading';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeContentComponent implements OnChanges {
  @Input({ required: true }) user!: MemberUser;
  @Input({ required: true }) eventsLoading: LoadingState = 'initial';
  @Input({ required: true }) upcomingEvents: Event[] = [];
  @Input({ required: true }) admissionsLoading: LoadingState = 'initial';
  @Input({ required: true }) admissions: Admission[] = [];
  @Output() editAvailability = new EventEmitter<void>();

  availabilityIcon = 'warning';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      switch (this.user.availabilityStatus) {
        case 'available':
          this.availabilityIcon = 'rainy';
          break;
        case 'unavailable':
          this.availabilityIcon = 'sunny';
          break;
        case 'partial':
          this.availabilityIcon = 'partly-sunny';
          break;
      }
    }
  }

  trackById = trackById;
}
