<ion-header>
  <ion-toolbar color="brand">
    <ion-title>{{'admin.title' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item-divider>Member Admission</ion-item-divider>
    <ion-item routerLink="member-admission-meeting">
      <ion-label>Schedule Meeting</ion-label>
    </ion-item>
    <ion-item routerLink="member-admission">
      <ion-label>Admission</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
