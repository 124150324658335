import { Injectable } from '@angular/core';
import { AvailabilityStatus, MemberUserEducation, Vote } from '@coalist/common';
import { AdmissionVotePage, VoteUpdate } from '../pages/admission-vote/admission-vote.page';
import { EditAvailabilityPage } from '../pages/edit-availability/edit-availability.page';
import { EditFormPage, FormField } from '../pages/edit-form/edit-form.page';
import { EditTextPage } from '../pages/edit-text/edit-text.page';
import { IonicService } from './ionic.service';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private ionicService: IonicService) {}

  async editAvailability(
    availability: string | null,
    availabilityStatus: AvailabilityStatus | null,
  ): Promise<
    | {
        availability: string | null;
        availabilityStatus: AvailabilityStatus | null;
      }
    | undefined
  > {
    return await this.ionicService.showModal({
      component: EditAvailabilityPage,
      componentProps: {
        availability,
        availabilityStatus,
      },
    });
  }

  async editText(title: string, text: string, multiline = false): Promise<string | undefined> {
    return await this.ionicService.showModal({
      component: EditTextPage,
      componentProps: {
        title,
        text,
        multiline,
      },
    });
  }

  async editForm<T>(title: string, fields: FormField[]): Promise<T | undefined> {
    return await this.ionicService.showModal<T>({
      component: EditFormPage,
      componentProps: {
        title,
        fields,
      },
    });
  }

  async editEducation(title: string, education?: MemberUserEducation): Promise<MemberUserEducation | undefined> {
    return await this.ionicService.showModal({
      component: EditTextPage,
      componentProps: {
        title,
        text: '',
      },
    });
  }

  async editAdmissionVote(vote: Vote) {
    return await this.ionicService.showModal<VoteUpdate>({
      component: AdmissionVotePage,
      componentProps: {
        vote,
      },
    });
  }
}
