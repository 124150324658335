import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IsoDate } from '@coalist/common';

@Component({
  selector: 'date-badge',
  templateUrl: './date-badge.component.html',
  styleUrls: ['./date-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateBadgeComponent {
  @Input() date!: IsoDate;
}
