import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MemberUser } from '@coalist/common';
import { ComponentChanges } from '../../util/component-changes';
import { SlidesComponent } from '../slides/slides.component';

@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeComponent implements OnChanges {
  @ViewChild(SlidesComponent) slides?: SlidesComponent;

  @Input() user!: MemberUser;
  @Output() dismiss = new EventEmitter<void>();
  @Output() requestNotificationPermission = new EventEmitter<void>();

  pushNotificationsPending = false;

  ngOnChanges(changes: ComponentChanges<WelcomeComponent>) {
    if (changes.user) {
      if (this.user.notificationEnabled) {
        this.dismiss.emit();
      }
    }
  }

  next() {
    this.slides?.slideNext();
  }

  enableNotifications() {
    this.pushNotificationsPending = true;
    this.requestNotificationPermission.emit();
  }
}
