import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ClientInvoice, MemberUser, Project, ProjectDetails, ProjectMember } from '@coalist/common';
import { flyInOut } from '../../../util/animations';
import { today } from '../../../util/date';
import { trackById } from '../../../util/trackBy';
import { MemberUserItem } from '../../member-user/member-user-item/member-user-item.component';

@Component({
  selector: 'project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class ProjectComponent implements OnChanges {
  @Input({ required: true }) project!: Project;
  @Input({ required: true }) details!: ProjectDetails;
  @Input({ required: true }) memberUsers!: MemberUser[];
  @Input({ required: true }) invoices!: ClientInvoice[];

  activeMembers: MemberUserItem[] = [];
  showInactive = false;
  inactiveMembers: MemberUserItem[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project || changes.memberUsers) {
      this.activeMembers = this.details.members
        .filter((member) => member.endDate === undefined || member.endDate >= today())
        .sort(sortProjectMember)
        .map((member: ProjectMember) =>
          mapProjectMember(
            member,
            this.memberUsers.find((user) => user.id === member.id),
          ),
        );
      this.inactiveMembers = this.details.members
        .filter((member) => member.endDate !== undefined && member.endDate < today())
        .sort(sortProjectMember)
        .map((member: ProjectMember) =>
          mapProjectMember(
            member,
            this.memberUsers.find((user) => user.id === member.id),
          ),
        );
    }
  }

  toggleInactive() {
    this.showInactive = !this.showInactive;
  }

  trackById = trackById;
}

function sortProjectMember(a: ProjectMember, b: ProjectMember) {
  if (a.endDate === b.endDate) {
    return a.startDate < b.startDate ? -1 : 0;
  }
  return a.endDate! > b.endDate! ? -1 : 0;
}

function mapProjectMember(member: ProjectMember, memberUser?: MemberUser): MemberUserItem {
  return {
    id: memberUser?.id ?? '',
    firstName: memberUser?.firstName ?? '',
    lastName: memberUser?.lastName ?? '',
    pictureUrl: memberUser?.pictureUrl,
    detail: member.endDate ? `${member.startDate} - ${member.endDate}` : `seit ${member.startDate}`,
  };
}
