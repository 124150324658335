<ion-header>
  <ion-toolbar color="brand">
    <ion-title>
      <ion-segment [value]="activeSlide | async" (ionChange)="showSlide($any($event).detail.value)">
        <ion-segment-button *ngFor="let slide of slideIds | async" [value]="slide">
          {{ ('members.pages.' + slide) | translate }}
        </ion-segment-button>
      </ion-segment>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="add()" color="secondary">
        <ion-icon name="add" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <!--
  <ion-toolbar color="secondary">
    <ion-searchbar
      (ionInput)="search($any($event).target?.value)"
      [placeholder]="'general.search' | translate"
    ></ion-searchbar>
  </ion-toolbar>
  -->
</ion-header>

<ion-content>
  <slides #slides (slideChanged)="slideChanged($event)">
    <slide>
      <ion-content>
        <loading *ngIf="users.loading | async as loading" [loading]="loading">
          <member-user-list *ngIf="users.data | async as users" [users]="users"></member-user-list>
        </loading>
      </ion-content>
    </slide>
    <slide>
      <ion-content>
        <loading *ngIf="companies.loading | async as loading" [loading]="loading">
          <member-company-list
            *ngIf="companies.data | async as companies"
            [companies]="companies"
            [users]="users.data | async"
          ></member-company-list>
        </loading>
      </ion-content>
    </slide>
  </slides>
</ion-content>
