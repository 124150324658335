import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'welcome-page',
  templateUrl: './welcome.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomePage {
  user = this.userService.user;

  constructor(
    private modalController: ModalController,
    private userService: UserService,
  ) {}

  async requestNotificationPermission() {
    await this.userService.enablePushNotifications();
  }

  dismiss() {
    this.userService.welcomeShown();
    this.modalController.dismiss();
  }
}
