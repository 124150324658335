import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Event, Id, MemberUser } from '@coalist/common';
import { ModalController, NavController, ViewWillEnter } from '@ionic/angular';
import { combineLatest, distinctUntilChanged, filter, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';

import { EventService } from '../../services/event.service';
import { MemberUserService } from '../../services/member-user.service';
import { UserService } from '../../services/user.service';
import { brandColor, setThemeColor } from '../../util/theme';
import { EditEventPage } from '../edit-event/edit-event.page';

@Component({
  selector: 'event-page',
  templateUrl: './event.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPage implements ViewWillEnter {
  user = this.userService.user;
  event: Observable<Event>;
  participants: Observable<MemberUser[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private memberUserService: MemberUserService,
    private modalController: ModalController,
    private navController: NavController,
    private userService: UserService
  ) {
    const eventId = activatedRoute.params.pipe(
      map(({ id }) => id),
      distinctUntilChanged()
    );
    this.event = eventId.pipe(
      switchMap((eventId) => (eventId ? this.eventService.findById(eventId) : of(null))),
      shareReplay(1),
      tap((event) => {
        if (!event) {
          this.navController.navigateBack('/events');
        }
      }),
      filter((event): event is Event => !!event)
    );
    this.participants = this.event.pipe(
      switchMap((event) => {
        return event.participantIds.length === 0
          ? of([])
          : combineLatest(event.participantIds.map((participantId) => this.memberUserService.findById(participantId)));
      }),
      map((participants) =>
        participants.sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
      )
    );
  }

  ionViewWillEnter() {
    setThemeColor(brandColor);
  }

  async attend(eventId: Id, userId: Id, attend: boolean) {
    await this.eventService.updateAttend(eventId, userId, attend);
  }

  async edit(event: Event) {
    const modal = await this.modalController.create({
      component: EditEventPage,
      componentProps: {
        event,
      },
    });
    await modal.present();
  }
}
