<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'businessProfile.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <edit-business-profile
    *ngIf="user | async as user"
    [profile]="profile | async"
    (saveMiniBio)="saveMiniBiography($event)"
    (addEducation)="saveEducation($event)"
    (saveEducation)="saveEducation($event.education, $event.index)"
    (addLanguage)="saveLanguage($event)"
    (saveLanguage)="saveLanguage($event.language, $event.index)"
  ></edit-business-profile>
</ion-content>
