import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvoiceService } from '../../services/invoice.service';
import { map, Observable, switchMap, tap } from 'rxjs';
import { ClientInvoice } from '@coalist/common';
import { ModalController, NavController } from '@ionic/angular';
import { EditInvoicePage } from '../edit-invoice/edit-invoice.page';

@Component({
  selector: 'invoice-page',
  templateUrl: './invoice.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicePage {
  invoice: Observable<ClientInvoice>;

  constructor(
    activatedRoute: ActivatedRoute,
    invoiceService: InvoiceService,
    navController: NavController,
    private modalController: ModalController,
  ) {
    const invoiceId = activatedRoute.params.pipe(map(({ id }) => id));
    this.invoice = invoiceId.pipe(
      switchMap((id) => invoiceService.findById(id)),
      tap((project) => {
        if (project === null) {
          navController.pop();
        }
      }),
    );
  }

  async edit(invoice: ClientInvoice) {
    const modal = await this.modalController.create({
      component: EditInvoicePage,
      componentProps: { invoice },
    });
    await modal.present();
  }
}
