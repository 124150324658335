import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MemberUser } from '@coalist/common';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { SlidesComponent } from '../../components/slides/slides.component';
import { MemberUserService } from '../../services/member-user.service';
import { load, Loading } from '../../util/loading';
import { brandColor, setThemeColor } from '../../util/theme';

export type MembersSlide = 'companies' | 'people' | 'availability';

@Component({
  selector: 'coalist-page',
  templateUrl: './coalist.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoalistPage implements ViewWillEnter, ViewDidLeave {
  @ViewChild('slides') slides!: SlidesComponent;

  slideIds: MembersSlide[] = ['availability'];
  activeSlide = new BehaviorSubject<MembersSlide>('availability');
  users: Loading<MemberUser[]>;

  constructor(private memberUserService: MemberUserService) {
    this.users = load(this.memberUserService.memberUsers);
  }

  ionViewWillEnter() {
    setThemeColor(brandColor);
    this.users.enable();
  }

  ionViewDidLeave() {
    this.users.disable();
  }

  selectSlide(slide: MembersSlide) {
    this.slides.slideTo(this.slideIds.indexOf(slide));
  }

  slideSelected(activeIndex: number) {
    const page = this.slideIds[activeIndex];
    if (this.activeSlide.value !== page) {
      this.activeSlide.next(page);
    }
  }
}
