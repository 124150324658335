<ion-header>
  <ion-toolbar color="brand">
    <ion-title>{{event?.title ?? 'editEvent.title' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <edit-event [event]="event" (save)="save($event)" (delete)="delete($event)"></edit-event>
</ion-content>
