<ion-list>
  <ion-item-group>
    <ion-item-divider>{{ 'profile.personal' | translate }}</ion-item-divider>
    <ion-item (click)="editName()">
      <ion-label>{{ user.firstName }} {{ user.lastName }}</ion-label>
    </ion-item>
    <ion-item (click)="editTitle()">
      <ion-label>{{ user.title }}</ion-label>
    </ion-item>
    <ion-item (click)="editDateOfBirth()">
      <ion-icon slot="start" name="calendar-number"></ion-icon>
      <ion-label *ngIf="userProtected?.dateOfBirth">
        {{ userProtected?.dateOfBirth | formatDate: 'dd.MM.yyyy' }}
      </ion-label>
      <ion-label class="placeholder" *ngIf="!userProtected?.dateOfBirth">
        {{ 'profile.dateOfBirth' | translate }}
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'profile.contact' | translate }}</ion-item-divider>
    <ion-item>
      <ion-icon slot="start" name="mail"></ion-icon>
      <ion-label>{{ user.email }}</ion-label>
    </ion-item>
    <ion-item *ngIf="user.phone" (click)="editPhone()">
      <ion-icon slot="start" name="call"></ion-icon>
      <ion-label *ngIf="user.phone">{{ user.phone }}</ion-label>
      <ion-label class="placeholder" *ngIf="!user.phone">
        {{ 'profile.noPhone' | translate }}
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'profile.socialProfiles' | translate }}</ion-item-divider>
    <ion-item-sliding #slidingItem *ngFor="let socialPlatform of socialPlatforms">
      <ion-item (click)="editSocialProfile(socialPlatform)">
        <ion-icon slot="start" [name]="'logo-' + socialPlatform"></ion-icon>
        <ion-label>{{ user.socialProfiles ? user.socialProfiles[socialPlatform] : '' }}</ion-label>
      </ion-item>
      <ion-item-options>
        <ion-item-option color="danger" (click)="deleteSocialProfile(slidingItem, socialPlatform)">
          <ion-icon slot="icon-only" name="trash"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-item-group>

  <ion-item-group *ngIf="user.address">
    <ion-item-divider>{{ 'profile.address' | translate }}</ion-item-divider>
    <address-item [address]="user.address" [showMap]="false"></address-item>
  </ion-item-group>
</ion-list>
