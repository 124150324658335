<ion-list class="shadow-md" lines="none" inset="">
  <ion-item-divider>
    {{ 'home.availability' | translate }}
    <ion-icon slot="end" color="dark" [name]="availabilityIcon"></ion-icon>
  </ion-item-divider>
  <ion-item (click)="editAvailability.emit()">
    <ion-label class="ion-text-wrap" [class.placeholder]="!user.availability">
      {{ user.availability || ('home.noAvailability' | translate) }}
    </ion-label>
  </ion-item>
</ion-list>

<ion-list class="shadow-md" inset="">
  <ion-item-divider>{{ 'home.upcomingEvents' | translate }}</ion-item-divider>
  <ion-label *ngIf="eventsLoading === 'loading'" class="loading">
    <ion-spinner></ion-spinner>
  </ion-label>
  <ng-container *ngIf="eventsLoading === 'success' && upcomingEvents">
    <ion-item *ngIf="upcomingEvents.length === 0" routerLink="/events">
      <ion-label>
        <h2>{{ 'home.noUpcomingEvents' | translate }}</h2>
        <p>{{ 'home.showAllEvents' | translate }}</p>
      </ion-label>
    </ion-item>
    <ion-item *ngFor="let event of upcomingEvents; trackBy: trackById" routerLink="/home/events/{{ event.id }}">
      <date-badge [date]="event.date" slot="start"></date-badge>
      <ion-label>
        <h2>{{ event.title }}</h2>
        <p>{{ event.online ? ('event.online' | translate) : event.location }}</p>
      </ion-label>
      <ion-icon
        slot="end"
        name="checkmark-circle"
        color="medium"
        *ngIf="event.participantIds.includes(user.id)"
      ></ion-icon>
    </ion-item>
  </ng-container>
</ion-list>

<ion-list class="shadow-md" inset="" *ngIf="admissions.length > 0">
  <ion-item-divider>{{ 'home.admissions' | translate }}</ion-item-divider>
  <ion-label *ngIf="admissionsLoading === 'loading'" class="loading">
    <ion-spinner></ion-spinner>
  </ion-label>
  <ng-container *ngIf="admissionsLoading === 'success' && admissions">
    <admission-item *ngFor="let admission of admissions" [admission]="admission"> </admission-item>
    <ion-item routerLink="admissions">
      <ion-label>All</ion-label>
    </ion-item>
  </ng-container>
</ion-list>
