import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MemberUserEducation, MemberUserProfile } from '@coalist/common';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'edit-business-profile',
  templateUrl: './edit-business-profile.component.html',
  styleUrls: ['./edit-business-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBusinessProfileComponent {
  @Input() profile: MemberUserProfile | null = null;
  @Output() saveMiniBio = new EventEmitter<string>();
  @Output() addEducation = new EventEmitter<MemberUserEducation>();
  @Output() saveEducation = new EventEmitter<{ index: number; education: MemberUserEducation }>();
  @Output() addLanguage = new EventEmitter<string>();
  @Output() saveLanguage = new EventEmitter<{ index: number; language: string }>();

  constructor(private modalService: ModalService) {}

  async editMiniBiography() {
    const updatedMiniBio = await this.modalService.editText('Mini Bio', this.profile?.miniBiography ?? '', true);
    if (updatedMiniBio) {
      this.saveMiniBio.emit(updatedMiniBio);
    }
  }

  async editEducation(index?: number, education?: MemberUserEducation) {
    const updatedEducation = await this.modalService.editEducation('Education', education);
    if (updatedEducation) {
      if (education && index !== undefined) {
        this.saveEducation.emit({
          index,
          education: updatedEducation
        });
      } else {
        this.addEducation.emit(updatedEducation);
      }
    }
  }

  async editLanguage(index?: number, language?: string) {
    const updatedLanguage = await this.modalService.editText('Language', language ?? '');
    if (updatedLanguage) {
      if (language && index !== undefined) {
        this.saveLanguage.emit({
          index,
          language: updatedLanguage
        });
      } else {
        this.addLanguage.emit(updatedLanguage);
      }
    }
  }

  deleteEducation(index: number) {}

  deleteLanguage(index: number) {}
}
