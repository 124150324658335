import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { IonTabs, ViewDidEnter } from '@ionic/angular';

@Component({
  selector: 'tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsPage implements ViewDidEnter {
  @ViewChild(IonTabs) tabs: IonTabs | null = null;

  ionViewDidEnter() {
    if (!this.tabs?.getSelected()) {
      this.tabs?.select('home');
    }
  }
}
