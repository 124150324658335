<ion-list>
  <ion-item-group>
    <ion-item-divider>{{ 'admission.label.state' | translate }}</ion-item-divider>
    <ion-item>
      <ion-label class="ion-text-wrap">
        {{
          'admission.state.' + admission.state
            | translate
              : {
                  companyName: admission.candidateCompany.name,
                  meetingName: admission.introductionEventTitle,
                  meetingDate: introductionEventDate,
                  voteUntil: voteUntil,
                  sponsorDisplayName: admission.sponsorDisplayName
                }
        }}
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'admission.label.motivation' | translate }}</ion-item-divider>
    <ion-item>
      <ion-label class="ion-text-wrap">
        {{ admission.description }}
        <p>{{ admission.sponsorDisplayName }}</p>
      </ion-label>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'admission.label.company' | translate }}</ion-item-divider>
    <ion-item>
      <ion-label>{{ admission.candidateCompany.name }}</ion-label>
      <ion-button
        fill="none"
        slot="end"
        *ngIf="admission.candidateCompany.website"
        [href]="admission.candidateCompany.website"
        target="_blank"
        class="link-button">
        <ion-icon slot="icon-only" name="globe"></ion-icon>
      </ion-button>
      <ion-button
        fill="none"
        slot="end"
        *ngFor="let link of companyLinks"
        [href]="link.href"
        target="_blank"
        class="link-button">
        <ion-icon slot="icon-only" [name]="link.icon"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item-divider>{{ 'admission.label.member' | translate }}</ion-item-divider>
    <ion-item *ngFor="let user of candidatePersons; trackBy: trackById; let index = index">
      <ion-label>{{ user.firstName }} {{ user.lastName }}</ion-label>
      <ion-button
        fill="none"
        slot="end"
        *ngFor="let link of personLinks[index]"
        [href]="link.href"
        target="_blank"
        class="link-button">
        <ion-icon slot="icon-only" [name]="link.icon"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-item-group>

  <ion-item-group>
    <ion-item *ngIf="admission.state === 'voting' && !showVotes">
      <ion-label>
        <ion-button expand="block" color="primary" size="default" (click)="vote.emit()">Vote</ion-button>
      </ion-label>
    </ion-item>
  </ion-item-group>
</ion-list>
