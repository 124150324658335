import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Admission, AvailabilityStatus, Event } from '@coalist/common';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { addDays, formatISO } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { AdmissionService } from '../../services/admission.service';
import { EventService } from '../../services/event.service';
import { MemberUserService } from '../../services/member-user.service';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { load, Loading } from '../../util/loading';
import { setThemeColor, white } from '../../util/theme';

@Component({
  selector: 'home-page',
  templateUrl: './home.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePage implements ViewWillEnter, ViewDidLeave {
  user = this.userService.user;
  upcomingEvents: Loading<Event[]>;
  admissions: Loading<Admission[]>;

  constructor(
    private eventService: EventService,
    private memberUserService: MemberUserService,
    private modalService: ModalService,
    private userService: UserService,
    private admissionService: AdmissionService,
  ) {
    const today = formatISO(new Date(), { representation: 'date' });
    const in30Days = formatISO(addDays(new Date(), 30), {
      representation: 'date',
    });
    this.upcomingEvents = load(this.eventService.findBetween(today, in30Days));
    this.admissions = load(this.admissionService.findByState(['voting', 'meetingScheduled', 'consensus']));
  }

  ionViewWillEnter() {
    setThemeColor(white);
    this.upcomingEvents.enable();
    this.admissions.enable();
  }

  ionViewDidLeave() {
    this.upcomingEvents.disable();
    this.admissions.disable();
  }

  async editAvailability() {
    const user = await firstValueFrom(this.user);
    if (user) {
      const data: { availability: string | null; availabilityStatus: AvailabilityStatus | null } | undefined =
        await this.modalService.editAvailability(user.availability, user.availabilityStatus);
      if (data) {
        await this.memberUserService.updateMemberUser(user.id, {
          availability: data.availability ?? '',
          availabilityStatus: data.availabilityStatus ?? 'available',
        });
      }
    }
  }
}
