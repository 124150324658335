<ion-item [routerLink]="routerLink">
  <ion-avatar slot="start">
    <img [storageImg]="user.pictureUrl" fallback="/assets/img/avatar.svg" />
  </ion-avatar>
  <ion-label *ngIf="type === 'self'">{{ 'general.you' | translate }}</ion-label>
  <ion-label *ngIf="type === 'default'">
    <h2>{{ user.firstName }} {{ user.lastName }}</h2>
    <p *ngIf="detail">{{ detail }}</p>
  </ion-label>
  <ng-content></ng-content>
</ion-item>
