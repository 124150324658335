import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Id, MemberUserProfileEntity, SkillLevel } from '@coalist/common';
import { ModalController } from '@ionic/angular';
import { combineLatest, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { createSkillsForm, MemberUserSkillsForm } from '../../../components/user/edit-skills/edit-skills.form';
import { MemberUserService } from '../../../services/member-user.service';
import { SkillsService } from '../../../services/skills.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'edit-skills-page',
  templateUrl: './edit-skills.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSkillsPage {
  skills: Observable<MemberUserSkillsForm[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private memberUserService: MemberUserService,
    private modalController: ModalController,
    private skillsService: SkillsService,
    private userService: UserService
  ) {
    const userId = activatedRoute.params.pipe(map(({ userId }) => userId));
    const userProfile = userId.pipe(
      switchMap((userId) => (userId ? memberUserService.findProfileById(userId) : userService.profile))
    );
    this.skills = combineLatest([skillsService.skills, userProfile]).pipe(
      map(([skills, profile]) => createSkillsForm(skills, profile?.skills ?? {}))
    );
  }

  async updateLevel(skillId: Id, level: SkillLevel) {
    const user = await firstValueFrom(this.userService.user);
    const profileUpdate: Partial<MemberUserProfileEntity> = {
      skills: {
        [skillId]: level
      }
    };
    await this.memberUserService.updateProfile(user.id, profileUpdate);
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
