<ion-header>
  <ion-toolbar color="brand">
    <ion-title>{{ 'availability.title' | translate}}</ion-title>
  </ion-toolbar>
  <!--
  <ion-toolbar color="secondary">
    <ion-searchbar
      (ionInput)="search($any($event).target?.value)"
      [placeholder]="'general.search' | translate"
    ></ion-searchbar>
  </ion-toolbar>
  -->
</ion-header>

<ion-content>
  <slides #slides (slideChanged)="slideSelected($event)">
    <slide>
      <ion-content>
        <loading *ngIf="users.loading | async as loading" [loading]="loading">
          <member-user-availability *ngIf="users.data | async as users" [users]="users"></member-user-availability>
        </loading>
      </ion-content>
    </slide>
  </slides>
</ion-content>
