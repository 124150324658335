import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MemberUser } from '@coalist/common';
import { Platform } from '@ionic/angular';
import { PickerService } from '../../../services/picker.service';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
  @Input() user!: MemberUser;
  @Input() profilePictureSaving = false;
  @Output() profilePictureChanged = new EventEmitter<string>();
  @Output() enablePushNotifications = new EventEmitter<void>();
  @Output() disablePushNotifications = new EventEmitter<void>();
  @Output() logout = new EventEmitter<void>();

  notificationsAvailable = false;
  pushNotificationsPending = false;

  constructor(
    private picker: PickerService,
    platform: Platform,
  ) {
    this.notificationsAvailable = platform.is('pwa');
  }

  async editProfilePicture() {
    const dataUrl = await this.picker.picture();
    if (dataUrl) {
      this.profilePictureChanged.emit(dataUrl);
    }
  }

  onEnablePushNotifications() {
    this.pushNotificationsPending = true;
    this.enablePushNotifications.emit();
  }
}
