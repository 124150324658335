import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Id, SkillLevel } from '@coalist/common';

export interface MemberUserSkills {
  category: string;
  skills: MemberUserSkill[];
}

export interface MemberUserSkill {
  id: Id;
  name: string;
  level: SkillLevel;
}

@Component({
  selector: 'member-user-skills',
  templateUrl: './member-user-skills.component.html',
  styleUrls: ['./member-user-skills.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberUserSkillsComponent {
  @Input() skills!: MemberUserSkills[];

  trackSkills(index: number, skills: MemberUserSkills) {
    return skills.category;
  }

  trackSkill(index: number, skills: MemberUserSkill) {
    return skills.name;
  }
}
