import { Injectable } from '@angular/core';
import { Event, EventEntity, Id } from '@coalist/common';
import { arrayRemove, arrayUnion, limit, orderBy, PartialWithFieldValue, where } from '@firebase/firestore';
import { FirebaseService } from './firebase.service';

@Injectable({ providedIn: 'root' })
export class EventService {
  constructor(private firebase: FirebaseService) {}

  findAfter(date: string, maxItems = 20) {
    return this.firebase.query<Event>('events', where('date', '>=', date), limit(maxItems), orderBy('date', 'asc'));
  }

  findBetween(from: string, to: string, maxItems = 20) {
    return this.firebase.query<Event>(
      'events',
      where('date', '>=', from),
      where('date', '<=', to),
      limit(maxItems),
      orderBy('date', 'asc'),
    );
  }

  findById(id: Id) {
    return this.firebase.findOne<Event>(`events/${id}`);
  }

  updateAttend(id: Id, userId: Id, attend: boolean) {
    return this.firebase.updateDoc(`events/${id}`, {
      participantIds: attend ? arrayUnion(userId) : arrayRemove(userId),
    });
  }

  create(event: EventEntity) {
    return this.firebase.addDoc<EventEntity>('events', event);
  }

  save(eventId: Id, event: PartialWithFieldValue<EventEntity>) {
    return this.firebase.updateDoc(`events/${eventId}`, event);
  }

  delete(eventId: Id) {
    return this.firebase.deleteDoc(`events/${eventId}`);
  }
}
