import { FirebaseOptions } from '@firebase/app';

export const firebaseOptionsProd: FirebaseOptions = {
  apiKey: 'AIzaSyCf0cUuaFaEepDdhilPTsWC6DcaRmZZUQI',
  authDomain: 'coalist-hub.firebaseapp.com',
  projectId: 'coalist-hub',
  storageBucket: 'coalist-hub.appspot.com',
  messagingSenderId: '177519525013',
  appId: '1:177519525013:web:eae475fdf31849be232f9c',
  measurementId: 'G-C77SJ7S883',
};
