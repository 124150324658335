import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemberUser, MemberUserDetails } from '@coalist/common';
import { UpdateData } from '@firebase/firestore';
import { firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { MemberUserService } from '../../../services/member-user.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'edit-member-profile-page',
  templateUrl: './edit-member-profile.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMemberProfilePage {
  user: Observable<MemberUser>;
  userProtected: Observable<MemberUserDetails>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private memberUserService: MemberUserService,
    private userService: UserService,
  ) {
    const userId = activatedRoute.params.pipe(map(({ userId }) => userId));
    this.user = userId.pipe(switchMap((userId) => (userId ? memberUserService.findById(userId) : userService.user)));
    this.userProtected = this.user.pipe(switchMap((user) => this.memberUserService.findProtectedById(user.id)));
  }

  async updateUser(update: UpdateData<MemberUser>) {
    const user = await firstValueFrom(this.user);
    await this.memberUserService.updateMemberUser(user.id, update);
  }

  async updateUserProtected(update: UpdateData<MemberUserDetails>) {
    const user = await firstValueFrom(this.user);
    await this.memberUserService.updateProtectedData(user.id, update);
  }
}
