<ion-header>
  <ion-toolbar color="white">
    <ion-buttons slot="start">
      <ion-button color="brand" (click)="cancel()">{{ 'general.cancel' | translate}}</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="brand" (click)="ok()">{{ 'general.ok' | translate}}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-datetime presentation="date" [(ngModel)]="dateValue" locale="de-CH"></ion-datetime>
</ion-content>
