<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">{{ 'general.cancel' | translate}}</ion-button>
    </ion-buttons>
    <ion-title>{{ "admissionVote.title" | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="ok()">{{ 'general.ok' | translate}}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <admission-vote [form]="form"></admission-vote>
</ion-content>
