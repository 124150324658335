<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button color="secondary" defaultHref="home"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'home.admissions' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <loading *ngIf="admissions.loading | async as loading" [loading]="loading">
      <ng-container *ngIf="admissions.data | async as admissions">
        <admission-item *ngFor="let admission of admissions" [admission]="admission"> </admission-item>
      </ng-container>
    </loading>
  </ion-list>
</ion-content>
