import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvailabilityStatus, availabilityStatuses } from '@coalist/common';
import { ModalController } from '@ionic/angular';
import { ThemedPage } from '../../util/theme';

@Component({
  selector: 'edit-availability-page',
  templateUrl: './edit-availability.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAvailabilityPage extends ThemedPage {
  availabilityStatuses = availabilityStatuses;

  @Input() availability = '';
  @Input() availabilityStatus: AvailabilityStatus | null = null;

  constructor(private modalController: ModalController) {
    super();
  }

  save(availability: string | null | undefined, availabilityStatus: AvailabilityStatus | null | undefined) {
    this.modalController.dismiss({ availability, availabilityStatus });
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
