import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(private appService: AppService) {
    this.appService.start();
  }
}
