import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MemberCompany, MemberUser } from '@coalist/common';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'member-company',
  templateUrl: './member-company.component.html',
  styleUrls: ['./member-company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberCompanyComponent {
  @Input() company!: MemberCompany;
  @Input() users!: MemberUser[];

  trackById = trackById;
}
