import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Admission, AdmissionPerson, createSocialPlatformLinks, Link, MemberUser, Vote, WithId } from '@coalist/common';
import { extractArrayWithId } from '../../../util/helpers';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdmissionComponent implements OnChanges {
  @Input() user!: MemberUser;
  @Input() admission!: Admission;
  @Output() vote = new EventEmitter();

  votes: (Vote & WithId)[] = [];
  companyLinks: Link[] = [];
  candidatePersons: AdmissionPerson[] = [];
  personLinks: Link[][] = [];

  voteUntil = '';
  introductionEventDate = '';

  showVoting = false;
  showVotes = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.admission) {
      this.votes = extractArrayWithId(this.admission.votes).sort((a, b) =>
        a.memberUserDisplayName.localeCompare(b.memberUserDisplayName),
      );
      this.candidatePersons = extractArrayWithId(this.admission.candidatePersons);
      this.showVoting = ['voting', 'accepted', 'declined', 'consensus'].includes(this.admission.state);
      this.showVotes = this.votes.some((vote) => vote.id === this.user.id);
      this.voteUntil = this.admission.voteUntil ? formatDate(this.admission.voteUntil, 'dd.MM.yyyy', 'de_CH') : '';
      this.introductionEventDate = this.admission.introductionEventDate
        ? formatDate(this.admission.introductionEventDate, 'dd.MM.yyyy', 'de_CH')
        : '';
      this.companyLinks = createSocialPlatformLinks(this.admission.candidateCompany.socialProfiles, true);
      this.personLinks = this.candidatePersons.map((person) => createSocialPlatformLinks(person.socialProfiles));
    }
  }

  trackById = trackById;
}
