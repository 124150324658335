import { Injectable } from '@angular/core';
import { Skills } from '@coalist/common';
import { Observable } from 'rxjs';
import { FirebaseService } from './firebase.service';

@Injectable({ providedIn: 'root' })
export class SkillsService {
  skills: Observable<Skills>;

  constructor(private firebase: FirebaseService) {
    this.skills = this.firebase.findOne<Skills>('config/skills');
  }
}
