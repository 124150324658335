import { BehaviorSubject } from 'rxjs';
import { SlidesComponent } from '../../../components/slides/slides.component';

export abstract class SlidesPage<T> {
  abstract slides?: SlidesComponent;

  slideIds: BehaviorSubject<T[]>;
  activeSlide: BehaviorSubject<T>;

  protected constructor(slides: T[]) {
    this.slideIds = new BehaviorSubject<T[]>(slides);
    this.activeSlide = new BehaviorSubject<T>(slides[0]);
  }

  showSlide(slide: T) {
    this.slides?.slideTo(this.slideIds.value.indexOf(slide));
  }

  slideChanged(slideIndex: number) {
    if (slideIndex !== undefined) {
      const page = this.slideIds.value[slideIndex];
      if (this.activeSlide.value !== page) {
        this.activeSlide.next(page);
      }
    }
  }
}
