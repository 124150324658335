import { Address, InvoiceClient, IsoDate, WithId } from '@coalist/common';

export function convertAddressToLine(address: Address) {
  return `${address.street},${address.postalCode} ${address.city},${address.country}`;
}

export function convertClientToLine(client: InvoiceClient) {
  return `${client.address},${client.zip} ${client.city},${client.country},`;
}

export function extractArrayWithId<T>(record: Record<string, T> | undefined): (T & WithId)[] {
  return record ? Object.entries(record).map(([key, value]) => ({ ...value, id: key })) : [];
}

export function groupBy<T, K extends keyof T>(list: T[], indexField: K): Record<K, T[]> {
  return list.reduce(
    (map, entry) => {
      const key = entry[indexField] as K;
      if (!map[key]) {
        map[key] = [entry];
      } else {
        map[key].push(entry);
      }
      return map;
    },
    {} as Record<K, T[]>,
  );
}

export const compareDate: (a?: IsoDate, b?: IsoDate) => number = (a?: IsoDate, b?: IsoDate) => {
  if (a && b) {
    return a < b ? 1 : a === b ? 0 : -1;
  }
  if (a) {
    return 1;
  }
  if (b) {
    return -1;
  }
  return 0;
};
