import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MemberUser } from '@coalist/common';
import { flyInOut } from '../../../util/animations';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'member-user-list',
  templateUrl: './member-user-list.component.html',
  styleUrls: ['./member-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class MemberUserListComponent {
  @Input() users!: MemberUser[];

  trackById = trackById;
}
