<ion-list *ngIf="loading === 'loading'">
  <ion-item>
    <ion-label class="loading">
      <ion-spinner></ion-spinner>
      <p class="loading-message" *ngIf="message">{{ message | translate }}</p>
    </ion-label>
  </ion-item>
</ion-list>

<ion-list *ngIf="loading === 'error'">
  <ion-item class="ion-text-wrap">
    <ion-label>
      <h1>{{ 'loading.error.title' | translate }}</h1>
      <p>{{ 'loading.error.description' | translate }}</p>
    </ion-label>
  </ion-item>
</ion-list>

<ng-container *ngIf="loading === 'success' || loading === 'offline'">
  <div class="offline" *ngIf="loading === 'offline'">{{ 'loading.offline' | translate }}</div>
  <ng-content></ng-content>
</ng-container>
