import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Admission, MemberUser, Vote, WithId } from '@coalist/common';
import { extractArrayWithId } from '../../../util/helpers';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'admission-voting',
  templateUrl: './admission-voting.component.html',
  styleUrls: ['./admission-voting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdmissionVotingComponent implements OnChanges {
  @Input() user!: MemberUser;
  @Input() admission!: Admission;
  @Input() sponsor!: MemberUser;
  @Output() vote = new EventEmitter();

  votes: (Vote & WithId)[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.admission) {
      this.votes = extractArrayWithId(this.admission.votes).sort((a, b) =>
        a.memberUserDisplayName.localeCompare(b.memberUserDisplayName)
      );
    }
  }

  trackById = trackById;
}
