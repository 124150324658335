<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'skills.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <edit-skills
    *ngIf="skills | async as skills"
    [skills]="skills"
    (changeLevel)="updateLevel($event.skillId, $event.level)"
  ></edit-skills>
</ion-content>
