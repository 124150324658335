import { Routes } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';
import { LoggedOutGuard } from './guards/logged-out.guard';
import { AdminPage } from './pages/admin/admin.page';
import { AdmissionPage } from './pages/admission/admission.page';
import { AdmissionsPage } from './pages/admissions/admissions.page';
import { AppInfoPage } from './pages/app-info/app-info-page.component';
import { ClientPage } from './pages/client/client.page';
import { CoalistPage } from './pages/coalist/coalist.page';
import { EventPage } from './pages/event/event.page';
import { EventsPage } from './pages/events/events.page';
import { HomePage } from './pages/home/home.page';
import { InvoicePage } from './pages/invoice/invoice.page';
import { LoginPage } from './pages/login/login.page';
import { MemberCompanyPage } from './pages/member-company/member-company.page';
import { MemberUserPage } from './pages/member-user/member-user.page';
import { MembersPage } from './pages/members/members.page';
import { ProjectPage } from './pages/project/project.page';
import { ProjectsPage } from './pages/projects/projects.page';
import { TabsPage } from './pages/tabs/tabs.page';
import { EditBusinessProfilePage } from './pages/user/edit-business-profile/edit-business-profile.page';
import { EditCompanyPage } from './pages/user/edit-company/edit-company.page';
import { EditMemberProfilePage } from './pages/user/edit-member-profile/edit-member-profile.page';
import { EditSkillsPage } from './pages/user/edit-skills/edit-skills.page';
import { UserProfilePage } from './pages/user/user-profile/user-profile.page';

export const routes: Routes = [
  {
    path: 'app',
    component: AppInfoPage,
  },
  {
    path: 'login',
    component: LoginPage,
    canActivate: [LoggedOutGuard],
  },
  {
    path: '',
    component: TabsPage,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        children: [
          {
            path: '',
            component: HomePage,
          },
          {
            path: 'profile',
            children: [
              {
                path: '',
                component: UserProfilePage,
              },
              {
                path: 'member',
                component: EditMemberProfilePage,
              },
              {
                path: 'skills',
                component: EditSkillsPage,
              },
              {
                path: 'company',
                component: EditCompanyPage,
              },
              {
                path: 'cv',
                component: EditBusinessProfilePage,
              },
              {
                path: ':userId',
                children: [
                  {
                    path: '',
                    component: UserProfilePage,
                  },
                  {
                    path: 'skills',
                    component: EditSkillsPage,
                  },
                  {
                    path: 'company',
                    component: EditCompanyPage,
                  },
                  {
                    path: 'cv',
                    component: EditBusinessProfilePage,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'events',
        children: [
          {
            path: '',
            component: EventsPage,
          },
          {
            path: ':id',
            component: EventPage,
          },
        ],
      },
      {
        path: 'members',
        component: MembersPage,
      },
      {
        path: 'coalist',
        component: CoalistPage,
      },
      {
        path: 'projects',
        children: [
          {
            path: '',
            component: ProjectsPage,
          },
          {
            path: ':id',
            component: ProjectPage,
          },
          {
            path: 'clients/:id',
            component: ClientPage,
          },
          {
            path: 'invoices/:id',
            component: InvoicePage,
          },
        ],
      },
      {
        path: 'admin',
        component: AdminPage,
      },
      {
        path: ':tab',
        children: [
          {
            path: 'admissions',
            component: AdmissionsPage,
          },
          {
            path: 'admissions/:id',
            component: AdmissionPage,
          },
          {
            path: 'companies/:id',
            component: MemberCompanyPage,
          },
          {
            path: 'companies/:companyId/users/:id',
            component: MemberUserPage,
          },
          {
            path: 'events/users/:id',
            pathMatch: 'full',
            redirectTo: 'users/:id',
          },
          {
            path: 'users/:id',
            component: MemberUserPage,
          },
          {
            path: 'events/:id',
            component: EventPage,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];
