import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MemberUser, MemberUserDetails, socialPlatforms, SocialPlatformType } from '@coalist/common';
import { deleteField, UpdateData } from '@firebase/firestore';
import { IonItemSliding } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FormField } from '../../../pages/edit-form/edit-form.page';
import { ModalService } from '../../../services/modal.service';
import { PickerService } from '../../../services/picker.service';

@Component({
  selector: 'edit-member-profile',
  templateUrl: './edit-member-profile.component.html',
  styleUrls: ['./edit-member-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMemberProfileComponent {
  @Input() user!: MemberUser;
  @Input() userProtected: MemberUserDetails | null = null;
  @Output() userChanged = new EventEmitter<UpdateData<MemberUser>>();
  @Output() userProtectedChanged = new EventEmitter<UpdateData<MemberUserDetails>>();

  socialPlatforms = socialPlatforms;

  constructor(
    private modalService: ModalService,
    private picker: PickerService,
    private translate: TranslateService,
  ) {}

  async editName() {
    const fields: FormField[] = [
      {
        id: 'firstName',
        type: 'text',
        label: this.translate.instant('profile.firstName'),
        value: this.user.firstName,
      },
      {
        id: 'lastName',
        type: 'text',
        label: this.translate.instant('profile.lastName'),
        value: this.user.lastName,
      },
    ];
    const formValue = await this.modalService.editForm<Pick<MemberUser, 'firstName' | 'lastName'>>(
      this.translate.instant('profile.name'),
      fields,
    );
    if (formValue !== undefined) {
      this.userChanged.emit(formValue);
    }
  }

  async editDateOfBirth() {
    const dateOfBirth = await this.picker.date(this.userProtected?.dateOfBirth ?? '');
    if (dateOfBirth !== undefined) {
      this.userProtectedChanged.emit({
        dateOfBirth,
      });
    }
  }

  async editPhone() {
    const phone = await this.modalService.editText(this.translate.instant('profile.phone'), this.user.phone ?? '');
    if (phone !== undefined) {
      this.userChanged.emit({
        phone,
      });
    }
  }

  async editTitle() {
    const title = await this.modalService.editText(this.translate.instant('profile.title'), this.user.title ?? '');
    if (title !== undefined) {
      this.userChanged.emit({ title });
    }
  }

  async editSocialProfile(type: SocialPlatformType) {
    const socialProfiles = this.user.socialProfiles ?? {};
    const username = await this.modalService.editText(
      this.translate.instant('profile.socialProfileUsername'),
      socialProfiles[type] ?? '',
    );
    if (username !== undefined) {
      this.userChanged.emit({
        [`socialProfiles.${type}`]: username,
      });
    }
  }

  deleteSocialProfile(item: IonItemSliding, type: SocialPlatformType) {
    this.userChanged.emit({
      [`socialProfiles.${type}`]: deleteField(),
    });
    item.close();
  }
}
