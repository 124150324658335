import { iosTransitionAnimation } from '@ionic/angular';
import type { TransitionOptions } from '@ionic/core/dist/types/utils/transition';
import { BehaviorSubject } from 'rxjs';

export const pageTransition = new BehaviorSubject(false);

export const iosTransitionAnimationWithHooks = (navEl: HTMLElement, opts: TransitionOptions) => {
  pageTransition.next(true);
  const transition = iosTransitionAnimation(navEl, opts);
  transition.onFinish(() => {
    pageTransition.next(false);
  });
  return transition;
};
