import { Link } from './link';

export type SocialPlatformType = 'github' | 'linkedIn' | 'twitter' | 'twitch';

export const socialPlatforms: SocialPlatformType[] = ['github', 'linkedIn', 'twitter', 'twitch'];

export function createSocialPlatformLinks(
  socialProfiles?: Partial<Record<SocialPlatformType, string>>,
  company = false
): Link[] {
  if (socialProfiles === undefined) {
    return [];
  }
  return (
    socialPlatforms
      .filter((socialPlatformType) => socialProfiles[socialPlatformType])
      .map((socialPlatformType) => {
        switch (socialPlatformType) {
          case 'github':
            return {
              icon: 'logo-github',
              href: `https://github.com/${socialProfiles[socialPlatformType]}`,
              text: socialProfiles[socialPlatformType] ?? ''
            };
          case 'linkedIn':
            return {
              icon: 'logo-linkedin',
              href: company
                ? `https://linkedin.com/company/${socialProfiles[socialPlatformType]}`
                : `https://linkedin.com/in/${socialProfiles[socialPlatformType]}`,
              text: socialProfiles[socialPlatformType] ?? ''
            };
          case 'twitter':
            return {
              icon: 'logo-twitter',
              href: `https://twitter.com/${socialProfiles[socialPlatformType]}`,
              text: socialProfiles[socialPlatformType] ?? ''
            };
          case 'twitch':
            return {
              icon: 'logo-twitch',
              href: `https://twitch.com/${socialProfiles[socialPlatformType]}`,
              text: socialProfiles[socialPlatformType] ?? ''
            };
        }
      }) ?? []
  );
}
