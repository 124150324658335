import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Address } from '@coalist/common';
import { convertAddressToLine } from '../../../util/helpers';

@Component({
  selector: 'address-item',
  templateUrl: 'address-item.component.html',
  styleUrls: ['address-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressItemComponent {
  @Input() address!: Address;
  @Input() showMap = false;

  addressLine = '';
  mapsUrl?: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.address && this.showMap) {
      const addressLine = convertAddressToLine(this.address);
      if (addressLine !== this.addressLine) {
        this.addressLine = addressLine;
        this.mapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://maps.google.com/maps?q=${addressLine}&t=&z=15&ie=UTF8&iwloc=&output=embed`
        );
      }
    } else {
      this.mapsUrl = undefined;
    }
  }
}
