import { CompanyEntity } from './company';
import { getCurrentValid, getValid, ValidFrom } from './valid-from';
import { IsoDate } from './types';

export interface Config {
  schemaVersion: number;
  coalist: CompanyEntity;
  membershipFee: MembershipFee[];
  vatRates: Record<Exclude<VatRateType, 'None'>, VatRate[]>;
  invoiceNumberSequence: number;
  invoice: {
    invoiceText: string;
    paymentTermDays: 30;
    footer: string;
  };
}

// Membership Fee

export interface MembershipFee extends ValidFrom {
  baseFee: number;
  userFee: number;
  vatRate: VatRateType;
}

export function getMembershipFee(config: Config, date: IsoDate) {
  return getValid(config.membershipFee, date);
}

export function getCurrentMembershipFee(config: Config) {
  return getCurrentValid(config.membershipFee);
}

// VAT

export type VatRateType = 'None' | 'Normal' | 'Special' | 'Reduced';

export interface VatRate extends ValidFrom {
  rate: number;
}

export function getVatRate(config: Config, type: VatRateType, date: IsoDate): number | undefined {
  if (type === 'None') {
    return 0;
  }
  return getValid(config.vatRates[type], date)?.rate;
}

export function getCurrentVatRate(config: Config, type: VatRateType): number | undefined {
  if (type === 'None') {
    return 0;
  }
  return getCurrentValid(config.vatRates[type])?.rate;
}
