import { Environment } from './environment.model';
import { firebaseOptionsProd } from './firebase.config.prod';

export const environment: Environment = {
  production: true,
  firebase: {
    options: firebaseOptionsProd,
    region: 'europe-west6',
    useEmulator: false,
    enableOffline: true,
    vapidKey: 'BEqj-FBIqzw683diIrnnrkmGjT9jKo580avGwQMMQ0LI9qS38OY6vD0PsyNrHbGKOegkynMSUiVdzr12RTm0okU',
  },
};
