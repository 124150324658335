<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button color="secondary"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="slideIds | async as slides">
        <ion-segment
          *ngIf="slides.length > 1"
          [value]="activeSlide | async"
          (ionChange)="showSlide($any($event).detail.value)"
        >
          <ion-segment-button *ngFor="let slide of slides" [value]="slide">
            {{ ('profile.pages.' + slide) | translate }}
          </ion-segment-button>
        </ion-segment>
        <ng-container *ngIf="slides.length === 1">
          <ng-container *ngIf="memberUser | async as user">{{ user.firstName }}</ng-container>
        </ng-container>
      </ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="memberUser | async as memberUser">
    <slides #slides (slideChanged)="slideChanged($event)">
      <slide>
        <ion-content>
          <member-user [memberUser]="memberUser"></member-user>
        </ion-content>
      </slide>
      <slide *ngIf="skills | async as skills">
        <ion-content>
          <member-user-skills [skills]="skills"></member-user-skills>
        </ion-content>
      </slide>
      <slide *ngIf="profile | async as profile">
        <ion-content>
          <member-user-profile [profile]="profile"></member-user-profile>
        </ion-content>
      </slide>
    </slides>
  </ng-container>
</ion-content>
