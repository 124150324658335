import { Id, SkillLevel, Skills } from '@coalist/common';

export interface MemberUserSkillsForm {
  category: string;
  skills: MemberUserSkillForm[];
}

export interface MemberUserSkillForm {
  id: Id;
  name: string;
  level: SkillLevel;
}

export function createSkillsForm(skills: Skills, userSkills: Record<Id, SkillLevel>): MemberUserSkillsForm[] {
  return skills.categoryIds.map(
    (categoryId): MemberUserSkillsForm => ({
      category: skills.categories[categoryId].name,
      skills: skills.categories[categoryId].skillIds.map((skillId) => ({
        id: skillId,
        name: skills.categories[categoryId].skills[skillId],
        level: userSkills[skillId] ?? 0
      }))
    })
  );
}
