<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <ion-list>
    <ion-item-group>
      <!-- invoice date -->
      <ion-item (click)="selectDate()">
        <ion-icon slot="start" name="calendar"></ion-icon>
        <ion-label>{{ formGroup.value.invoiceDate | formatDate }}</ion-label>
      </ion-item>

      <!-- invoice number -->
      <ion-item>
        <ion-input
          [label]="'editInvoice.invoiceNumber' | translate"
          type="text"
          formControlName="invoiceNumber"
        ></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.invoiceNumber.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        >
        </ion-icon>
      </ion-item>

      <!-- QR reference -->
      <ion-item>
        <ion-input
          [label]="'editInvoice.qrReference' | translate"
          type="text"
          formControlName="qrReference"
        ></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.qrReference.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        >
        </ion-icon>
      </ion-item>

      <!-- Invoice Text -->
      <ion-item>
        <ion-textarea
          [label]="'editInvoice.invoiceText' | translate"
          labelPlacement="stacked"
          formControlName="invoiceText"
          [autoGrow]="true"
        ></ion-textarea>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.invoiceText.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        ></ion-icon>
      </ion-item>

      <!-- Payment Term Days -->
      <ion-item>
        <ion-input
          [label]="'editInvoice.paymentTermDays' | translate"
          type="number"
          formControlName="paymentTermDays"
        ></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.paymentTermDays.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        >
        </ion-icon>
      </ion-item>

      <!-- VAT -->
      <ion-item>
        <ion-input [label]="'editInvoice.vatRate' | translate" type="number" formControlName="vatRate"></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.vatRate.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        >
        </ion-icon>
      </ion-item>
    </ion-item-group>

    <!-- Client -->
    <ion-item-group formGroupName="client">
      <ion-item-divider>{{ 'editInvoice.client' | translate }}</ion-item-divider>

      <!-- Client Name -->
      <ion-item>
        <ion-input type="text" formControlName="name" [placeholder]="'editInvoice.clientName' | translate"></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.client.controls.name.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        ></ion-icon>
      </ion-item>

      <!-- Client Street -->
      <ion-item>
        <ion-input
          type="text"
          formControlName="address"
          [placeholder]="'editInvoice.clientAddress' | translate"
        ></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.client.controls.address.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        ></ion-icon>
      </ion-item>

      <!-- Client ZIP -->
      <ion-item>
        <ion-input type="text" formControlName="zip" [placeholder]="'editInvoice.clientZip' | translate"></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.client.controls.zip.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        ></ion-icon>
      </ion-item>

      <!-- Client City -->
      <ion-item>
        <ion-input type="text" formControlName="city" [placeholder]="'editInvoice.clientCity' | translate"></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.client.controls.city.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        ></ion-icon>
      </ion-item>

      <!-- Client Country -->
      <ion-item>
        <ion-input
          type="text"
          formControlName="country"
          [placeholder]="'editInvoice.clientCountry' | translate"
        ></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.client.controls.country.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        ></ion-icon>
      </ion-item>
    </ion-item-group>

    <!-- Supplier -->
    <ion-item-group>
      <ion-item-divider>{{ 'editInvoice.supplier' | translate }}</ion-item-divider>
      <!-- Supplier Address -->
      <ion-item>
        <ion-input
          [label]="'editInvoice.supplierAddress' | translate"
          type="text"
          formControlName="supplierAddress"
        ></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.supplierAddress.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        >
        </ion-icon>
      </ion-item>

      <!-- VAT Number -->
      <ion-item>
        <ion-input [label]="'editInvoice.vatNumber' | translate" type="text" formControlName="vatNumber"></ion-input>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.vatNumber.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        >
        </ion-icon>
      </ion-item>
    </ion-item-group>

    <!-- Main Line Items -->
    <ion-item-group>
      <ion-item-divider>{{ 'editInvoice.lineItems' | translate }}</ion-item-divider>
      <ion-item>
        <ion-textarea class="json" wrap="off" formControlName="mainLineItems" rows="10"></ion-textarea>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.mainLineItems.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        ></ion-icon>
      </ion-item>
    </ion-item-group>

    <!-- Details -->
    <ion-item-group>
      <ion-item-divider>{{ 'editInvoice.details' | translate }}</ion-item-divider>
    </ion-item-group>
    <ion-item-group>
      <ion-item>
        <ion-textarea class="json" formControlName="details" [autoGrow]="true"></ion-textarea>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.details.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        ></ion-icon>
      </ion-item>
    </ion-item-group>

    <!-- Footer -->
    <ion-item-group>
      <ion-item-divider>{{ 'editInvoice.footer' | translate }}</ion-item-divider>
      <ion-item>
        <ion-textarea formControlName="footer" [autoGrow]="true"></ion-textarea>
        <ion-icon
          *ngIf="showErrors && formGroup.controls.footer.errors"
          slot="end"
          name="warning-outline"
          color="warning"
        ></ion-icon>
      </ion-item>
    </ion-item-group>

    <ion-item-group>
      <div class="ion-padding">
        <ion-button expand="block" color="danger" *ngIf="invoice">
          {{ 'general.delete' | translate }}
        </ion-button>
        <ion-button type="submit" expand="block">
          {{ (invoice ? 'general.save' : 'editInvoice.create') | translate }}
        </ion-button>
      </div>
    </ion-item-group>
  </ion-list>
</form>
