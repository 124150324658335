<ion-item routerLink="/home/admissions/{{ admission.id }}">
  <date-badge
    slot="start"
    *ngIf="admission.state === 'meetingScheduled' && admission.introductionEventDate"
    [date]="admission.introductionEventDate"
  ></date-badge>
  <date-badge
    slot="start"
    *ngIf="admission.state === 'voting' && admission.voteUntil"
    [date]="admission.voteUntil"
  ></date-badge>
  <ion-icon slot="start" *ngIf="admission.state === 'consensus'" name="alert-circle" color="warning"></ion-icon>
  <ion-icon slot="start" *ngIf="admission.state === 'accepted'" name="checkmark-circle" color="medium"></ion-icon>
  <ion-icon slot="start" *ngIf="admission.state === 'declined'" name="close-circle" color="medium"></ion-icon>
  <ion-label class="ion-text-wrap">
    <h2>{{ admission.candidateCompany.name }}</h2>
    <p>
      <span *ngFor="let person of candidatePersons; last as isLast"
        >{{ person.firstName }} {{ person.lastName }}<ng-container *ngIf="!isLast">, </ng-container></span
      >
    </p>
    <p *ngIf="admission.state === 'voting'">
      {{ "home.votingUntil" | translate }} {{ admission.voteUntil | formatDate: "dd.MM." }}
    </p>
    <p *ngIf="admission.state === 'meetingScheduled'">
      {{ "home.introductionMeeting" | translate }} {{ admission.introductionEventDate | formatDate: "dd.MM." }}
      {{ admission.introductionEventTitle }}
    </p>
    <p *ngIf="admission.state === 'consensus'">
      {{ "home.consensus" | translate }}
    </p>
  </ion-label>
  <ion-badge color="light" *ngIf="admission.state === 'voting'" slot="end">{{ voteCount }}</ion-badge>
</ion-item>
