import { IsoDate } from './types';

export interface ValidFrom {
  validFrom: IsoDate;
}

export function getValid<T extends ValidFrom>(list: T[], date: IsoDate): T | undefined {
  return list.sort((a, b) => (a.validFrom < b.validFrom ? 1 : -1)).find((vatRate) => vatRate.validFrom <= date);
}

export function getCurrentValid<T extends ValidFrom>(list: T[]): T | undefined {
  const today = new Date().toISOString().substring(0, 10);
  return getValid(list, today);
}
