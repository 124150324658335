import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Id, SkillLevel } from '@coalist/common';
import { MemberUserSkillForm, MemberUserSkillsForm } from './edit-skills.form';

export interface ChangeSkillLevelEvent {
  skillId: Id;
  level: SkillLevel;
}

@Component({
  selector: 'edit-skills',
  templateUrl: './edit-skills.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSkillsComponent {
  @Input() skills!: MemberUserSkillsForm[];
  @Output() changeLevel = new EventEmitter<ChangeSkillLevelEvent>();

  updateRating(skillId: Id, level: SkillLevel) {
    this.changeLevel.emit({ skillId, level });
  }

  trackSkills(index: number, skills: MemberUserSkillsForm) {
    return skills.category;
  }

  trackSkill(index: number, skills: MemberUserSkillForm) {
    return skills.name;
  }
}
