<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button color="secondary"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="!(showVotes | async)">
        <ng-container *ngIf="admission | async as admission">{{admission.candidateCompany.name}}</ng-container>
      </ng-container>
      <ng-container *ngIf="showVotes | async">
        <ion-segment [value]="activeSlide | async" (ionChange)="showSlide($any($event).detail.value)">
          <ion-segment-button *ngFor="let slide of slideIds | async" [value]="slide">
            {{ ('admission.pages.' + slide) | translate }}
          </ion-segment-button>
        </ion-segment>
      </ng-container>
    </ion-title>
    <ng-container *ngIf="admission | async as admission">
      <ion-buttons slot="end" *ngIf="admission.state === 'voting'">
        <ion-button (click)="vote()">{{ 'admission.vote.vote' | translate }}</ion-button>
      </ion-buttons>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="user | async as user">
    <ng-container *ngIf="admission | async as admission">
      <admission *ngIf="!(showVotes | async)" [user]="user" [admission]="admission" (vote)="vote()"></admission>
      <slides #slides *ngIf="showVotes | async" (slideChanged)="slideChanged($event)">
        <slide>
          <ion-content>
            <admission [user]="user" [admission]="admission" (vote)="vote()"></admission>
          </ion-content>
        </slide>
        <slide>
          <ion-content>
            <admission-voting [user]="user!" [admission]="admission!" (vote)="vote()"></admission-voting>
          </ion-content>
        </slide>
      </slides>
    </ng-container>
  </ng-container>
</ion-content>
