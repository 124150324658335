<ion-list>
  <ion-item-group *ngFor="let skills of skills; trackBy: trackSkills">
    <ion-item-divider>{{ skills.category }}</ion-item-divider>
    <ion-item *ngFor="let skill of skills.skills; trackBy: trackSkill">
      <ion-label>{{ skill.name }}</ion-label>
      <div slot="end">
        <ion-icon color="warning" [name]="skill.level > 0 ? 'star' : 'star-outline'"></ion-icon>
        <ion-icon color="warning" [name]="skill.level > 1 ? 'star' : 'star-outline'"></ion-icon>
        <ion-icon color="warning" [name]="skill.level > 2 ? 'star' : 'star-outline'"></ion-icon>
        <ion-icon color="warning" [name]="skill.level > 3 ? 'star' : 'star-outline'"></ion-icon>
        <ion-icon color="warning" [name]="skill.level > 4 ? 'star' : 'star-outline'"></ion-icon>
      </div>
    </ion-item>
  </ion-item-group>
</ion-list>
