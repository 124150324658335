<ion-list>
  <ion-item>
    <ion-label class="company-logo">
      <img [storageImg]="company.logoUrl" fallback="/assets/img/logo-placeholder.svg" />
    </ion-label>
  </ion-item>
</ion-list>

<ion-list>
  <ion-item-divider>{{ "memberCompany.people" | translate }}</ion-item-divider>
  <member-user-item
    *ngFor="let user of users; trackBy: trackById"
    [user]="user"
    routerLink="../../users/{{ user.id }}"
  ></member-user-item>
</ion-list>
<ion-list lines="none">
  <ng-container *ngIf="company.address">
    <ion-item-divider>{{ "memberCompany.address" | translate }}</ion-item-divider>
    <address-item [address]="company.address" [showMap]="true"></address-item>
  </ng-container>
</ion-list>
