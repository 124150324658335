import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MemberUser } from '@coalist/common';
import { flyInOut } from '../../../util/animations';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'member-user-availability',
  templateUrl: './member-user-availability.component.html',
  styleUrls: ['./member-user-availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut]
})
export class MemberUserAvailabilityComponent implements OnChanges {
  trackById = trackById;

  @Input() users!: MemberUser[];

  unavailable: MemberUser[] = [];
  partial: MemberUser[] = [];
  available: MemberUser[] = [];
  notSet: MemberUser[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users) {
      this.unavailable = this.users.filter((user) => user.availabilityStatus === 'unavailable');
      this.partial = this.users.filter((user) => user.availabilityStatus === 'partial');
      this.available = this.users.filter((user) => user.availabilityStatus === 'available');
      this.notSet = this.users.filter((user) => !user.availabilityStatus);
    }
  }
}
