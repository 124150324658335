import {animate, style, transition, trigger} from '@angular/animations';

export const flyInOut = trigger("flyInOut", [
  transition(":enter", [
    style({
      opacity: 0,
      transform: "translate(-20%)",
    }),
    animate("0.2s ease-out"),
  ]),
  transition(":leave", [
    animate(
      "0.2s ease-out",
      style({
        opacity: 0,
        transform: "translate(20%)",
      })
    ),
  ]),
]);

export const fadeIn = trigger("fadeIn", [
  transition(":enter", [
    style({
      opacity: 0,
    }),
    animate("200ms ease-out"),
  ]),
]);

export const fadeInOut = trigger("fadeInOut", [
  transition(":enter", [
    style({
      opacity: 0,
    }),
    animate("200ms ease-out"),
  ]),
  transition(":leave", [
    animate(
      "200ms ease-out",
      style({
        opacity: 0,
      })
    ),
  ]),
]);

export const translateInOut = trigger("animationTranslateInOut", [
  transition(":enter", [
    style({
      transform: "translate(-100%)",
      opacity: 1,
    }),
    animate("200ms ease-out"),
  ]),
  transition(":leave", [
    animate(
      "200ms ease-out",
      style({
        transform: "translate(100%)",
        opacity: 0,
      })
    ),
  ]),
]);
