import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { Admission, PersonEntity } from '@coalist/common';

@Component({
  selector: 'admission-item',
  templateUrl: './admission-item.component.html',
  styleUrls: ['./admission-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdmissionItemComponent implements OnChanges {
  @Input()
  admission!: Admission;

  voteCount = 0;
  candidatePersons: PersonEntity[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.admission) {
      this.voteCount = this.admission.votes ? Object.values(this.admission.votes).length : 0;
      this.candidatePersons = Object.values(this.admission.candidatePersons);
    }
  }
}
