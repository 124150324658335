<ion-list>
  <ion-item-group *ngIf="available.length > 0">
    <ion-item-divider sticky>
      {{ 'availability.state.available' | translate }}
      <ion-icon slot="end" color="dark" name="rainy"></ion-icon>
    </ion-item-divider>
    <member-user-availability-item
      *ngFor="let user of available; trackBy: trackById"
      [user]="user"
      [@flyInOut]></member-user-availability-item>
  </ion-item-group>

  <ion-item-group *ngIf="partial.length > 0">
    <ion-item-divider sticky>
      {{ 'availability.state.partial' | translate }}
      <ion-icon slot="end" color="dark" name="partly-sunny"></ion-icon>
    </ion-item-divider>
    <member-user-availability-item
      *ngFor="let user of partial; trackBy: trackById"
      [user]="user"
      [@flyInOut]></member-user-availability-item>
  </ion-item-group>

  <ion-item-group *ngIf="unavailable.length > 0">
    <ion-item-divider sticky>
      {{ 'availability.state.unavailable' | translate }}
      <ion-icon slot="end" color="dark" name="sunny"></ion-icon>
    </ion-item-divider>
    <member-user-availability-item
      *ngFor="let user of unavailable; trackBy: trackById"
      [user]="user"
      [@flyInOut]></member-user-availability-item>
  </ion-item-group>

  <ion-item-group *ngIf="notSet.length > 0">
    <ion-item-divider sticky>Keine Angabe</ion-item-divider>
    <member-user-availability-item
      *ngFor="let user of notSet; trackBy: trackById"
      [user]="user"
      [@flyInOut]></member-user-availability-item>
  </ion-item-group>
</ion-list>
