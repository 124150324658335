<ion-list>
  <ion-item-group *ngFor="let skillsCategory of skills; trackBy: trackSkills">
    <ion-item-divider>{{ skillsCategory.category }}</ion-item-divider>
    <ion-item *ngFor="let skill of skillsCategory.skills; trackBy: trackSkill">
      <ion-label>{{ skill.name }}</ion-label>
      <div slot="end">
        <ion-icon
          color="warning"
          [name]="skill.level > 0 ? 'star' : 'star-outline'"
          (click)="updateRating(skill.id, skill.level === 1 ? 0 : 1)"
        ></ion-icon>
        <ion-icon
          color="warning"
          [name]="skill.level > 1 ? 'star' : 'star-outline'"
          (click)="updateRating(skill.id, 2)"
        ></ion-icon>
        <ion-icon
          color="warning"
          [name]="skill.level > 2 ? 'star' : 'star-outline'"
          (click)="updateRating(skill.id, 3)"
        ></ion-icon>
        <ion-icon
          color="warning"
          [name]="skill.level > 3 ? 'star' : 'star-outline'"
          (click)="updateRating(skill.id, 4)"
        ></ion-icon>
        <ion-icon
          color="warning"
          [name]="skill.level > 4 ? 'star' : 'star-outline'"
          (click)="updateRating(skill.id, 5)"
        ></ion-icon>
      </div>
    </ion-item>
  </ion-item-group>
</ion-list>
