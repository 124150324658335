<div class="dots"></div>
<ion-list lines="none">
  <ion-item class="logo-item">
    <a slot="start" routerLink="/app">
      <img class="logo" src="/assets/img/coalist-logo-name.svg" alt="Coalist Logo" />
    </a>
    <div slot="end">
      <ion-avatar routerLink="/home/profile">
        <img [storageImg]="user.pictureUrl" fallback="/assets/img/avatar.svg" alt="avatar image" />
      </ion-avatar>
    </div>
  </ion-item>
</ion-list>
