import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Admission, displayName } from '@coalist/common';
import { NavController, ViewWillEnter } from '@ionic/angular';
import { combineLatest, filter, firstValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { SlidesComponent } from '../../components/slides/slides.component';
import { AdmissionService } from '../../services/admission.service';
import { MemberUserService } from '../../services/member-user.service';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { brandColor, setThemeColor } from '../../util/theme';
import { SlidesPage } from '../slides/slides-page/slides.page';

export type AdmissionSlide = 'candidate' | 'voting';

@Component({
  selector: 'admission-page',
  templateUrl: './admission.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdmissionPage extends SlidesPage<AdmissionSlide> implements ViewWillEnter {
  @ViewChild('slides') slides?: SlidesComponent;

  user = this.userService.user;
  admission: Observable<Admission>;
  showVotes: Observable<boolean>;
  showVoting: Observable<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private admissionService: AdmissionService,
    private memberUserService: MemberUserService,
    private modalService: ModalService,
    private navController: NavController,
    private userService: UserService,
  ) {
    super(['candidate']);
    const admissionId = activatedRoute.params.pipe(map(({ id }) => id));
    this.admission = admissionId.pipe(
      switchMap((eventId) => (eventId ? this.admissionService.findById(eventId) : of(null))),
      tap((event) => {
        if (event === null) {
          this.navController.navigateBack('/home');
        }
      }),
      filter((admission): admission is Admission => admission !== null),
    );
    this.showVotes = combineLatest([this.user, this.admission]).pipe(
      map(
        ([user, admission]) =>
          (admission.state === 'voting' && admission.votes && !!admission.votes[user.id]) ||
          admission.state === 'accepted' ||
          admission.state === 'declined' ||
          admission.state === 'consensus',
      ),
      tap((showVotes) => this.slideIds.next(showVotes ? ['candidate', 'voting'] : ['candidate'])),
    );
    this.showVoting = this.admission.pipe(map((admission) => admission.state === 'voting'));
  }

  ionViewWillEnter() {
    setThemeColor(brandColor);
  }

  async vote() {
    const user = await firstValueFrom(this.user);
    const admission = await firstValueFrom(this.admission);
    if (user && admission?.votes) {
      const userVote = admission.votes[user.id];
      const updatedVote = await this.modalService.editAdmissionVote(userVote);
      if (updatedVote) {
        await this.admissionService.updateAdmissionVote(admission.id, user.id, {
          ...updatedVote,
          memberUserDisplayName: displayName(user),
        });
        this.showSlide('voting');
      }
    }
  }
}
