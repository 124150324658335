import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { createSocialPlatformLinks, Link, MemberUser } from '@coalist/common';

@Component({
  selector: 'member-user',
  templateUrl: './member-user.component.html',
  styleUrls: ['./member-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberUserComponent implements OnChanges {
  @Input() memberUser!: MemberUser;

  links: Link[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.memberUser) {
      this.links = createSocialPlatformLinks(this.memberUser.socialProfiles);
    }
  }
}
