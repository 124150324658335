<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">{{ 'general.cancel' | translate}}</ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="ok()">{{ 'general.ok' | translate}}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form *ngIf="formGroup" [formGroup]="formGroup">
    <ion-list>
      <ion-item *ngFor="let field of formFields; let index = index">
        <ion-label [position]="field.labelPosition ?? field.type === 'textarea' ? 'stacked' : 'fixed'" class="small">
          {{ field.label }}
        </ion-label>
        <ng-container *ngIf="field.type === 'text' || field.type === 'number'">
          <ion-input #input [type]="field.type" [autofocus]="index === 0" [formControlName]="field.id"></ion-input>
          <ion-icon
            *ngIf="showErrors && !formGroup.controls[field.id].valid"
            slot="end"
            name="warning-outline"
            color="warning"></ion-icon>
        </ng-container>
        <ng-container *ngIf="field.type === 'textarea'">
          <ion-textarea #input [autofocus]="index === 0" [rows]="1" [autoGrow]="true" [formControlName]="field.id">
          </ion-textarea>
          <ion-icon
            *ngIf="showErrors && !formGroup.controls[field.id].valid"
            slot="end"
            name="warning-outline"
            color="warning"></ion-icon>
        </ng-container>
        <ng-container *ngIf="field.type === 'date'">
          <ion-label *ngIf="field.value" (click)="editDate(field)">
            {{ formGroup.value[field.id] | formatDate: 'dd.MM.yyyy' }}
          </ion-label>
          <ion-label #input class="placeholder" *ngIf="!field.value" (click)="editDate(field)">
            {{ 'placeholder.date' | translate }}
          </ion-label>
          <ion-icon
            *ngIf="showErrors && !formGroup.controls[field.id].valid"
            slot="end"
            name="warning-outline"
            color="warning"></ion-icon>
        </ng-container>
      </ion-item>
    </ion-list>
  </form>
</ion-content>
