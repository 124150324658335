import { WithId } from './types';

export type Person = PersonEntity & WithId;

export interface PersonEntity {
  firstName: string;
  lastName: string;
  pictureUrl?: string;
}

export function displayName(person: PersonEntity) {
  return `${person.firstName} ${person.lastName}`;
}
