import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { AddressItemComponent } from './components/address/address-item/address-item.component';
import { AddressComponent } from './components/address/address/address.component';
import { AdmissionItemComponent } from './components/admission/admission-item/admission-item.component';
import { AdmissionVoteComponent } from './components/admission/admission-vote/admission-vote.component';
import { AdmissionVotingComponent } from './components/admission/admission-voting/admission-voting.component';
import { AdmissionComponent } from './components/admission/admission/admission.component';
import { VoteComponent } from './components/admission/vote/vote.component';
import { AnimateListItemDirective } from './components/animate-list-item.directive';
import { AppComponent } from './components/app/app.component';
import { AutoFocusDirective } from './components/auto-focus.directive';
import { DateBadgeComponent } from './components/date-badge/date-badge.component';
import { EditEventComponent } from './components/event/edit-event/edit-event.component';
import { EventListComponent } from './components/event/event-list/event-list.component';
import { EventComponent } from './components/event/event/event.component';
import { HomeContentComponent } from './components/home/home-content/home-content.component';
import { HomeHeaderComponent } from './components/home/home-header/home-header.component';
import { EditInvoiceComponent } from './components/invoice/edit-invoice/edit-invoice.component';
import { InvoiceComponent } from './components/invoice/invoice/invoice.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoginComponent } from './components/login/login/login.component';
import { MemberCompanyItemComponent } from './components/member-company/member-company-item/member-company-item.component';
import { MemberCompanyListComponent } from './components/member-company/member-company-list/member-company-list.component';
import { MemberCompanyComponent } from './components/member-company/member-company/member-company.component';
import { MemberUserAvailabilityItemComponent } from './components/member-user/member-user-availability-item/member-user-availability-item.component';
import { MemberUserAvailabilityComponent } from './components/member-user/member-user-availability/member-user-availability.component';
import { MemberUserItemComponent } from './components/member-user/member-user-item/member-user-item.component';
import { MemberUserListComponent } from './components/member-user/member-user-list/member-user-list.component';
import { MemberUserProfileComponent } from './components/member-user/member-user-profile/member-user-profile.component';
import { MemberUserSkillsComponent } from './components/member-user/member-user-skills/member-user-skills.component';
import { MemberUserComponent } from './components/member-user/member-user/member-user.component';
import { ClientListComponent } from './components/project/client-list/client-list.component';
import { ClientComponent } from './components/project/client/client/client.component';
import { ProjectListComponent } from './components/project/project-list/project-list.component';
import { ProjectComponent } from './components/project/project/project.component';
import { SlideComponent } from './components/slides/slide.component';
import { SlidesComponent } from './components/slides/slides.component';
import { StorageImgDirective } from './components/storage-img.directive';
import { EditBusinessProfileComponent } from './components/user/edit-business-profile/edit-business-profile.component';
import { EditMemberCompanyComponent } from './components/user/edit-member-company/edit-member-company.component';
import { EditMemberProfileComponent } from './components/user/edit-member-profile/edit-member-profile.component';
import { EditSkillsComponent } from './components/user/edit-skills/edit-skills.component';
import { UserProfileComponent } from './components/user/user-profile/user-profile.component';
import { AdminPage } from './pages/admin/admin.page';
import { AdmissionVotePage } from './pages/admission-vote/admission-vote.page';
import { AdmissionPage } from './pages/admission/admission.page';
import { AdmissionsPage } from './pages/admissions/admissions.page';
import { AppInfoPage } from './pages/app-info/app-info-page.component';
import { ClientPage } from './pages/client/client.page';
import { CoalistPage } from './pages/coalist/coalist.page';
import { DatePickerPage } from './pages/date-picker/date-picker.page';
import { EditAvailabilityPage } from './pages/edit-availability/edit-availability.page';
import { EditEventPage } from './pages/edit-event/edit-event.page';
import { EditFormPage } from './pages/edit-form/edit-form.page';
import { EditInvoicePage } from './pages/edit-invoice/edit-invoice.page';
import { EditTextPage } from './pages/edit-text/edit-text.page';
import { EventPage } from './pages/event/event.page';
import { EventsPage } from './pages/events/events.page';
import { HomePage } from './pages/home/home.page';
import { InvoicePage } from './pages/invoice/invoice.page';
import { LoginPage } from './pages/login/login.page';
import { MemberCompanyPage } from './pages/member-company/member-company.page';
import { MemberUserPage } from './pages/member-user/member-user.page';
import { MembersPage } from './pages/members/members.page';
import { NavModalPage } from './pages/nav-modal/nav-modal.page';
import { ProjectPage } from './pages/project/project.page';
import { ProjectsPage } from './pages/projects/projects.page';
import { SlidesModal } from './pages/slides/slides-modal/slides.modal';
import { TabsPage } from './pages/tabs/tabs.page';
import { TimePickerPage } from './pages/time-picker/time-picker.page';
import { EditBusinessProfilePage } from './pages/user/edit-business-profile/edit-business-profile.page';
import { EditCompanyPage } from './pages/user/edit-company/edit-company.page';
import { EditMemberProfilePage } from './pages/user/edit-member-profile/edit-member-profile.page';
import { EditSkillsPage } from './pages/user/edit-skills/edit-skills.page';
import { UserProfilePage } from './pages/user/user-profile/user-profile.page';
import { WelcomePage } from './pages/welcome/welcome.page';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FormatDurationPipe } from './pipes/format-duration.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { iosTransitionAnimationWithHooks } from './util/page-transitions';
import { createTranslateLoader } from './util/translate-loader';
import { WelcomeComponent } from './components/welcome/welcome.component';

@NgModule({
  declarations: [
    AppComponent,
    FormatDatePipe,
    FormatDurationPipe,
    FormatNumberPipe,
    AppInfoPage,
    TabsPage,
    HomePage,
    HomeContentComponent,
    HomeHeaderComponent,
    EventsPage,
    EventListComponent,
    EventPage,
    EventComponent,
    MembersPage,
    MemberCompanyListComponent,
    MemberUserListComponent,
    MemberCompanyPage,
    MemberCompanyComponent,
    MemberUserPage,
    MemberUserComponent,
    ProjectsPage,
    ProjectListComponent,
    AdminPage,
    LoginPage,
    LoginComponent,
    MemberCompanyItemComponent,
    MemberUserItemComponent,
    UserProfilePage,
    StorageImgDirective,
    MemberUserProfileComponent,
    MemberUserSkillsComponent,
    EditTextPage,
    EditFormPage,
    SlidesModal,
    UserProfilePage,
    UserProfileComponent,
    EditSkillsPage,
    EditSkillsComponent,
    DatePickerPage,
    TimePickerPage,
    AddressComponent,
    AddressItemComponent,
    AdmissionsPage,
    AdmissionPage,
    AdmissionComponent,
    AdmissionVotingComponent,
    AdmissionItemComponent,
    AdmissionVotePage,
    AdmissionVoteComponent,
    VoteComponent,
    MemberUserAvailabilityComponent,
    MemberUserAvailabilityItemComponent,
    EditAvailabilityPage,
    EditBusinessProfilePage,
    EditBusinessProfileComponent,
    EditCompanyPage,
    EditMemberCompanyComponent,
    CoalistPage,
    DateBadgeComponent,
    EditMemberProfilePage,
    EditMemberProfileComponent,
    EditEventPage,
    EditEventComponent,
    ProjectPage,
    ProjectComponent,
    ClientPage,
    ClientComponent,
    ClientListComponent,
    LoadingComponent,
    AnimateListItemDirective,
    AutoFocusDirective,
    NavModalPage,
    InvoicePage,
    EditInvoicePage,
    EditInvoiceComponent,
    InvoiceComponent,
    SlidesComponent,
    SlideComponent,
    WelcomePage,
    WelcomeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({
      mode: 'ios',
      navAnimation: iosTransitionAnimationWithHooks,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
