import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Event } from '@coalist/common';
import { IonInfiniteScroll } from '@ionic/angular';
import { flyInOut } from '../../../util/animations';
import { groupBy } from '../../../util/helpers';
import { trackById } from '../../../util/trackBy';

@Component({
  selector: 'event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class EventListComponent implements OnChanges {
  @ViewChild(IonInfiniteScroll) infiniteScroll?: IonInfiniteScroll;

  @Input() events!: Event[];
  @Input() hasMore = true;
  @Output() loadMore = new EventEmitter<void>();

  dates: string[] = [];
  eventsByDate: Record<string, Event[]> = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes.events) {
      this.infiniteScroll?.complete();
      this.eventsByDate = groupBy(this.events, 'date');
      this.dates = Object.keys(this.eventsByDate).sort();
    }
    if (changes.hasMore && this.infiniteScroll) {
      this.infiniteScroll.disabled = this.hasMore;
    }
  }

  onLoadMore() {
    this.loadMore.next();
  }

  trackById = trackById;
}
