import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MemberUserProfile } from '@coalist/common';

@Component({
  selector: 'member-user-profile',
  templateUrl: './member-user-profile.component.html',
  styleUrls: ['./member-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberUserProfileComponent {
  @Input() profile!: MemberUserProfile;
}
