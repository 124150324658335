import { Injectable } from '@angular/core';
import { Id, MemberCompany, MemberCompanyEntity } from '@coalist/common';
import { where } from '@firebase/firestore';
import { map, shareReplay } from 'rxjs';
import { cacheForever } from '../model/constants';
import { FirebaseService } from './firebase.service';

@Injectable({ providedIn: 'root' })
export class MemberCompanyService {
  memberCompanies = this.findActive().pipe(shareReplay(1));

  constructor(private firebase: FirebaseService) {}

  findActive() {
    return this.firebase
      .query<MemberCompany>('member-companies', where('active', '==', true))
      .pipe(map((companies) => companies.sort((a, b) => a.name.localeCompare(b.name))));
  }

  findById(id: Id) {
    return this.firebase.findOne<MemberCompany>(`member-companies/${id}`);
  }

  update(companyId: Id, update: Partial<MemberCompanyEntity>) {
    return this.firebase.updateDoc(`member-companies/${companyId}`, update);
  }

  async updateLogo(companyId: Id, imagePath: string) {
    const storageLocation = `company-logos/${this.firebase.id()}`;
    await this.firebase.upload(storageLocation, imagePath, cacheForever);
    this.update(companyId, {
      logoUrl: storageLocation,
    });
  }
}
