import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { AppService } from '../services/app.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedOutGuard  {
  constructor(private appService: AppService, private navController: NavController, private userService: UserService) {}

  async canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    const user = await firstValueFrom(this.userService.userOptional);
    if (user !== null && user.active) {
      return this.navController.navigateRoot('/', { animated: false });
    }
    return true;
  }
}
