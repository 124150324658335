import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Event, Id, IsoDate, IsoDateTime } from '@coalist/common';
import { IonInput, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { addHours, formatISO, getDate, getMonth, getYear, parseISO, set } from 'date-fns';
import { DatePickerPage } from '../../../pages/date-picker/date-picker.page';
import { TimePickerPage } from '../../../pages/time-picker/time-picker.page';
import { IonicService } from '../../../services/ionic.service';
import { flyInOut } from '../../../util/animations';
import { today } from '../../../util/date';
import { ModelFormGroup, validate } from '../../../util/form';

export interface EventForm {
  title: string;
  allDay: boolean;
  startDate: IsoDateTime;
  endDate: IsoDateTime;
  duration: number;
  location: string;
  online: boolean;
  description: string;
}

@Component({
  selector: 'edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class EditEventComponent implements OnChanges {
  @ViewChild('titleInput') titleInput?: IonInput;
  @Input() event?: Event;
  @Output() save = new EventEmitter<EventForm>();
  @Output() delete = new EventEmitter<Id>();

  formGroup: ModelFormGroup<EventForm> = new FormGroup({
    title: new FormControl('', { validators: Validators.required, nonNullable: true }),
    allDay: new FormControl(false, { validators: Validators.required, nonNullable: true }),
    startDate: new FormControl(today('complete'), { validators: Validators.required, nonNullable: true }),
    endDate: new FormControl(formatISO(addHours(parseISO(today('complete')), 1), { representation: 'complete' }), {
      validators: Validators.required,
      nonNullable: true,
    }),
    duration: new FormControl(60, { validators: Validators.required, nonNullable: true }),
    location: new FormControl('', { nonNullable: true }),
    online: new FormControl(false, { validators: Validators.required, nonNullable: true }),
    description: new FormControl('', { nonNullable: true }),
  });
  showErrors = false;

  constructor(
    private ionicService: IonicService,
    private modalController: ModalController,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.event && this.event) {
      this.formGroup.patchValue(this.event);
    }
  }

  submit() {
    if (validate(this, this.formGroup)) {
      this.save.emit(this.formGroup.value as EventForm);
    } else {
      this.showErrors = true;
    }
  }

  async selectDate() {
    const date = await this.ionicService.showModal<IsoDate>({
      component: DatePickerPage,
      componentProps: {
        date: this.formGroup.value.startDate,
      },
      cssClass: 'date-picker-modal',
    });
    if (date) {
      const parsedDate = parseISO(date);
      const startDate = formatISO(
        set(this.formGroup.value.startDate ? parseISO(this.formGroup.value.startDate) : new Date(), {
          year: getYear(parsedDate),
          month: getMonth(parsedDate),
          date: getDate(parsedDate),
        }),
      );
      const endDate = formatISO(
        set(this.formGroup.value.endDate ? parseISO(this.formGroup.value.endDate) : new Date(), {
          year: getYear(parsedDate),
          month: getMonth(parsedDate),
          date: getDate(parsedDate),
        }),
      );
      this.formGroup.patchValue({
        startDate,
        endDate,
      });
      this.cdr.markForCheck();
    }
  }

  async selectTime() {
    const modal = await this.modalController.create({
      component: TimePickerPage,
      componentProps: {
        startTime: this.formGroup.value.startDate,
        endTime: this.formGroup.value.endDate,
      },
      cssClass: 'time-picker-modal',
    });
    await modal.present();
    const { data: time } = await modal.onDidDismiss();
    if (time) {
      this.formGroup.patchValue({
        startDate: time.start,
        endDate: time.end,
        duration: time.duration,
      });
      this.cdr.markForCheck();
    }
  }

  toggleAllDay() {
    this.formGroup.patchValue({
      allDay: !this.formGroup.value.allDay,
    });
  }

  async deleteEvent(eventId: Id) {
    const confirmed = await this.ionicService.confirmActionSheet({
      header: this.translate.instant('editEvent.confirmDelete'),
      confirmButtonText: this.translate.instant('editEvent.confirmDeleteButton'),
    });
    if (confirmed) {
      this.delete.emit(eventId);
    }
  }
}
