import { Injectable } from '@angular/core';
import { Id, WithId } from '@coalist/common';
import { FirebaseApp, initializeApp } from '@firebase/app';
import { Auth, connectAuthEmulator, getAuth, indexedDBLocalPersistence } from '@firebase/auth';
import {
  collection,
  collectionGroup,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  type DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  initializeFirestore,
  type PartialWithFieldValue,
  persistentLocalCache,
  query,
  QueryConstraint,
  setDoc,
  type SetOptions,
  type UpdateData,
  updateDoc,
  writeBatch,
} from '@firebase/firestore';
import { connectFunctionsEmulator, type Functions, getFunctions, httpsCallable } from '@firebase/functions';
import { getMessaging, Messaging } from '@firebase/messaging';
import {
  connectStorageEmulator,
  type FirebaseStorage,
  type FullMetadata,
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadBytes,
  type UploadMetadata,
} from '@firebase/storage';
import { getDocFromServer, increment } from 'firebase/firestore';
import { collectionData, docData } from 'rxfire/firestore';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FirebaseService {
  auth: Auth;
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
  messaging: Messaging;

  constructor() {
    const config = environment.firebase;
    const app = initializeApp(config.options);
    this.auth = initAuth(app, config.useEmulator, config.enableOffline);
    this.firestore = initFirestore(app, config.useEmulator, config.enableOffline);
    this.functions = initFunctions(app, config.region, config.useEmulator);
    this.storage = initStorage(app, config.useEmulator);
    this.messaging = getMessaging(app);
  }

  id(): Id {
    return doc(collection(this.firestore, 'ids')).id;
  }

  increment(path: string, field: string) {
    return this.updateDoc(path, { [field]: increment(1) });
  }

  async callFunction<T>(functionName: string, data: unknown): Promise<T> {
    const fn = httpsCallable(this.functions, functionName);
    const result = await fn(data);
    return result.data as T;
  }

  query<T>(collectionName: string, ...queryConstraints: QueryConstraint[]): Observable<T[]> {
    return this.queryWithId(collectionName, 'id', ...queryConstraints);
  }

  queryWithId<T>(collectionName: string, idField = 'id', ...queryConstraints: QueryConstraint[]): Observable<T[]> {
    return collectionData(query(collection(this.firestore, collectionName), ...queryConstraints), {
      idField,
    }) as Observable<T[]>;
  }

  queryOne<T>(collectionName: string, ...queryConstraints: QueryConstraint[]): Observable<T | null> {
    return this.query<T>(collectionName, ...queryConstraints).pipe(
      map((results) => (results.length === 1 ? results[0] : null)),
    );
  }

  findOne<T>(path: string, idField = 'id'): Observable<T> {
    return docData(doc(this.firestore, path), {
      idField,
    }) as Observable<T>;
  }

  docData<T>(path: string): Observable<T> {
    return docData(doc(this.firestore, path)) as Observable<T>;
  }

  async get<T extends WithId>(path: string): Promise<T | null> {
    const document = await getDoc(doc(this.firestore, path));
    if (document.exists()) {
      return {
        ...document.data(),
        id: document.id,
      } as T;
    }
    return null;
  }

  async getDocument<T>(path: string): Promise<T> {
    const document = await getDocFromServer(doc(this.firestore, path));
    return { ...document.data() } as T;
  }

  queryCollectionGroup<T>(collectionName: string, ...queryConstraints: QueryConstraint[]): Observable<T[]> {
    return this.queryCollectionGroupWithId(collectionName, 'id', ...queryConstraints);
  }

  queryCollectionGroupWithId<T>(
    collectionName: string,
    idField = 'id',
    ...queryConstraints: QueryConstraint[]
  ): Observable<T[]> {
    return collectionData(query(collectionGroup(this.firestore, collectionName), ...queryConstraints), {
      idField,
    }) as Observable<T[]>;
  }

  addDoc<T extends PartialWithFieldValue<DocumentData>>(collectionName: string, input: T): Id {
    const docRef = doc(collection(this.firestore, collectionName));
    setDoc(docRef, input);
    return docRef.id;
  }

  setDoc<T extends PartialWithFieldValue<DocumentData>>(path: string, input: T, options?: SetOptions) {
    const ref = doc(this.firestore, path);
    if (options) {
      setDoc(ref, input, options);
    } else {
      setDoc(ref, input);
    }
  }

  mergeDoc<T extends PartialWithFieldValue<DocumentData>>(path: string, data: T) {
    setDoc(doc(this.firestore, path), data, { merge: true });
  }

  updateDoc<T extends DocumentData>(path: string, data: UpdateData<T>) {
    return updateDoc(doc(this.firestore, path) as DocumentReference<T>, data);
  }

  deleteDoc(path: string) {
    deleteDoc(doc(this.firestore, path));
  }

  deleteDocs(paths: string[]) {
    const batch = writeBatch(this.firestore);
    paths.forEach((path) => {
      batch.delete(doc(this.firestore, path));
    });
    batch.commit();
  }

  async storageMetadata(location: string): Promise<FullMetadata | null> {
    return getMetadata(ref(this.storage, location)).catch(() => null);
  }

  async downloadUrl(location: string) {
    try {
      return getDownloadURL(ref(this.storage, location));
    } catch (e) {
      return null;
    }
  }

  async upload(location: string, imagePath: string, metadata?: UploadMetadata) {
    const imgData = await readFile(imagePath);
    return uploadBytes(ref(this.storage, location), imgData, metadata);
  }
}

export const cacheForever: UploadMetadata = {
  cacheControl: 'max-age=31536000',
};

async function readFile(filePath: string) {
  const file = await fetch(filePath);
  return await file.blob();
}

function initAuth(app: FirebaseApp, useEmulator: boolean, enableOffline: boolean) {
  const auth = getAuth(app);
  if (enableOffline) {
    auth.setPersistence(indexedDBLocalPersistence);
  }

  if (useEmulator) {
    connectAuthEmulator(auth, 'http://localhost:9099');
  }
  return auth;
}

function initFirestore(app: FirebaseApp, useEmulator: boolean, enableOffline: boolean) {
  const firestore = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
    localCache: enableOffline ? persistentLocalCache() : undefined,
  });
  if (useEmulator) {
    connectFirestoreEmulator(firestore, 'localhost', 8080);
  }
  return firestore;
}

function initFunctions(app: FirebaseApp, locationId: string, useEmulator: boolean) {
  const functions = getFunctions(app);
  functions.region = locationId;
  if (useEmulator) {
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }
  return functions;
}

function initStorage(app: FirebaseApp, useEmulator: boolean) {
  const storage = getStorage(app);
  if (useEmulator) {
    connectStorageEmulator(storage, 'localhost', 9199);
  }
  return storage;
}
