import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemberCompany, MemberUser } from '@coalist/common';
import { NavController } from '@ionic/angular';
import { filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { MemberCompanyService } from '../../services/member-company.service';
import { MemberUserService } from '../../services/member-user.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'member-company-page',
  templateUrl: './member-company.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberCompanyPage {
  user = this.userService.user;
  company: Observable<MemberCompany>;
  users: Observable<MemberUser[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private memberCompanyService: MemberCompanyService,
    private memberUserService: MemberUserService,
    private navController: NavController,
    private userService: UserService
  ) {
    const companyId = activatedRoute.params.pipe(map(({ id }) => id));
    this.company = companyId.pipe(
      switchMap((companyId) => (companyId ? this.memberCompanyService.findById(companyId) : of(null))),
      tap((company) => {
        if (company === null) {
          this.navController.navigateBack('/users');
        }
      }),
      filter((user): user is MemberCompany => user !== null)
    );
    this.users = this.company.pipe(switchMap((company) => this.memberUserService.findByCompanyId(company.id)));
  }
}
