<ion-item [routerLink]="routerLink">
  <ion-thumbnail slot="start">
    <img [storageImg]="company.logoUrl" fallback="/assets/img/logo-placeholder.svg" />
  </ion-thumbnail>
  <ion-label>
    <h2>{{ company.name }}</h2>
    <ng-container *ngIf="users">
      <p>
        <ng-container *ngFor="let user of users; trackBy: trackById; let last = last">
          {{ user.firstName }}<ng-container *ngIf="!last">,</ng-container>
        </ng-container>
      </p>
    </ng-container>
  </ion-label>
</ion-item>
