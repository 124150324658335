import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { IsoDate } from '@coalist/common';
import { IonDatetime, ModalController } from '@ionic/angular';
import { formatISO, parseISO } from 'date-fns';

@Component({
  selector: 'date-picker',
  templateUrl: 'date-picker.page.html',
  styleUrls: ['date-picker.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerPage {
  @ViewChild(IonDatetime) datePicker!: IonDatetime;

  dateValue = formatISO(new Date(), { representation: 'date' });

  set date(date: IsoDate | undefined | null) {
    if (date) {
      this.dateValue = formatISO(parseISO(date), { representation: 'date' });
    }
  }

  constructor(private modalController: ModalController) {}

  ok() {
    this.modalController.dismiss(this.dateValue);
  }

  cancel() {
    this.modalController.dismiss();
  }
}
