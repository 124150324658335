import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemberCompany, MemberUser } from '@coalist/common';
import { BehaviorSubject, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { MemberCompanyService } from '../../../services/member-company.service';
import { MemberUserService } from '../../../services/member-user.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'edit-company-page',
  templateUrl: './edit-company.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCompanyPage {
  user: Observable<MemberUser>;
  company: Observable<MemberCompany>;
  logoSaving = new BehaviorSubject(false);
  users: Observable<MemberUser[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private memberCompanyService: MemberCompanyService,
    private memberUserService: MemberUserService,
    private userService: UserService,
  ) {
    const userId = activatedRoute.params.pipe(map(({ userId }) => userId));
    this.user = userId.pipe(switchMap((userId) => (userId ? memberUserService.findById(userId) : userService.user)));
    this.company = this.user.pipe(switchMap((user) => memberCompanyService.findById(user.companyId)));
    this.users = this.user.pipe(switchMap((user) => memberUserService.findByCompanyId(user.companyId)));
  }

  async updateLogo(dataUrl: string) {
    const company = await firstValueFrom(this.company);
    this.logoSaving.next(true);
    await this.memberCompanyService.updateLogo(company.id, dataUrl);
    this.logoSaving.next(false);
  }

  async updateBusinessTitle(businessTitle: string) {
    const user = await firstValueFrom(this.user);
    await this.memberUserService.updateMemberUser(user.id, { businessTitle });
  }
}
