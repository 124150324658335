<ion-list>
  <ion-item-group>
    <ion-item-divider>{{ 'event.date' | translate }}</ion-item-divider>
    <ion-item>
      <ion-label>
        <ng-container *ngIf="event.allDay">
          {{ event.startDate | formatDate }}
        </ng-container>
        <ng-container *ngIf="!event.allDay">
          {{ event.startDate | formatDate: 'dd.MM.yyyy HH:mm' }}
          -
          {{ event.endDate | formatDate: (sameDay ? 'HH:mm' : 'dd.MM.yyyy HH:mm') }}
        </ng-container>
      </ion-label>
    </ion-item>
  </ion-item-group>
  <ion-item-group>
    <ion-item-divider>{{ 'event.description' | translate }}</ion-item-divider>
    <ion-item>
      <ion-label class="ion-text-wrap">{{ event.description }}</ion-label>
    </ion-item>
  </ion-item-group>
  <ion-item-group>
    <ion-item-divider sticky>
      {{ 'event.participants' | translate }}
      <ion-badge slot="end" color="medium">{{ event.participantIds.length }}</ion-badge>
    </ion-item-divider>
    <member-user-item type="self" [user]="user" (click)="attend.emit(!attends)">
      <ion-toggle color="primary" [checked]="attends"></ion-toggle>
    </member-user-item>
    <member-user-item
      *ngFor="let participant of otherParticipants; trackBy: trackById"
      [user]="participant"
      routerLink="../users/{{ participant.id }}"
      [@flyInOut]></member-user-item>
  </ion-item-group>
  <ion-item-group *ngIf="!event.online">
    <ion-item-divider sticky>{{ 'event.location' | translate }}</ion-item-divider>
    <ion-item>
      <ion-label class="ion-text-wrap">{{ event.location }}</ion-label>
    </ion-item>
    <ion-item *ngIf="mapsUrl">
      <iframe class="map" [src]="mapsUrl"></iframe>
    </ion-item>
  </ion-item-group>
</ion-list>
