import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingState } from '../../util/loading';

@Component({
  selector: 'loading',
  templateUrl: 'loading.component.html',
  styleUrls: ['loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  @Input({ required: true }) loading?: LoadingState = 'loading';
  @Input() message?: string;
}
