import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemberUser } from '@coalist/common';
import { NavController, ViewWillEnter } from '@ionic/angular';
import { BehaviorSubject, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { MemberUserService } from '../../../services/member-user.service';
import { UserService } from '../../../services/user.service';
import { brandColor, setThemeColor } from '../../../util/theme';

@Component({
  selector: 'user-profile-page',
  templateUrl: './user-profile.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfilePage implements ViewWillEnter {
  user: Observable<MemberUser>;
  profilePictureSaving = new BehaviorSubject(false);

  constructor(
    activatedRoute: ActivatedRoute,
    private memberUserService: MemberUserService,
    private userService: UserService,
    private navController: NavController,
  ) {
    const userId = activatedRoute.params.pipe(map(({ userId }) => userId));
    this.user = userId.pipe(switchMap((userId) => (userId ? memberUserService.findById(userId) : userService.user)));
  }

  ionViewWillEnter() {
    setThemeColor(brandColor);
  }

  async updateProfilePicture(dataUrl: string) {
    const user = await firstValueFrom(this.user);
    this.profilePictureSaving.next(true);
    await this.memberUserService.updateProfilePicture(user.id, dataUrl);
    this.profilePictureSaving.next(false);
  }

  async enablePushNotifications() {
    await this.userService.enablePushNotifications();
  }

  async disablePushNotifications() {
    await this.userService.disablePushNotifications();
  }

  async logout() {
    await this.userService.logout();
    await this.navController.navigateRoot('/login');
  }
}
