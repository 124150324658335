import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Client, Project } from '@coalist/common';
import { flyInOut } from '../../../../util/animations';

@Component({
  selector: 'client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut],
})
export class ClientComponent {
  @Input() client!: Client;
  @Input() projects!: Project[];
}
