<ion-header>
  <ion-toolbar color="brand">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title><span *ngIf="client | async as client">{{ client.name }}</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <client *ngIf="client | async as client" [client]="client" [projects]="(projects | async) ?? []"></client>
</ion-content>
