import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client, Project } from '@coalist/common';
import { map, Observable, switchMap } from 'rxjs';
import { ClientService } from '../../services/client.service';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'client-page',
  templateUrl: './client.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientPage {
  client: Observable<Client>;
  projects: Observable<Project[]>;

  constructor(activatedRoute: ActivatedRoute, clientService: ClientService, projectService: ProjectService) {
    const clientId = activatedRoute.params.pipe(map(({ id }) => id));
    this.client = clientId.pipe(switchMap((id) => clientService.findById(id)));
    this.projects = clientId.pipe(switchMap((id) => projectService.findByClientId(id)));
  }
}
