import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemberUser, MemberUserEducation, MemberUserProfile } from '@coalist/common';
import { ModalController } from '@ionic/angular';
import { firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { MemberUserService } from '../../../services/member-user.service';
import { ModalService } from '../../../services/modal.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'edit-business-profile-page',
  templateUrl: './edit-business-profile.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBusinessProfilePage {
  user: Observable<MemberUser>;
  profile: Observable<MemberUserProfile>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private memberUserService: MemberUserService,
    private modalService: ModalService,
    private userService: UserService,
    private modalController: ModalController
  ) {
    const userId = activatedRoute.params.pipe(map(({ userId }) => userId));
    this.user = userId.pipe(switchMap((userId) => (userId ? memberUserService.findById(userId) : userService.user)));
    this.profile = this.user.pipe(switchMap((user) => this.memberUserService.findProfileById(user.id)));
  }

  async saveMiniBiography(miniBiography: string) {
    const user = await firstValueFrom(this.user);
    await this.memberUserService.updateProfile(user.id, {
      miniBiography
    });
  }

  async saveEducation(education: MemberUserEducation, index?: number) {
    const user = await firstValueFrom(this.user);
    const profile = await firstValueFrom(this.profile);
    // todo use array-union
    if (index !== undefined) {
      const educations = [...profile.education];
      educations[index] = education;
      await this.memberUserService.updateProfile(user.id, {
        education: educations
      });
    } else {
      const educations = [...profile.education, education];
      await this.memberUserService.updateProfile(user.id, {
        education: educations
      });
    }
  }

  async saveLanguage(language: string, index?: number) {
    const user = await firstValueFrom(this.user);
    const profile = await firstValueFrom(this.profile);
    // todo use array-union
    if (index !== undefined) {
      const languages = [...profile.languages];
      languages[index] = language;
      await this.memberUserService.updateProfile(user.id, {
        languages
      });
    } else {
      const languages = [...profile.languages, language];
      await this.memberUserService.updateProfile(user.id, {
        languages
      });
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
