import { Injectable } from '@angular/core';
import { Admission, AdmissionEntity, AdmissionState, Id, Vote } from '@coalist/common';
import { collection, CollectionReference, doc, updateDoc, where } from '@firebase/firestore';
import { map } from 'rxjs';
import { compareDate } from '../util/helpers';
import { FirebaseService } from './firebase.service';

@Injectable({ providedIn: 'root' })
export class AdmissionService {
  constructor(private firebase: FirebaseService) {}

  findByState(states: AdmissionState[]) {
    return this.firebase
      .query<Admission>('admissions', where('state', 'in', states))
      .pipe(map((admissions) => admissions.sort(sortAdmissions)));
  }

  findById(id: Id) {
    return this.firebase.findOne<Admission>(`admissions/${id}`);
  }

  updateAdmissionVote(admissionId: Id, userid: Id, vote: Vote) {
    return updateDoc(
      doc(collection(this.firebase.firestore, 'admissions') as CollectionReference<Admission>, admissionId),
      {
        [`votes.${userid}`]: vote,
      } as Partial<AdmissionEntity>,
    );
  }
}

const admissionStateSort: AdmissionState[] = [
  'consensus',
  'voting',
  'meetingScheduled',
  'accepted',
  'firstIntroductionDone',
  'declined',
];

const sortAdmissions = (a: Admission, b: Admission) => {
  if (a.state === b.state) {
    if (a.state === 'meetingScheduled') {
      return compareDate(a.introductionEventDate, b.introductionEventDate);
    }
    if (a.state === 'voting') {
      return compareDate(a.voteUntil, b.voteUntil);
    }
    return 0;
  }
  return admissionStateSort.indexOf(a.state) - admissionStateSort.indexOf(b.state);
};
