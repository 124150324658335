import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { IonInput, IonTextarea, ModalController, ViewDidEnter } from '@ionic/angular';

@Component({
  selector: 'edit-text-page',
  templateUrl: './edit-text.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTextPage implements ViewDidEnter {
  @ViewChild(IonInput) input?: IonInput;
  @ViewChild(IonTextarea) textarea?: IonTextarea;

  title = '';
  text = '';
  placeholder?: string;
  multiline = false;

  constructor(private modalController: ModalController) {}

  ionViewDidEnter() {
    this.input?.setFocus();
    this.textarea?.setFocus();
  }

  cancel() {
    this.modalController.dismiss();
  }

  ok() {
    this.modalController.dismiss(this.text);
  }
}
