import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemberUser, MemberUserProfile } from '@coalist/common';
import { NavController } from '@ionic/angular';
import { combineLatest, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  MemberUserSkill,
  MemberUserSkills,
} from '../../components/member-user/member-user-skills/member-user-skills.component';
import { SlidesComponent } from '../../components/slides/slides.component';
import { MemberUserService } from '../../services/member-user.service';
import { SkillsService } from '../../services/skills.service';
import { UserService } from '../../services/user.service';
import { SlidesPage } from '../slides/slides-page/slides.page';

export type MemberUserSlide = 'profile' | 'cv' | 'skills';

@Component({
  selector: 'member-user-page',
  templateUrl: './member-user.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberUserPage extends SlidesPage<MemberUserSlide> {
  @ViewChild('slides') slides!: SlidesComponent;

  user = this.userService.user;
  memberUser: Observable<MemberUser>;
  profile: Observable<MemberUserProfile>;
  skills: Observable<MemberUserSkills[]>;

  constructor(
    activatedRoute: ActivatedRoute,
    skillsService: SkillsService,
    private memberUserService: MemberUserService,
    private userService: UserService,
    private navController: NavController,
  ) {
    super(['profile']);
    const userId = activatedRoute.params.pipe(map(({ id }) => id));
    this.memberUser = userId.pipe(
      switchMap((userId) => (userId ? this.memberUserService.findById(userId) : of(null))),
      tap((user) => {
        if (user === null) {
          this.navController.navigateBack('/users');
        }
      }),
      filter((user): user is MemberUser => user !== null),
    );

    this.profile = this.memberUser.pipe(
      switchMap((user) => this.memberUserService.findProfileById(user.id)),
      tap((profile) => {
        if (profile) {
          this.slideIds.next(['profile', 'skills', 'cv']);
          this.slides.update();
        }
      }),
    );

    this.skills = combineLatest([skillsService.skills, this.profile]).pipe(
      map(([skills, profile]): MemberUserSkills[] =>
        skills.categoryIds
          .map((categoryId) => ({
            category: skills.categories[categoryId].name,
            skills: skills.categories[categoryId].skillIds
              .filter((skillId) => profile?.skills && profile.skills[skillId])
              .map(
                (skillId): MemberUserSkill => ({
                  id: skillId,
                  name: skills.categories[categoryId].skills[skillId],
                  level: profile.skills[skillId],
                }),
              ),
          }))
          .filter((skills) => skills.skills.length > 0),
      ),
      filter((skills) => skills.length > 0),
    );
  }
}
